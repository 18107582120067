import React, {} from 'react'
import {ThemeProvider, withTheme} from 'styled-components'
import {format} from 'date-fns'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Form} from 'react-final-form'
import {Container} from '../../components/common'
import InputField from '../../components/form/InputField'
import DateField from '../../components/form/DateField'

const Filters = ({theme, angosto, onFilter}) => {
  const textTheme = {
    field: {
      ...theme.field,
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderLeftWidth: '1px',
      borderRightWidth: '1px',
      borderRadius: '5px'
    }
  }

  return(
  <Form onSubmit={(values,b) => onFilter({searchText: values.searchText || '', searchDate: values.searchDate || ''})}>
    {({handleSubmit}) => (
      <Container flexDirection='row' alignItems='flex-end' margins='10px 0 0'>
        <Container margins='5px'>
          <FontAwesomeIcon
            icon={faSearch}
            size={15}
            color={theme.globalBlue}
          />
        </Container>
        <ThemeProvider theme={textTheme}>
          <Container flexDirection={angosto ? 'column' :'row'}>
            <InputField
              width={angosto ? '100%' : '60%'}
              name='searchText'
              label='Busque por Cédula o Paciente:'
              placeholder='0502875065 ó luis'
              onChange={handleSubmit}
            />
            
            <DateField
              width={angosto ? '100%' : '40%'}
              label='o Cita:'
              placeholder='AAAA-MM-DD'
              name='searchDate'
              minDate={format(new Date(2020, 0, 1), 'yyyy-MM-dd')}
              maxDate={format(new Date().setDate((new Date()).getDate() + 365), 'yyyy-MM-dd')}
              onChange={handleSubmit}
            />
          </Container>
        </ThemeProvider>
      </Container>
    )}
  </Form>
)}
export default withTheme(Filters)