/**
 * Triaje: EditUser Reduced Component
 *
 * @format
 * @flow
 */

import React, { Component, Fragment } from 'react'
import { ActivityIndicator } from 'react-native'
import { withTheme } from 'styled-components'
import { faBirthdayCake, faPhone, faPlus, faUserShield, faVenusMars, faVial, faWheelchair } from '@fortawesome/free-solid-svg-icons'
import { faIdCard, faSave, faUser } from '@fortawesome/free-regular-svg-icons'
import Toast from '../../components/Toast'
import InputField from '../../components/form/InputField'
import SelectField from '../../components/form/SelectField'
import DateField from '../../components/form/DateField'
import CheckboxField from '../../components/form/CheckboxField'
import FormIconArrayField from '../../components/form/FormIconArrayField'
import FormIconButton from '../../components/form/FormIconButton'
import { Container, Label, Title } from '../../components/common'
import { doFetch, genderOptions } from '../../utility/Util'
import FormCheckbox from '../../components/form/FormCheckbox'

class Triaje extends Component {
  constructor(props) {
    super(props)
    this.state = {
      percentDisabled: true,
      showCheckMark: false
    }
  }

  render() {
    const { initialValues, setAlreadyAppointments, savePatientAdmission, setSavePatientAdmission, theme } = this.props,
      { percentDisabled, showCheckMark } = this.state,
      fieldMargin = '20px 0 0'

    if (!initialValues) {
      return (
        <Container flex={1} justifyContent='center'>
          <ActivityIndicator />
        </Container>
      )
    }

    return (
      <Fragment>
        <Title margins='25px 0'>
          {initialValues.id ? 'Editar Paciente' : 'Crear Paciente'}
        </Title>

        {showCheckMark && <FormCheckbox
          margins="0 0 20px"
          leftLabelColor={!savePatientAdmission && "#a49593"}
          rightLabelColor={theme.globalError}
          leftLabel='Ingresar paciente para agendamiento'
          rightLabel={!savePatientAdmission && '(Solo guardar datos del paciente)'}
          width='100%'
          value={savePatientAdmission}
          icon={faSave}
          onPress={val => setSavePatientAdmission(val)}
        />}

        <Container flex={1} flexDirection='row' justifyContent='space-between'>
          <InputField
            margins={fieldMargin}
            width='35%'
            label='Cédula de Identidad'
            placeholder='Ingrese 10 dígitos'
            name='person.idNumber'
            maxLength={10}
            icon={faIdCard}
            onBlur={({nativeEvent: {text}}) => {
              if (text.length > 6) {
                doFetch({
                  endpoint: 'getPatientsByIdNumber2',
                  body: text,
                  onOK: data => {
                    let patient =
                      data && data.length > 0
                        ? data[0].patient
                          : null

                    if (patient) {
                      Toast({
                        message: 'La cédula ya registrada en el sistema. Se han cargado los datos existentes.',
                        duration: 4000,
                        backgroundColor: theme.globalBlue,
                        opacity: 0.75
                      })
                      this.setState({showCheckMark: true})
                      setAlreadyAppointments(patient, data)
                    } else {
                      this.setState({showCheckMark: false})
                      setSavePatientAdmission(true)
                      setAlreadyAppointments({}, [])
                    }
                  },
                  onJsonError: data => {
                    this.setState({showCheckMark: false})
                    setSavePatientAdmission(true)
                    setAlreadyAppointments({person: {idNumber: text, phones: [{}]}}, [])
                  }
                })
              }
            }}
          />

          <FormIconArrayField
            name='person.phones'
            label='Teléfonos'
            width='50%'
            margins={fieldMargin}
            wrapperStyle={{ minHeight: 60 }}
          >
            {({ fields }) => (
              <Container
                flex={1}
                flexDirection='row'
                onLayout={() => {
                  if (fields.length === 0) {
                    fields.push({})
                  }
                }}
              >
                <FormIconButton
                  leftIcon={faPlus}
                  margins='0'
                  paddings='5px'
                  alignSelf='flex-start'
                  onPress={() => fields.push({})}
                />
                <Container margins='0 0 0 10px' flex={1}>
                  {fields.map((phoneItem, index) => (
                    <InputField
                      key={'phoneItem' + index}
                      margins={index === 0 ? '0' : fieldMargin}
                      placeholder='0912345678'
                      name={`${phoneItem}.phoneNumber`}
                      maxLength={32}
                      keyboardType='numeric'
                      icon={faPhone}
                    />
                  ))}
                </Container>
              </Container>
            )}
          </FormIconArrayField>
        </Container>

        <Container
          flexDirection='row'
          justifyContent='center'
          alignItems='stretch'
          margins={fieldMargin}
        >
          <InputField
            width='50%'
            placeholder='Primer Nombre'
            name='person.firstName'
            maxLength={512}
            returnKeyType='next'
            icon={faUser}
          />
          <InputField
            width='50%'
            placeholder='Segundo Nombre'
            maxLength={512}
            name='person.middleName'
          />
        </Container>

        <Container
          flexDirection='row'
          justifyContent='center'
          alignItems='stretch'
          margins={fieldMargin}
        >
          <InputField
            width='50%'
            placeholder='Primer Apellido'
            name='person.surName'
            maxLength={512}
            icon={faUser}
          />
          <InputField
            width='50%'
            placeholder='Segundo Apellido'
            name='person.secondSurName'
            maxLength={512}
          />
        </Container>

        <Container
          flexDirection='row'
          justifyContent='space-between'
          alignItems='stretch'
          margins={fieldMargin}
        >
          <SelectField
            width='30%'
            options={genderOptions}
            placeholder='Sexo'
            name='person.gender'
            maxLength={256}
            icon={faVenusMars}
          />
          <DateField
            width='30%'
            placeholder='AAAA-MM-DD'
            name='person.birthDate'
            icon={faBirthdayCake}
          />
          <SelectField
            width='30%'
            placeholder='Tipo/Sangre'
            options={[
              { value: 'A+', label: 'A+' },
              { value: 'A-', label: 'A-' },
              { value: 'B+', label: 'B+' },
              { value: 'B-', label: 'B-' },
              { value: 'AB+', label: 'AB+' },
              { value: 'AB-', label: 'AB-' },
              { value: 'O+', label: 'O+' },
              { value: 'O-', label: 'O-' }
            ]}
            name='person.bloodType'
            icon={faVial}
          />
        </Container>

        <Container
          flexDirection='row'
          justifyContent='flex-start'
          alignItems='flex-end'
          margins={fieldMargin}
        >
          <CheckboxField
            label='Discapacidad'
            leftLabel=' '
            width='40%'
            name='person.disability'
            icon={faWheelchair}
            onChange={val =>
              this.setState({ percentDisabled: !val })
            }
          />

          <InputField
            width='50%'
            label='Porcentaje de Discapacidad'
            placeholder='###'
            keyboardType='numeric'
            maxLength={3}
            name='person.disabilityPercentage'
            disabled={percentDisabled}
          />
          <Label>%</Label>
        </Container>

        <CheckboxField
          margins={fieldMargin}
          label='Seguro Médico Privado'
          leftLabel=' '
          width='100%'
          name='privateUser'
          icon={faUserShield}
        />

        {/*<Container
          flexDirection='row'
          justifyContent='space-around'
          alignItems='stretch'
          margins={fieldMargin}
        >
          <FormIconButton
            margins='30px'
            title='Más datos'
            // onPress={handleSubmit}
          />
          <FormIconButton
            margins='30px'
            title='Guardar Ahora'
            // onPress={handleSubmit}
          />

        </Container>*/}
      </Fragment>
    )
  }
}

export default withTheme(Triaje)
