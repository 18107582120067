import React, {Component} from 'react'
import {
  BackButton,
  Redirect,
  Router,
  Route,
  Switch
} from '../../utility/routing'
import storage from '../../utility/storage'
import {doFetch} from '../../utility/Util'
import Login from '../../view/login/Login'
import Register from '../../view/register/Register'
import Home from '../../view/home/Home'

class AppProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sessionInfo: {}
    }
    this.changeStateToConnect = this.changeStateToConnect.bind(this)
    this.changeStateToDisconnect = this.changeStateToDisconnect.bind(this)
  }

  changeStateToConnect(data) {
    if (data.sessionInfo && data.sessionInfo.user) {
      storage.setItem('sessionInfo', JSON.stringify(data.sessionInfo))
      this.setState({sessionInfo: data.sessionInfo})
    }
  }

  changeStateToDisconnect() {
    this.setState({sessionInfo: {}})
  }

  componentDidMount() {
    doFetch({
      endpoint: 'getSessionInfo',
      onOK: data => {
        if (data.user) {
          storage.setItem('sessionInfo', JSON.stringify(data))
          this.setState({sessionInfo: data})
        }
        if (data.sessionInfo && data.sessionInfo.user) {
          storage.setItem('sessionInfo', JSON.stringify(data.sessionInfo))
          storage.setItem('csrfToken', data.token)
          this.setState({sessionInfo: data.sessionInfo})
        }
      },
      onNotOK: () => {}// data => console.log('getSessionINFO NOT OK', data)
    })
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log('shouldComponentUpdate', nextProps, nextState);
  //   return true
  // }
  // componentDidUpdate() {
  //   doFetch({
  //     endpoint: 'getSessionInfo',
  //     onOK: data => {
  //       storage.setItem('user', JSON.stringify(data))
  //       this.setState({sessionInfo: data})
  //     }
  //   })
  // }
  render() {
    return (
      <Router>
        <BackButton>
          {this.state.sessionInfo.user ? (
            <Switch>
              {/* Add here Authenticated components */}
              <Route
                path='/'
                // exact
                render={() => (
                  <Home disconnectFunction={this.changeStateToDisconnect} />
                )}
              />
              {/*<Route path="/profile" component={Profile} />*/}
              {this.props.children}
            </Switch>
          ) : (
            <Switch>
              {/* Add here Not Authenticated components */}
              <Route
                path='/'
                exact
                render={() => (
                  <Login connectFunction={this.changeStateToConnect} />
                )}
              />
              <Route path='/register' component={Register} />
              <Redirect to="/"/>
            </Switch>
          )}
        </BackButton>
      </Router>
    )
  }
}

export default AppProvider
