/**
 * Permission View
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {ScrollView} from 'react-native'
import {withTheme} from 'styled-components'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {faEdit} from '@fortawesome/free-regular-svg-icons'
import FormIconButton from '../../components/form/FormIconButton'
import {Container, Title} from '../../components/common'
import {withRouter} from '../../utility/routing'
import {doFetch} from '../../utility/Util'
import Item from './Item'

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      permissions: [],
      users: [],
      userTypes: [],
      error: null,
      enableNew: false,
      edit: -1
    }
  }

  componentDidMount() {
    doFetch({
      endpoint: 'getPermissionsList',
      onOK: data => {
        let permissions = []
        data.forEach(dataI => {
          const {systemUser, userType, permission} = dataI
          permissions.push({
            ...permission,
            systemUser,
            userType
          })
        })
        this.setState({permissions})
      }
    })
    doFetch({
      endpoint: 'getUsers',
      onOK: data => {
        this.setState(prevState => {
          let permissions = []
          prevState.permissions.forEach(perm => {
            if (perm.systemUser) {
              const systemUser = data.find(u => u.id === perm.systemUser)
              permissions.push({...perm, systemUser})
            } else {
              permissions.push(perm)
            }
          })
          return {users: data, permissions}
        })
      }
    })
    doFetch({
      endpoint: 'getUserTypes',
      onOK: data => {
        this.setState(prevState => {
          let permissions = []
          prevState.permissions.forEach(perm => {
            if (perm.userType) {
              const userType = data.find(u => u.id === perm.userType)
              permissions.push({...perm, userType})
            } else {
              permissions.push(perm)
            }
          })
          return {userTypes: data, permissions}
        })
      }
    })
  }

  render() {
    const {permissions, users, userTypes, enableNew, edit} = this.state

    return (
      <Container flex={1}>
        <ScrollView
          style={{flex: 1}}
          contentContainerStyle={{paddingBottom: 80}}
        >
          <Title margins='20px 0 30px'>PERMISOS</Title>

          {permissions.map((permissionObject, index) => (
            <Container key={permissionObject.id} flexDirection='row'>
              <FormIconButton
                borderTopWidth='0'
                borderRightWidth='0'
                borderBottomWidth='0'
                borderLeftWidth='0'
                margins='0'
                paddings='0'
                leftIcon={faEdit}
                alignSelf='center'
                onPress={() => this.setState({edit: edit > -1 ? -1 : index})}
              />
              <Item
                permissionObject={permissionObject}
                enableNew={edit === index}
                users={users}
                userTypes={userTypes}
              />
            </Container>
          ))}
          <Container
            backgroundColor='yellow'
            flexDirection='row'
            paddings='0 0 0 15px'
          >
            {enableNew && (
              <Item enableNew={enableNew} users={users} userTypes={userTypes} />
            )}
          </Container>
        </ScrollView>

        <FormIconButton
          style={{position: 'absolute', bottom: 25, left: 25}}
          backgroundColor='aliceblue'
          margins='0'
          paddings='10px'
          leftIcon={faPlus}
          onPress={() => this.setState({enableNew: true})}
        />
      </Container>
    )
  }
}

export default withTheme(withRouter(List))
