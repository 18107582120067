/**
 * FontAwesomeLink Component
 *
 * @format
 * @flow
 */

import React from 'react'
import {withTheme} from 'styled-components'
import {withRouter} from '../../utility/routing'
import FormIconButton from '../form/FormIconButton'

const FontAwesomeLink = ({
  link,
  title,
  icon,
  theme,
  location,
  history,
  state,
  color,
  ...restProps
}) => {
  const selected = location.pathname === link
  return (
    <FormIconButton
      margins='0'
      paddings='0'
      alignItems='center'
      iconSize={theme.bottomMenu.iconSize}
      color={
        color
          ? color
          : selected
          ? theme.bottomMenu.selectedIconColor
          : theme.bottomMenu.iconColor
      }
      borderColor='transparent'
      leftIcon={icon}
      title={title}
      onPress={() => {
        history.push({
          pathname: link,
          state: state
        })
      }}
      {...restProps}
    />
  )
}

export default withTheme(withRouter(FontAwesomeLink))
