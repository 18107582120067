import React from 'react'
import {Map, Marker as GMarker, GoogleApiWrapper} from 'google-maps-react'
import {Container} from '../common'
import {global} from '../../utility/Util'

const MapView = GoogleApiWrapper({
    apiKey: global.GOOGLE_MAPS_APIKEY
  })(props => {
    const toolbar = props.toolbarEnabled
    if (props.region.latitude) {
      return (
        <Map
          google={props.google}
          zoom={props.zoom || 16}
          initialCenter={{
            lat: props.region.latitude,
            lng: props.region.longitude
          }}
          options={{fullscreenControl: false, ...props.defaultOptions}}
          // defaultZoom={this.props.zoom}
          streetViewControl={toolbar}
          mapTypeControl={toolbar}
          zoomControl={props.zoomControl || toolbar}
          fullscreenControl={toolbar}
          center={{
            lat: props.region.latitude,
            lng: props.region.longitude
          }}
          {...props}
        >
          {props.children}
        </Map>
      )
    }
    return null
  }),
  Callout = props => <Container />,
  Marker = ({
    // map,
    // google,
    coordinate,
    // mapCenter,
    image,
    label,
    draggable,
    onPress,
    title,
    ...props
  }) => (
    <GMarker
      position={coordinate}
      icon={image && {url: image}}
      title=''
      onClick={data => onPress(data)}
      {...props}
    />
  ),
  MapViewDirections = props => <Container />
export {Callout, MapView, Marker, MapViewDirections}
