/**
 * Schedule View
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {ScrollView, TouchableOpacity} from 'react-native'
import styled, {withTheme} from 'styled-components'
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {object as yupObject} from 'yup'
import Form from '../../components/form/Form'
import FormIconButton from '../../components/form/FormIconButton'
import Room from '../consultingRoom/Room'
import {Container, containerCss, Label, Title} from '../../components/common'
import {withRouter} from '../../utility/routing'
import {doFetch} from '../../utility/Util'

const TouchContainer = styled(TouchableOpacity)`
    ${containerCss};
  `,
  ChevronButton = ({color, icon, onPress}) => (
    <FormIconButton
      margins='0'
      paddings='0 10px'
      borderTopWidth='0'
      borderRightWidth='0'
      borderBottomWidth='0'
      borderLeftWidth='0'
      leftIcon={icon}
      iconSize={18}
      alignSelf='center'
      color={color}
      onPress={onPress}
    />
  ),
  helperArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

class Schedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rents: null,
      appointments: null,
      newRents: [[], [], []],
      newAppointments: [[], [], []]
    }

    this.selectCell = this.selectCell.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  componentDidMount() {
    const {location} = this.props,
      room = location.state.room,
      doctor = location.state.doctor,
      rentComponent = location.state.rent ? true : false

    if (room) {
      doFetch({
        endpoint: rentComponent ? 'getRenting' : 'getAppointments',
        body: rentComponent
          ? JSON.stringify({id: room.id, version: room.version})
          : JSON.stringify({
              consultingRoom: {id: room.id, version: room.version},
              doctor: {id: doctor.id, version: doctor.version}
            }),
        onOK: data => {
          if (rentComponent) {
            // Renting from this authenticated doctor
            if (data && data[0] && data[0].newRents) {
              data[0].newRents.forEach(newRents => {
                newRents.forEach(cell => {
                  this.selectCell(cell.column, cell.index, false, true)
                })
              })
            }

            // Renting from other doctors
            if (data && data[1] && data[1].newRents) {
              data[1].newRents.forEach(newRents => {
                newRents.forEach(cell => {
                  this.selectCell(cell.column, cell.index, true, false)
                })
              })
            }
            this.setState({rents: data})
          } else {
            // Appointment from this authenticated patient
            if (data && data[0] && data[0].newAppointments) {
              data[0].newAppointments.forEach(newAppointments => {
                newAppointments.forEach(cell => {
                  this.selectCell(cell.column, cell.index, false, true)
                })
              })
            }

            // Appointment from other patients
            if (data && data[1] && data[1].newAppointments) {
              data[1].newAppointments.forEach(newAppointments => {
                newAppointments.forEach(cell => {
                  this.selectCell(cell.column, cell.index, true, false)
                })
              })
            }
            this.setState({appointments: data})
          }
        }
      })
    }
  }

  selectCell(column, index, disabled, value) {
    this.setState((prevState, props) => {
      const rentComponent = props.location.state.rent ? true : false,
        name = rentComponent ? 'newRents' : 'newAppointments'
      let newRentsArray = prevState[name],
        arrayColumn = prevState[name][column]

      arrayColumn[index] = {
        column,
        index,
        value:
          value === undefined
            ? arrayColumn[index] && arrayColumn[index].value
              ? false
              : true
            : value,
        disabled
      }
      newRentsArray[column] = arrayColumn
      return {...prevState, [name]: newRentsArray}
    })
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSelectChange(itemValue, itemPosition) {
    this.setState({
      type: itemValue,
      choosenIndex: itemPosition
    })
  }

  render() {
    const {history, theme, location} = this.props,
      days = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado'
      ],
      now = new Date(),
      temp = new Date(),
      hours = 6,
      rentComponent = location.state.rent ? true : false,
      stateObj = rentComponent ? 'newRents' : 'newAppointments'

    return (
      <Container flex={1}>
        <Form
          margins='20px 70px'
          maxWidth='400px'
          width='100%'
          alignSelf='center'
          endpoint={rentComponent ? 'saveRenting' : 'saveAppointment'}
          contentType='application/json'
          body={
            rentComponent
              ? {
                  newRents: this.state.newRents,
                  consultingRoom: {
                    id: location.state.room.id,
                    version: location.state.room.version
                  }
                }
              : {
                  newAppointments: this.state.newAppointments,
                  consultingRoom: {
                    id: location.state.room.id,
                    version: location.state.room.version
                  },
                  doctor: {
                    id: location.state.doctor.id,
                    version: location.state.doctor.version
                  }
                }
          }
          validationSchema={yupObject().shape({})}
          onSuccess={data => {
            history.goBack()
          }}
          submitButtonTitle={rentComponent ? 'Rentar' : 'Agendar'}
          submitButtonMargins='30px 0'
          wrapper={ScrollView}
          wrapperProps={{showsVerticalScrollIndicator: false}}
        >
          {errors => (
            <React.Fragment>
              <Room roomData={location.state.room} />

              <Container>
                <Title margins='30px 0'>
                  {rentComponent
                    ? 'Renta de consultorio'
                    : 'Horario de la cita'}
                </Title>

                <Container flexDirection='row' justifyContent='center'>
                  <ChevronButton
                    icon={faChevronLeft}
                    color={theme.globalGreen}
                    // onPress={}
                  />
                  <ScrollView
                    style={{flex: 1}}
                    contentContainerStyle={{
                      justifyContent: 'center',
                      flexDirection: 'row'
                    }}
                  >
                    {this.state[stateObj].map((literal, litIndex) => (
                      <Container key={'lit' + litIndex}>
                        <Container
                          width='80px'
                          height='20px'
                          alignItems='center'
                          style={{
                            borderColor: 'grey',
                            borderTopWidth: 1,
                            borderRightWidth: litIndex === 2 ? 1 : 0,
                            borderLeftWidth: 1
                          }}
                          backgroundColor={'#eee'}
                        >
                          <Label>
                            {
                              days[
                                new Date(
                                  temp.setDate(now.getDate() + litIndex)
                                ).getDay()
                              ]
                            }
                          </Label>
                        </Container>
                        {helperArray.map((item, index) => (
                          <TouchContainer
                            key={litIndex + '-' + index}
                            width='80px'
                            height='20px'
                            alignItems='center'
                            style={{
                              borderColor: 'grey',
                              borderTopWidth: 1,
                              borderRightWidth: litIndex === 2 ? 1 : 0,
                              borderLeftWidth: 1,
                              borderBottomWidth: index === 11 ? 1 : 0
                            }}
                            backgroundColor={
                              (litIndex === 0 &&
                                now.getHours() > hours + index + 1) ||
                              (literal[index] && literal[index].disabled)
                                ? '#ccc'
                                : literal[index] && literal[index].value
                                ? theme.globalGreen
                                : 'white'
                            }
                            onPress={() => this.selectCell(litIndex, index)}
                            disabled={
                              (litIndex === 0 &&
                                now.getHours() > hours + index + 1) ||
                              (literal[index] && literal[index].disabled)
                            }
                          >
                            <Label>{hours + index + 1 + ':00'}</Label>
                          </TouchContainer>
                        ))}
                      </Container>
                    ))}
                  </ScrollView>

                  <ChevronButton
                    icon={faChevronRight}
                    color={theme.globalGreen}
                    // onPress={() => {}}
                  />
                </Container>
              </Container>
            </React.Fragment>
          )}
        </Form>
      </Container>
    )
  }
}

export default withRouter(withTheme(Schedule))
