import {notify} from 'react-notify-toast'

const toast = ({
  message,
  duration,
  position,
  backgroundColor,
  opacity,
  ...props
}) => {
  notify.show(message, 'custom', duration, {
    background: backgroundColor,
    text: '#FFF',
    position,
    zIndex: 999999
  })
}
export default toast
