/**
 * Appointment List
 *
 * @format
 * @flow
 */

import React, {Component, Fragment} from 'react'
import {
  ActivityIndicator,
  Dimensions,
  Platform,
  ScrollView,
  TouchableWithoutFeedback
} from 'react-native'
import {withTheme} from 'styled-components'
import {string as yupString, object as yupObject} from 'yup'
import {format, differenceInYears} from 'date-fns'
import {faBookMedical, faExclamationCircle, faFileMedical} from '@fortawesome/free-solid-svg-icons'
import {faClock, faEdit, faFile as faRegularFile, faFileExcel, faHandPointRight} from '@fortawesome/free-regular-svg-icons'
import {es} from 'date-fns/locale'
import {Field} from 'react-final-form'
import {debounce, omit} from 'lodash'
import {Container, Label, TableLabel, Title} from '../../components/common'
import {doFetch, hasPermissions} from '../../utility/Util'
import CardView from '../../components/CardView'
import PagedList from '../../components/PagedList'
import Modal from '../../components/Modal'
import FormFinal from '../../components/form/FormFinal'
import DateField from '../../components/form/DateField'
import TimeField from '../../components/form/TimeField'
import FormIconButton from '../../components/form/FormIconButton'
import Toast from '../../components/Toast'
import {getStoredSessionInfo} from '../../components/logic/Authentication'
import Filters from './Filters'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
// import {saveAs} from 'file-saver'

class AppointmentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appointments: [],
      today: true,
      loading: true,
      page: 0,
      pageSize: 10, //PAGE SIZE
      totalPages: 0,
      angosto: Dimensions.get('window').width < 575,
      searchText: '',
      searchDate: '',
      patients: [],
      doctors: []
    }
    this.getAppointedPatients = this.getAppointedPatients.bind(this)
    this.setDebounced = debounce(this.setFilter.bind(this), 500)
  }

  componentDidMount() {
    this.getAppointedPatients()
    doFetch({
      endpoint: 'getAllDoctors',
      onOK: data => this.setState({allDoctors: data})
    })
    getStoredSessionInfo()
      .then(data => {
        this.setState({
          callCenterPermission: hasPermissions(data.permissions, {
            objects: 'CallCenter',
            actions: ['read']
          })
        })
      })
      .catch(() => {})
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.today !== prevState.today ||
      this.state.page !== prevState.page
    ) {
      this.getAppointedPatients()
    }
  }

  getAppointedPatients() {
    this.setState({loading: true})
    const {today, searchText, searchDate, page, pageSize} = this.state
    doFetch({
      endpoint: 'getAppointedPatients',
      params: [{page}, {size: pageSize}],
      body: JSON.stringify({today, searchText, searchDate}),
      onOK: data => {
        let appointments = [],
          doctors = this.state.doctors,
          patients = this.state.patients,
          patientAdmissions = this.state.patientAdmissions

        setTimeout(() => {
          data.content.forEach(({patientAdmission, doctor, ...appointment}) => {
            let tempPatient = {},
              tempDoctor = {}
            if (
              patientAdmission.patient.id &&
              (!patients.find(p => p.id === patientAdmission.patient.id) ||
                !patients.find(p => p.id === patientAdmission.patient.id).length)
            ) {
              patients.push(patientAdmission.patient)
              tempPatient = patientAdmission.patient
            } else {
              tempPatient = patients.find(p => p.id === patientAdmission.patient)
            }
            if (
              doctor.id &&
              (!doctors.find(p => p.id === doctor.id) ||
                !doctors.find(p => p.id === doctor.id).length)
            ) {
              doctors.push(doctor)
              tempDoctor = doctor
            } else {
              console.log('temp ' + doctor, tempDoctor);
              tempDoctor = doctors.find(d => d.id === doctor)
                || this.state.allDoctors.find(d => d.id === doctor)
            }
            appointments.push({
              ...appointment,
              patientAdmission: {
                ...patientAdmission,
                patient: tempPatient
              },
              doctor: tempDoctor
            })
          })

          this.setState({
            appointments,
            patients,
            doctors,
            patientAdmissions,
            loading: false,
            totalPages: data.totalPages
          })
        }, 500);
        
      },
      onNotOK: () => this.setState({loading: false})
    })
  }

  setFilter(values) {
    this.setState({...values, page: 0}, () => {
      this.getAppointedPatients()
    })
  }

  render() {
    const {
        appointments,
        allDoctors,
        statusesVisible,
        doctorsVisible,
        scheduledOnVisible,
        appointment,
        today,
        loading,
        totalPages,
        callCenterPermission,
        angosto
      } = this.state,
      {theme, history} = this.props
    return (
      <Container alignItems='center' width='100%' flex={1}>
        <Container
          flexDirection={angosto ? 'column' : 'row'}
          justifyContent='space-between'
          alignItems='flex-end'
          width='100%'
          paddings='15px'
          onLayout={({
            nativeEvent: {
              layout: {width}
            }
          }) => {
            if (width < 575 && !angosto) {
              this.setState({angosto: true})
            } else if (width >= 575 && angosto) {
              this.setState({angosto: false})
            }
          }}
        >
          <TouchableWithoutFeedback
            onPress={() => {
              this.setState({today: !today, page: 0})
            }}
          >
            <Container
              style={{
                borderColor: theme.globalBlue,
                borderRadius: 5
              }}
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              backdropColor={theme.globalGreen}
            >
              <Container
                style={{
                  width: 40,
                  padding: 5,
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderRightWidth: 0,
                  backgroundColor: today ? theme.globalBlue : theme.globalBackground
                }}
              >
                <Label
                  style={{
                    textAlign: 'center',
                    color: today ? theme.globalBackground : theme.globalBlue
                  }}
                >
                  Hoy
                </Label>
              </Container>

              <Container
                style={{
                  width: 60,
                  padding: 5,
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderLeftWidth: 0,
                  backgroundColor: today ? 'transparent' : theme.globalBlue
                }}
              >
                <Label
                  style={{
                    textAlign: 'center',
                    color: today ? theme.globalBlue : theme.globalBackground
                  }}
                >
                  Todos
                </Label>
              </Container>
            </Container>
          </TouchableWithoutFeedback>

          {/* FILTERS */}
          <Filters angosto={angosto} onFilter={this.setDebounced} />
        </Container>

        <Title margins='10px'>Agendamientos</Title>

        {loading && <ActivityIndicator />}

        {/* APPOINTMENTS LIST */}
        <PagedList
          rows={appointments}
          contentStyle={{
            // alignItems: 'center',
            flexDirection: 'column',
            minWidth: callCenterPermission ? 1500 : 1300,
            width: '100%'
          }}
          totalPages={totalPages}
          onPageChange={i => {
            if (this.state.page !== i) {
              this.setState({page: i}, () => {
                this.getAppointedPatients()
              })
            }
          }}
          page={this.state.page}
          refreshing={loading}
          contentContainerStyle={{alignItems: 'stretch'}}
          ListEmptyComponent={
            !loading && (
              <Container alignSelf='center'>
                <Label fontWeight='bold' color={theme.globalBlue}>
                  No hay datos para mostrar
                </Label>
              </Container>
            )
          }
          ListHeaderComponent={
            !loading && (
              <Container
                flexDirection='row'
                justifyContent='flex-start'
                paddings='5px 10px'
                backgroundColor={theme.globalBackground}
              >
                <TableLabel width='110px' />
                <TableLabel fontSize='17px' color={theme.globalGreen}>
                  Cédula
                </TableLabel>
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='70px'
                >
                  Tipo de Sangre
                </TableLabel>
                <TableLabel fontSize='17px' color={theme.globalGreen}>
                  Teléfono
                </TableLabel>
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='70px'
                >
                  Edad
                </TableLabel>
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='200px'
                >
                  Paciente
                </TableLabel>
                {callCenterPermission && (
                  <TableLabel
                    fontSize='17px'
                    color={theme.globalGreen}
                    width='200px'
                  >
                    Médico Asignado
                  </TableLabel>
                )}
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  flex={1}
                  style={{minWidth: 200}}
                >
                  Síntomas
                </TableLabel>
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='150px'
                >
                  Cita
                </TableLabel>
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='110px'
                >
                  Estado
                </TableLabel>
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='120px'
                  align='right'
                >
                  Categoría
                </TableLabel>
              </Container>
            )
          }
          renderItem={({item: appointment, index: i}) => {
            const patient = appointment.patientAdmission.patient,
              patientName =
                patient.person.firstName +
                ' ' +
                (patient.person.surName ? patient.person.surName : ''),
              doctorName =
                appointment.doctor.person.firstName +
                ' ' +
                (appointment.doctor.person.surName
                  ? appointment.doctor.person.surName
                  : ''),
              colorUr = appointment.triaje
                ? appointment.triaje.atentionLevel
                : 'blue',
              ur =
                colorUr === 'yellow'
                  ? 'URGENTE'
                  : colorUr === 'green'
                  ? 'Menos Urgente'
                  : 'No Urgente',
              appointmentStatus =
                appointment.status === 'active'
                  ? 'Agendada'
                  : appointment.status === 'running'
                  ? 'En Atención'
                  : appointment.status === 'attended'
                  ? 'Atendida'
                  : 'Candelada',
              statusColor =
                appointment.status === 'active' ||
                appointment.status === 'running'
                  ? '#1c1fad'
                  : '',
              backgroundC = i % 2 === 0 ? '#eaf6f7' : ''
            let patientPhone = ''
            patientPhone =
              patient.person.phones &&
              patient.person.phones.map(
                (phone, i) =>
                  patientPhone + (i === 0 ? '' : ', ') + phone.phoneNumber
              )

            if (loading) return null
            if (!loading)
              return (
                <Container
                  key={appointment.id}
                  flexDirection='row'
                  justifyContent='flex-start'
                  paddings='5px 10px'
                  backgroundColor={backgroundC}
                >
                  <TableLabel width='50px'>
                    {i + 1 + this.state.pageSize * this.state.page}
                  </TableLabel>
                  {!callCenterPermission ||
                  (appointment.clinicalHistory &&
                    (appointment.clinicalHistory.status === 'onRevission' ||
                      appointment.clinicalHistory.status === 'approved')) ? (
                    <FormIconButton
                      borderTopWidth='0px'
                      borderRightWidth='0px'
                      borderBottomWidth='0px'
                      borderLeftWidth='0px'
                      margins='0px'
                      paddings='0px'
                      iconSize={30}
                      color={theme.globalBlue}
                      leftIcon={
                        appointment.clinicalHistory &&
                        appointment.clinicalHistory.status === 'approved'
                          ? faBookMedical
                          : appointment.clinicalHistory &&
                            appointment.clinicalHistory.status === 'onRevission'
                          ? faFileMedical
                          : faRegularFile
                      }
                      absoluteIcon={
                        appointment.clinicalHistory &&
                        appointment.clinicalHistory.status === 'onEdition'
                          ? faExclamationCircle
                          : undefined
                      }
                      onPress={() => {
                        history.push({
                          pathname: '/clinicalHistory',
                          state: {
                            appointment
                          }
                        })
                      }}
                    />
                  ) : (
                    <Container width='30px' />
                  )}
                  {appointment.clinicalHistory &&
                  appointment.clinicalHistory.status === 'approved' ? (
                    <FormIconButton
                      borderTopWidth='0px'
                      borderRightWidth='0px'
                      borderBottomWidth='0px'
                      borderLeftWidth='0px'
                      margins='0px'
                      paddings='0px'
                      iconSize={30}
                      color={theme.globalBlue}
                      leftIcon={faFileExcel}
                      onPress={() => {
                        doFetch({
                          endpoint: 'downloadClinicalHistory',
                          // method: 'GET',
                          body: JSON.stringify({
                            id: appointment.clinicalHistory.id,
                            version: appointment.clinicalHistory.version
                          }),
                          blob: true,
                          // params: [{id: appointment.clinicalHistory.id}],
                          onOK: data => console.log('OK', data),
                          onBlobOK: blob => {
                            console.log('BLOBOK', blob)
                            // saveAs(
                            //   blob,
                            //   'ch_' +
                            //     appointment.patient.person.surName +
                            //     appointment.patient.person.firstName +
                            //     '.xls'
                            // )
                          },
                          onNotOK: e => console.log('err', e),
                          onFetchError: e => console.log('Ferr', e)
                        })
                      }}
                    />
                  ) : (
                    <Container width='30px' />
                  )}
                  {/* <Container width="60px" /> */}
                  <TableLabel>{patient.person.idNumber}</TableLabel>
                  <TableLabel width='70px' color='#9f2121'>
                    {patient.person.bloodType}
                  </TableLabel>
                  <TableLabel>{patientPhone}</TableLabel>
                  <TableLabel width='70px'>
                    {patient.person.birthDate
                      ? differenceInYears(
                          new Date(),
                          new Date(patient.person.birthDate)
                        ) + ' años'
                      : ''}
                  </TableLabel>
                  <TableLabel width='200px'>{patientName}</TableLabel>
                  {callCenterPermission && (
                    <Container width='200px' paddings='5px'>
                      <FormIconButton
                        color={theme.globalBlue}
                        alignItems='center'
                        margins='0'
                        paddings='0'
                        leftIcon={faEdit}
                        title={doctorName}
                        fontWeight='normal'
                        flexDirection='row'
                        borderTopWidth='0px'
                        borderRightWidth='0px'
                        borderBottomWidth='0px'
                        borderLeftWidth='0px'
                        onPress={() =>
                          this.setState({doctorsVisible: true, appointment})
                        }
                      />
                    </Container>
                  )}
                  <TableLabel flex={1} style={{minWidth: 150}}>
                    {(appointment && appointment.patientAdmission && appointment.patientAdmission.triaje && appointment.patientAdmission.triaje.briefDiagnosis) || (appointment && appointment.briefDiagnosis)}
                  </TableLabel>
                  <Container width='150px' paddings='5px'>
                    <FormIconButton
                      color={theme.globalGreen}
                      alignItems='center'
                      margins='0'
                      paddings='0'
                      leftIcon={faEdit}
                      title={format(
                        new Date(
                          appointment.scheduledOn.split(' ')[0].split('-')[0],
                          appointment.scheduledOn.split(' ')[0].split('-')[1] -
                            1,
                          appointment.scheduledOn.split(' ')[0].split('-')[2],
                          appointment.scheduledOn.split(' ')[1].split(':')[0],
                          appointment.scheduledOn.split(' ')[1].split(':')[1]
                        ),
                        'd MMM, H:mm',
                        {locale: es}
                      )}
                      fontWeight='normal'
                      flexDirection='row'
                      borderTopWidth='0px'
                      borderRightWidth='0px'
                      borderBottomWidth='0px'
                      borderLeftWidth='0px'
                      onPress={() =>
                        this.setState({scheduledOnVisible: true, appointment})
                      }
                    />
                  </Container>
                  <Container width='110px' paddings='5px'>
                    <FormIconButton
                      color={statusColor}
                      alignItems='center'
                      margins='0'
                      paddings='0'
                      leftIcon={faEdit}
                      title={appointmentStatus}
                      fontWeight='normal'
                      flexDirection='row'
                      borderTopWidth='0px'
                      borderRightWidth='0px'
                      borderBottomWidth='0px'
                      borderLeftWidth='0px'
                      onPress={() =>
                        this.setState({statusesVisible: true, appointment})
                      }
                    />
                  </Container>
                  <TableLabel
                    width='120px'
                    align='right'
                    color={colorUr === 'yellow' ? '#d48f0e' : colorUr}
                  >
                    {ur}
                  </TableLabel>
                </Container>
              )
          }}
        />

        {appointment && (
          <Modal
            isVisible={statusesVisible}
            backdropColor='white'
            onBackdropPress={() => this.setState({statusesVisible: false})}
            onBackButtonPress={() => this.setState({statusesVisible: false})}
          >
            <CardView
              cardElevation={5}
              cardMaxElevation={5}
              cornerRadius={15}
              style={{
                borderColor: theme.globalBlue
              }}
              alignSelf='center'
              backgroundColor={theme.globalBackground}
              width='200px'
              height='200px'
            >
              <FormFinal
                endpoint='changeAppointment'
                initialValues={{
                  ...omit(appointment, ['doctor']),
                  doctor: {
                    id: appointment.doctor.id,
                    version: appointment.doctor.version
                  }
                }}
                validationSchema={yupObject().shape({
                  status: yupString()
                })}
                onSuccess={() => {
                  Toast({
                    message: 'La cita se ha modificado correctamente',
                    duration: 4000,
                    backgroundColor: theme.globalBlue
                  })
                  this.setState({statusesVisible: false, today: today}, () => {
                    this.getAppointedPatients()
                  })
                }}
                onError={() => {
                  Toast({
                    message:
                      'Hubo un error al guardar el cambio en la cita médica.',
                    duration: 4000,
                    backgroundColor: theme.globalError
                  })
                }}
                mutators={{
                  active: (args, state, utils) => {
                    utils.changeValue(state, 'status', () => 'active')
                  },
                  running: (args, state, utils) => {
                    utils.changeValue(state, 'status', () => 'running')
                  },
                  attended: (args, state, utils) => {
                    utils.changeValue(state, 'status', () => 'attended')
                  },
                  canceled: (args, state, utils) => {
                    utils.changeValue(state, 'status', () => 'canceled')
                  }
                }}
                wrapper={Container}
                wrapperProps={{
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  paddings: '20px'
                }}
              >
                {(form, handleSubmit) => {
                  const Button = ({title, status, color}) => {
                    if (appointment && appointment.status !== status) {
                      return (
                        <FormIconButton
                          flexDirection='row'
                          alignItems='center'
                          leftIcon={faHandPointRight}
                          iconSize={12}
                          color={color}
                          margins='10px'
                          paddings='5px'
                          title={title}
                          borderTopWidth='0px'
                          borderRightWidth='0px'
                          borderBottomWidth='0px'
                          borderLeftWidth='0px'
                          onPress={() => {
                            form.mutators[status]()
                            handleSubmit()
                          }}
                        />
                      )
                    }
                    return null
                  }

                  return (
                    <Fragment>
                      <Button
                        color={theme.globalBlue}
                        title='Agendada'
                        status='active'
                      />
                      <Button
                        color={theme.globalGreen}
                        title='En atención'
                        status='running'
                      />
                      <Button
                        color={theme.globalBlue}
                        title='Atendida'
                        status='attended'
                      />
                      <Button
                        color={theme.globalError}
                        title='Cancelada'
                        status='canceled'
                      />
                    </Fragment>
                  )
                }}
              </FormFinal>
            </CardView>
          </Modal>
        )}

        {appointment && (
          <Modal
            isVisible={scheduledOnVisible}
            backdropColor='white'
            onBackdropPress={() => this.setState({scheduledOnVisible: false})}
            onBackButtonPress={() => this.setState({scheduledOnVisible: false})}
          >
            <CardView
              cardElevation={5}
              cardMaxElevation={5}
              cornerRadius={15}
              style={{
                borderColor: theme.globalBlue
              }}
              alignSelf='center'
              backgroundColor={theme.globalBackground}
              width='300px'
              height='400px'
            >
              <FormFinal
                endpoint='changeAppointment'
                initialValues={{
                  ...appointment,
                  doctor: {
                    id: appointment.doctor.id,
                    version: appointment.doctor.version
                  },
                  patientAdmission: {
                    id: appointment.patientAdmission.id,
                    version: appointment.patientAdmission.version
                  }
                }}
                validationSchema={yupObject().shape({
                  date: yupString()
                    .required('Ingrese una fecha válida')
                    .matches(
                      /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                      'Formato inválido, el correcto es AAAA-MM-DD (Por ejemplo: 1970-06-25)'
                    ),
                  time: yupString()
                    .required('Ingrese una hora válida')
                    .matches(/^([7-9]|0[7-9]|1[0-8]):[0-5][0-9]$/, {
                      message:
                        'Solo se puede agendar desde las 7:00 hasta las 18:59',
                      excludeEmptyString: true
                    })
                    .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, {
                      message: 'Hora no válida',
                      excludeEmptyString: true
                    })
                })}
                onBeforeFetch={data => omit(data, ['date', 'time'])}
                onSuccess={() => {
                  Toast({
                    message: 'La cita se ha modificado correctamente',
                    duration: 4000,
                    backgroundColor: theme.globalBlue
                  })
                  this.setState({scheduledOnVisible: false}, () => {
                    this.getAppointedPatients()
                  })
                }}
                onError={() => {
                  Toast({
                    message:
                      'Hubo un error al guardar el cambio en la cita médica.',
                    duration: 4000,
                    backgroundColor: theme.globalError
                  })
                }}
                mutators={{
                  setScheduledOn: (args, state, utils) => {
                    utils.changeValue(
                      state,
                      'scheduledOn',
                      () =>
                        state.formState.values.date +
                        ' ' +
                        state.formState.values.time +
                        ':00'
                    )
                  }
                }}
                wrapper={Container}
                wrapperProps={{
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  paddings: '20px'
                }}
              >
                {(form, handleSubmit) => (
                  <Fragment>
                    <TableLabel
                      width='100%'
                      fontSize='17px'
                      color={theme.globalGreen}
                      margins='0 10px 10px'
                    >
                      Seleccione la fecha y hora de la nueva cita.
                    </TableLabel>

                    <FormIconButton
                      color={theme.globalBlue}
                      margins='10px'
                      paddings='5px'
                      title='Inmediatamente'
                      borderTopWidth='0px'
                      borderRightWidth='0px'
                      borderBottomWidth='0px'
                      borderLeftWidth='0px'
                      onPress={() => {
                        var today = new Date(),
                          minute,
                          hour
                        if (today.getMinutes() > 30) {
                          minute = '00'
                          hour = today.getHours() + 1
                        } else {
                          minute = '30'
                          hour = today.getHours()
                        }
                        hour =
                          hour < 7 ? '07' : hour < 10 ? '0' + hour : '' + hour
                        if (today.getHours() >= 19) {
                          form.change(
                            'date',
                            format(
                              new Date().setDate(new Date().getDate() + 1),
                              'yyyy-MM-dd'
                            )
                          )
                        } else {
                          form.change('date', format(new Date(), 'yyyy-MM-dd'))
                        }

                        var time = hour + ':' + minute
                        form.change('time', time)
                      }}
                    />

                    <Container flexDirection='row'>
                      <DateField
                        width='65%'
                        placeholder='AAAA-MM-DD'
                        name='date'
                        minDate={format(
                          new Date().setDate(new Date().getDate() - 365),
                          'yyyy-MM-dd'
                        )}
                        maxDate={format(
                          new Date().setDate(new Date().getDate() + 365),
                          'yyyy-MM-dd'
                        )}
                        disabled
                      />
                      <TimeField icon={faClock} name='time' width='35%' />
                    </Container>

                    <FormIconButton
                      margins='10px'
                      title='Guardar cambios'
                      onPress={() => {
                        form.mutators.setScheduledOn()
                        handleSubmit()
                      }}
                    />
                  </Fragment>
                )}
              </FormFinal>
            </CardView>
          </Modal>
        )}

        <Modal
          isVisible={doctorsVisible}
          backdropColor='white'
          onBackdropPress={() => this.setState({doctorsVisible: false})}
          onBackButtonPress={() => this.setState({doctorsVisible: false})}
        >
          <CardView
            cardElevation={5}
            cardMaxElevation={5}
            cornerRadius={15}
            style={{
              borderColor: theme.globalBlue
            }}
            alignSelf='center'
            backgroundColor={theme.globalBackground}
            width={Platform.OS === 'web' ? '700px' : '500px'}
            height='400px'
          >
            <FormFinal
              endpoint='changeDoctorAppointment'
              initialValues={{
                appointmentId: appointment && appointment.id,
                doctorId: appointment && appointment.doctor.id
              }}
              validationSchema={yupObject().shape({
                status: yupString()
              })}
              onSuccess={() => {
                Toast({
                  message: 'La cita se ha modificado correctamente',
                  duration: 4000,
                  backgroundColor: theme.globalBlue
                })
                this.setState({doctorsVisible: false}, () => {
                  this.getAppointedPatients()
                })
              }}
              onError={() => {
                Toast({
                  message:
                    'Hubo un error al guardar el cambio en la cita médica.',
                  duration: 4000,
                  backgroundColor: theme.globalError
                })
              }}
              wrapper={Container}
              wrapperProps={{
                alignItems: 'center',
                justifyContent: 'center',
                paddings: '20px'
              }}
            >
              {(form, handleSubmit) => (
                <Fragment>
                  <Title margins='10px'>Seleccione un Médico</Title>
                  <ScrollView
                    style={{width: '100%'}}
                    contentContainerStyle={{alignItems: 'flex-start'}}
                  >
                    {allDoctors && allDoctors.map(doc => (
                      <Field name='doctorId' key={doc.id}>
                        {({input}) => (
                          <TouchableWithoutFeedback
                            style={{width: '100%'}}
                            onPress={() => input.onChange(doc.id)}
                          >
                            <Container
                              margins='10px'
                              flexDirection='row'
                              alignItems='center'
                              width='100%'
                            >
                              <FontAwesomeIcon
                                icon={faHandPointRight}
                                size={form.getState() &&
                                  form.getState().values &&
                                  form.getState().values.doctorId === doc.id
                                    ? 15
                                    : 12}
                                color={
                                  form.getState() &&
                                  form.getState().values &&
                                  form.getState().values.doctorId === doc.id
                                    ? theme.globalGreen
                                    : theme.globalBlue
                                }
                                style={{ paddingRight: 10 }}
                              />

                              <Label
                                flex={1}
                                color={
                                  form.getState() &&
                                  form.getState().values &&
                                  form.getState().values.doctorId === doc.id
                                    ? theme.globalGreen
                                    : theme.globalBlue
                                }
                              >
                                {doc.person.emails[0].address}
                              </Label>

                              <Label
                                flex={1}
                                color={
                                  form.getState() &&
                                  form.getState().values &&
                                  form.getState().values.doctorId === doc.id
                                    ? theme.globalGreen
                                    : theme.globalBlue
                                }
                              >
                                {doc.person.firstName +
                                  ' ' +
                                  (doc.person.middleName
                                    ? doc.person.middleName
                                    : '') +
                                  ' ' +
                                  doc.person.surName +
                                  ' ' +
                                  doc.person.secondSurName}
                              </Label>
                            </Container>
                          </TouchableWithoutFeedback>
                        )}
                      </Field>
                    ))}
                  </ScrollView>
                  <FormIconButton
                    margins='10px'
                    title='Guardar'
                    onPress={() => handleSubmit()}
                  />
                </Fragment>
              )}
            </FormFinal>
          </CardView>
        </Modal>
      </Container>
    )
  }
}

export default withTheme(AppointmentList)
