/**
 * Consulting Room Inspect
 *
 * @format
 * @flow
 */

import React, {Component, Fragment} from 'react'
import {Animated, ScrollView} from 'react-native'
import {FormSpy} from 'react-final-form'
import {ThemeProvider, withTheme} from 'styled-components'
import {
  number as yupNumber,
  string as yupString,
  object as yupObject
} from 'yup'
import {
  faBrush,
  faCertificate,
  faChevronDown,
  faChevronUp,
  faCity,
  faCouch,
  faDollarSign,
  faExpandArrowsAlt,
  faFlag as faSolidFlag,
  faMailBulk,
  faParking,
  faPhoneSquareAlt,
  faRestroom,
  faRoad,
  faTools,
  faWheelchair
} from '@fortawesome/free-solid-svg-icons'
import {faFlag, faHospital} from '@fortawesome/free-regular-svg-icons'
import FormFinal from '../../components/form/FormFinal'
import InputField from '../../components/form/InputField'
import DateField from '../../components/form/DateField'
import CheckboxField from '../../components/form/CheckboxField'
import IconAddressLocation from '../../components/form/IconAddressLocation'
import FormIconButton from '../../components/form/FormIconButton'
import {Container, Title} from '../../components/common'
import {withRouter} from '../../utility/routing'

class Inspect2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buildingDetails: false,
      locationDetails: false
    }
    this.buildingDetailsFlex = new Animated.Value(0)
    this.locationDetailsFlex = new Animated.Value(0)
    this.slideDownDetails = this.slideDownDetails.bind(this)
    this.slideUpDetails = this.slideUpDetails.bind(this)
    this.slideDownLocations = this.slideDownLocations.bind(this)
    this.slideUpLocations = this.slideUpLocations.bind(this)
  }

  slideDownDetails = () => {
    this.setState({buildingDetails: !this.state.buildingDetails}, () => {
      Animated.timing(this.buildingDetailsFlex, {
        toValue: 1,
        duration: 750
      }).start()
    })
  }

  slideUpDetails = () => {
    Animated.timing(this.buildingDetailsFlex, {
      toValue: 0,
      duration: 750
    }).start(() =>
      this.setState({buildingDetails: !this.state.buildingDetails})
    )
  }

  slideDownLocations = () => {
    this.setState({locationDetails: !this.state.locationDetails}, () => {
      Animated.timing(this.locationDetailsFlex, {
        toValue: 1,
        duration: 750
      }).start()
    })
  }

  slideUpLocations = () => {
    Animated.timing(this.locationDetailsFlex, {
      toValue: 0,
      duration: 750
    }).start(() =>
      this.setState({locationDetails: !this.state.locationDetails})
    )
  }

  render() {
    const {match, theme, history, location} = this.props,
      {buildingDetails, locationDetails} = this.state,
      isNew = match.params.id === 'new',
      fieldMargin = '5px 0 0',
      textAreaTheme = {
        field: {
          ...theme.field,
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderLeftWidth: '1px',
          borderRightWidth: '1px',
          borderRadius: '5px'
        }
      },
      animationValueDetails = this.buildingDetailsFlex.interpolate({
        inputRange: [0, 1],
        outputRange: [-59, 0]
      }),
      animationValueLocation = this.locationDetailsFlex.interpolate({
        inputRange: [0, 1],
        outputRange: [-59, 0]
      })

    return (
      <FormFinal
        endpoint='saveConsultingRoom'
        contentType='application/json'
        initialValues={location.state}
        validationSchema={yupObject().shape({
          name: yupString().required('Agregue algún nombre'),
          rentPrice: yupNumber().required(
            'El costo inicial de la renta del inmueble es obligatoria.'
          ),
          location: yupObject()
            .shape({
              latitude: yupNumber().required(
                'Se requiere ingresar una dirección con datos de ubicaión: Latitud y Longitud'
              ),
              longitude: yupNumber().required(
                'Se requiere ingresar una dirección con datos de ubicaión: Latitud y Longitud'
              ),
              placeId: yupString().required(
                'Se requiere ingresar una dirección con datos de ubicaión: Latitud y Longitud'
              ),
              address: yupObject().shape({
                address1: yupString().required('Ingrese una dirección válida')
              })
            })
            .required('Ingrese una dirección válida')
        })}
        onSuccess={data => {
          if (isNew) {
            history.goBack()
          }
        }}
        submitButtonTitle={isNew ? 'Crear Consultorio' : 'Guardar'}
        // submitButtonMargins='30px 0'
        wrapper={ScrollView}
        wrapperProps={{
          contentContainerStyle: {
            maxWidth: 350,
            width: '100%',
            alignSelf: 'center',
            marginLeft: 0,
            marginRight: 0
          },
          keyboardShouldPersistTaps: 'handled'
          // showsVerticalScrollIndicator: false
        }}
      >
        {() => (
          <Fragment>
            <Title margins='25px 0 30px'>{`${
              isNew ? 'Crear' : 'Editar'
            } Consultorio`}</Title>
            <InputField
              placeholder='Ingrese algún nombre para este consultorio'
              label='Nombre'
              name='name'
              icon={faHospital}
            />
            <Container
              flexDirection='row'
              justifyContent='center'
              alignItems='stretch'
              margins={fieldMargin}
            >
              <InputField
                width='50%'
                label='Precio de Renta'
                name='rentPrice'
                keyboardType='numeric'
                placeholder='0.00'
                icon={faDollarSign}
              />
              <InputField
                width='50%'
                label='Numero de Teléfono'
                name='phoneNumber'
                maxLength={36}
                keyboardType='phone-pad'
                placeholder='(04) 2777 777'
                icon={faPhoneSquareAlt}
              />
            </Container>

            <FormIconButton
              rightIcon={buildingDetails ? faChevronUp : faChevronDown}
              title='Detalles del Edificio'
              onPress={() => {
                if (buildingDetails) {
                  this.slideUpDetails()
                } else {
                  this.slideDownDetails()
                }
              }}
              margins='10px 0'
              paddings='5px'
              borderTopWidth='0px'
              borderRightWidth='0px'
              borderBottomWidth='0px'
              borderLeftWidth='0px'
              borderRadius='5px'
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              backgroundColor='white'
              color={theme.title.color}
              fontSize='20px'
            />

            {buildingDetails && (
              <Animated.View
                style={{
                  flex: this.buildingDetailsFlex,
                  opacity: this.buildingDetailsFlex,
                  transform: [{translateY: animationValueDetails}]
                }}
              >
                <Container
                  flexDirection='row'
                  justifyContent='center'
                  alignItems='stretch'
                  margins={fieldMargin}
                >
                  <InputField
                    label='Tamaño'
                    width='40%'
                    name='size'
                    keyboardType='numeric'
                    placeholder='70 m2'
                    icon={faExpandArrowsAlt}
                  />
                  <CheckboxField
                    leftLabel=' '
                    label='Estacionamiento Propio'
                    width='60%'
                    iconSize={20}
                    name='parkingSpace'
                    icon={faParking}
                  />
                </Container>

                <DateField
                  margins={fieldMargin}
                  label='Fecha de Construcción'
                  name='buildDate'
                  placeholder='dd/mm/aaaa'
                  icon={faTools}
                />
                <DateField
                  margins={fieldMargin}
                  label='Fecha de Remodelación'
                  name='refurbishingDate'
                  placeholder='dd/mm/aaaa'
                  icon={faBrush}
                />

                <InputField
                  margins={fieldMargin}
                  label='Acceso'
                  name='access'
                  placeholder='¿Cómo se puede acceder al Consultorio?'
                  icon={faWheelchair}
                />
                <ThemeProvider theme={textAreaTheme}>
                  <Fragment>
                    <InputField
                      margins={fieldMargin}
                      label='Baños'
                      name='bathroom'
                      placeholder='Ingrese el detalle de los baños de su establecimiento'
                      icon={faRestroom}
                      multiline
                      height={50}
                      textAlignVertical='top'
                    />
                    <InputField
                      margins={fieldMargin}
                      label='Permisos de Funcionamiento'
                      name='operatingLicences'
                      keyboardType='numeric'
                      placeholder='Ingrese los permisos con los que consta este Consultorio'
                      icon={faCertificate}
                      multiline
                      height={70}
                      textAlignVertical='top'
                    />
                    <InputField
                      margins={fieldMargin}
                      label='Mobiliario'
                      name='furniture'
                      keyboardType='numeric'
                      placeholder='Ingrese el mobiliario con el que consta este Consultorio'
                      icon={faCouch}
                      multiline
                      height={70}
                      textAlignVertical='top'
                    />
                  </Fragment>
                </ThemeProvider>
              </Animated.View>
            )}

            <FormIconButton
              rightIcon={locationDetails ? faChevronUp : faChevronDown}
              title='Ubicación Geográfica'
              onPress={() => {
                if (locationDetails) {
                  this.slideUpLocations()
                } else {
                  this.slideDownLocations()
                }
              }}
              margins='10px 0'
              paddings='5px'
              borderTopWidth='0px'
              borderRightWidth='0px'
              borderBottomWidth='0px'
              borderLeftWidth='0px'
              borderRadius='5px'
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              backgroundColor='white'
              color={theme.title.color}
              fontSize='20px'
            />

            {locationDetails && (
              <Animated.View
                style={{
                  flex: this.locationDetailsFlex,
                  opacity: this.locationDetailsFlex,
                  transform: [{translateY: animationValueLocation}]
                }}
              >
                <Container>
                  <IconAddressLocation
                    margins={fieldMargin}
                    label='Address'
                    name='location'
                  />
                </Container>

                <FormSpy>
                  {({form: {getFieldState}}) => {
                    if (
                      getFieldState('location') &&
                      getFieldState('location').value &&
                      getFieldState('location').value.address
                    ) {
                      return (
                        <Fragment>
                          <InputField
                            margins={fieldMargin}
                            name='location.address.address1'
                            maxLength={128}
                            placeholder='Calle ##'
                            label='Principal :'
                            icon={faRoad}
                          />
                          <InputField
                            margins={fieldMargin}
                            name='location.address.address2'
                            maxLength={128}
                            placeholder='Oficina ###'
                            label='Referencia:'
                            icon={faRoad}
                          />
                          <Container
                            flexDirection='row'
                            justifyContent='center'
                            alignItems='stretch'
                            margins={fieldMargin}
                          >
                            <InputField
                              width='70%'
                              name='location.address.city'
                              maxLength={36}
                              placeholder='Guayaquil'
                              label='Ciudad:'
                              icon={faCity}
                            />
                            <InputField
                              width='30%'
                              name='location.address.zipCode'
                              maxLength={10}
                              placeholder='#####'
                              label='Código Postal:'
                              icon={faMailBulk}
                            />
                          </Container>
                          <Container
                            flexDirection='row'
                            justifyContent='center'
                            alignItems='stretch'
                            margins={fieldMargin}
                          >
                            <InputField
                              width='70%'
                              name='location.address.state'
                              maxLength={24}
                              placeholder='Guayas'
                              label='Provincia:'
                              icon={faSolidFlag}
                            />
                            <InputField
                              width='30%'
                              name='location.address.country'
                              maxLength={2}
                              placeholder='EC'
                              label='País:'
                              icon={faFlag}
                            />
                          </Container>
                        </Fragment>
                      )
                    }
                    return null
                  }}
                </FormSpy>
              </Animated.View>
            )}
          </Fragment>
        )}
      </FormFinal>
    )
  }
}

export default withTheme(withRouter(Inspect2))
