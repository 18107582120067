/**
 * Appointment For Medic View
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {ScrollView, TouchableOpacity} from 'react-native'
import styled, {withTheme} from 'styled-components'
import {object as yupObject} from 'yup'
import {debounce} from 'lodash'
import {
  faChevronLeft,
  faChevronRight,
  faUserInjured
} from '@fortawesome/free-solid-svg-icons'
import Form from '../../components/form/Form'
import FormIconInput from '../../components/form/FormIconInput'
import FormCheckbox from '../../components/form/FormCheckbox'
import FormIconButton from '../../components/form/FormIconButton'
import {Container, containerCss, Title, Label} from '../../components/common'
import {withRouter} from '../../utility/routing'
import {doFetch} from '../../utility/Util'

const TouchContainer = styled(TouchableOpacity)`
    ${containerCss};
  `,
  ChevronButton = ({color, icon, onPress}) => (
    <FormIconButton
      margins='0'
      paddings='0 10px'
      borderTopWidth='0'
      borderRightWidth='0'
      borderBottomWidth='0'
      borderLeftWidth='0'
      leftIcon={icon}
      iconSize={18}
      alignSelf='center'
      color={color}
      onPress={onPress}
    />
  ),
  helperArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

class View extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientName: null,
      patientsList: [],
      appointments: null,
      newAppointments: [[], [], []]
    }

    this.handleChange = this.handleChange.bind(this)
    this.debouncedQuery = debounce(this.query.bind(this), 300)
    this.queryPatientAppointments = this.queryPatientAppointments.bind(this)
    this.selectCell = this.selectCell.bind(this)
    this.selectPatient = this.selectPatient.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleAction = this.handleAction.bind(this)
  }

  componentDidMount() {
    doFetch({
      endpoint: 'getDoctorRentedConsultingRooms',
      onOK: data => {
        let rentedConsultingRooms = [],
          doctors = [],
          consultingRooms = []

        data.forEach(({consultingRoom, doctor}) => {
          let tempConsultingRoom = {},
            tempDoctor = {}
          if (consultingRoom.id) {
            consultingRooms.push(consultingRoom)
            tempConsultingRoom = consultingRoom
          } else {
            tempConsultingRoom = consultingRooms.find(
              r => r.id === consultingRoom
            )
          }
          if (doctor.id) {
            doctors.push(doctor)
            tempDoctor = doctor
          } else {
            tempDoctor = doctors.find(d => d.id === doctor)
          }
          rentedConsultingRooms.push({
            consultingRoom: tempConsultingRoom,
            doctor: tempDoctor
          })
        })
        this.setState({rentedConsultingRooms})
      }
    })
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    })
    this.debouncedQuery(value)
  }

  query(text) {
    doFetch({
      endpoint: 'findPatient',
      body: text,
      onOK: data => this.setState({patientsList: data})
    })
  }

  queryPatientAppointments() {
    const {patient, room} = this.state

    doFetch({
      endpoint: 'getPatientAppointments',
      body: JSON.stringify({
        consultingRoom: {id: room.id, version: room.version},
        doctor: {id: patient.id, version: patient.version}
      }),
      onOK: data => {
        // Appointment from this authenticated patient
        if (data && data[0] && data[0].newAppointments) {
          data[0].newAppointments.forEach(newAppointments => {
            newAppointments.forEach(cell => {
              this.selectCell(cell.column, cell.index, false, true)
            })
          })
        }

        // Appointment from other patients
        if (data && data[1] && data[1].newAppointments) {
          data[1].newAppointments.forEach(newAppointments => {
            newAppointments.forEach(cell => {
              this.selectCell(cell.column, cell.index, true, false)
            })
          })
        }
        this.setState({appointments: data})
      }
    })
  }

  selectCell(column, index, disabled, value) {
    this.setState((prevState, props) => {
      let newRentsArray = prevState.newAppointments,
        arrayColumn = prevState.newAppointments[column]

      arrayColumn[index] = {
        column,
        index,
        value:
          value === undefined
            ? arrayColumn[index] && arrayColumn[index].value
              ? false
              : true
            : value,
        disabled
      }
      newRentsArray[column] = arrayColumn
      return {...prevState, newAppointments: newRentsArray}
    })
  }

  selectPatient(patient) {
    this.setState({
      patientName: patient.person.firstName + ' ' + patient.person.surName,
      patientsList: [],
      patient
    })
  }

  handleSelectChange(itemValue, itemPosition) {
    this.setState({
      type: itemValue,
      choosenIndex: itemPosition
    })
  }

  handleAction(room, value) {
    this.setState({room})
  }

  render() {
    const {theme, history} = this.props,
      {
        rentedConsultingRooms,
        room,
        patient,
        patientsList,
        appointments
      } = this.state,
      days = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado'
      ],
      now = new Date(),
      temp = new Date(),
      hours = 6,
      Patients = patientsList.map(pt => (
        <TouchableOpacity key={pt.id} onPress={() => this.selectPatient(pt)}>
          <Container
            paddings='5px 0'
            style={{borderBottomWidth: 0.5, borderColor: '#AA8DBE'}}
          >
            <Label numberOfLines={1}>
              {pt.person.firstName + ' ' + pt.person.surName}
            </Label>
          </Container>
        </TouchableOpacity>
      ))

    return (
      <Container flex={1}>
        <Form
          margins='20px 70px'
          maxWidth='400px'
          width='100%'
          alignSelf='center'
          endpoint='saveAppointmentByDoctor'
          contentType='application/json'
          body={{
            newAppointments: this.state.newAppointments,
            consultingRoom: room && {id: room.id, version: room.version},
            doctor: patient && {id: patient.id, version: patient.version}
          }}
          validationSchema={yupObject().shape({})}
          onSuccess={data => {
            history.goBack()
          }}
          submitButtonTitle={!appointments ? null : 'Agendar'}
          submitButtonMargins='30px 0'
          wrapper={ScrollView}
          wrapperProps={{showsVerticalScrollIndicator: false}}
        >
          {errors => (
            <React.Fragment>
              <Title margins='20px 0'>{'Agendar'}</Title>

              <Container margins='20px'>
                <FormIconInput
                  placeholder='Nombre del Paciente'
                  value={this.state.patientName}
                  name='patientName'
                  onChange={this.handleChange}
                  icon={faUserInjured}
                  errors={errors}
                />
                {Patients}

                <Label margins='10px 0 0'>
                  {rentedConsultingRooms && rentedConsultingRooms.length > 0
                    ? 'Seleccione el Consultorio al cual agendar ' +
                      (patient ? 'a ' + patient.person.firstName : '')
                    : 'Primero debe rentar algún Consultorio para poder agendar una cita a un Paciente'}
                </Label>

                {rentedConsultingRooms
                  ? rentedConsultingRooms.map(({consultingRoom}) => (
                      <FormCheckbox
                        key={consultingRoom.id}
                        rightLabel={consultingRoom.name}
                        value={room && room.id === consultingRoom.id}
                        onPress={value =>
                          this.handleAction(consultingRoom, value)
                        }
                        // errors={errors}
                      />
                    ))
                  : null}

                <FormIconButton
                  // margins={submitButtonMargins}
                  title='Buscar'
                  alignSelf={'center'}
                  onPress={this.queryPatientAppointments}
                  disabled={!patient || !room}
                />
              </Container>

              {appointments && (
                <Container>
                  <Title margins='10px 0 20px'>{'Horario de la cita'}</Title>

                  <Container flexDirection='row' justifyContent='center'>
                    <ChevronButton
                      icon={faChevronLeft}
                      color={theme.globalGreen}
                      // onPress={}
                    />
                    <ScrollView
                      style={{flex: 1}}
                      contentContainerStyle={{
                        justifyContent: 'center',
                        flexDirection: 'row'
                      }}
                    >
                      {this.state.newAppointments.map((literal, litIndex) => (
                        <Container key={'lit' + litIndex}>
                          <Container
                            width='80px'
                            height='20px'
                            alignItems='center'
                            style={{
                              borderColor: 'grey',
                              borderTopWidth: 1,
                              borderRightWidth: litIndex === 2 ? 1 : 0,
                              borderLeftWidth: 1
                            }}
                            backgroundColor={'#eee'}
                          >
                            <Label>
                              {
                                days[
                                  new Date(
                                    temp.setDate(now.getDate() + litIndex)
                                  ).getDay()
                                ]
                              }
                            </Label>
                          </Container>
                          {helperArray.map((item, index) => (
                            <TouchContainer
                              key={litIndex + '-' + index}
                              width='80px'
                              height='20px'
                              alignItems='center'
                              style={{
                                borderColor: 'grey',
                                borderTopWidth: 1,
                                borderRightWidth: litIndex === 2 ? 1 : 0,
                                borderLeftWidth: 1,
                                borderBottomWidth: index === 11 ? 1 : 0
                              }}
                              backgroundColor={
                                (litIndex === 0 &&
                                  now.getHours() > hours + index + 1) ||
                                (literal[index] && literal[index].disabled)
                                  ? '#ccc'
                                  : literal[index] && literal[index].value
                                  ? theme.globalGreen
                                  : 'white'
                              }
                              onPress={() => this.selectCell(litIndex, index)}
                              disabled={
                                (litIndex === 0 &&
                                  now.getHours() > hours + index + 1) ||
                                (literal[index] && literal[index].disabled)
                              }
                            >
                              <Label>{hours + index + 1 + ':00'}</Label>
                            </TouchContainer>
                          ))}
                        </Container>
                      ))}
                    </ScrollView>

                    <ChevronButton
                      icon={faChevronRight}
                      color={theme.globalGreen}
                      // onPress={() => {}}
                    />
                  </Container>
                </Container>
              )}
            </React.Fragment>
          )}
        </Form>
      </Container>
    )
  }
}

export default withTheme(withRouter(View))
