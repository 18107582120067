/**
 * History View
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {Image, ScrollView, TouchableOpacity} from 'react-native'
import {withTheme} from 'styled-components'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import 'intl'
import "intl/locale-data/jsonp/en"
import "intl/locale-data/jsonp/es"
import FormIconButton from '../../components/form/FormIconButton'
import {Container, Label, Title} from '../../components/common'
import {doFetch} from '../../utility/Util'
import doctorPlaceholderImg from '../../assets/images/doctorPlaceholder.png'

const Appointment = ({appointment, theme}) => (
  <Container flexDirection='row' margins='20px 0 0' flex={1}>
    <Image
      source={
        doctorPlaceholderImg /*{
        uri:
          'https://www.aspysalud.com/wp-content/uploads/2016/12/medico-300x300.jpg'
      }*/
      }
      style={{
        width: 100,
        height: 100,
        borderRadius: 50
      }}
    />

    <Container margins='0 5px' flex={1}>
      <Container
        flexDirection='row'
        justifyContent='space-between'
        style={{
          borderBottomWidth: 1,
          borderColor: theme.globalGreen
        }}
      >
        <Container justifyContent='center' alignItems='stretch' flex={1}>
          <Label fontWeight='bold' fontSize='16px'>
            {appointment.doctor.person.firstName +
              ' ' +
              appointment.doctor.person.surName +
              ' ' +
              appointment.doctor.person.secondSurName}
          </Label>
          <Label color={theme.globalGreen} fontSize='16px' numberOfLines={1}>
            {appointment.doctor.person.degree ||
              'Nutrición y Dietética, Nutrición'}
          </Label>
          <Label numberOfLines={3}>
            {appointment.doctor.person.careerSummary ||
              'Soy el nutricionista que necesitas'}
          </Label>
        </Container>
        <Container justifyContent='center' alignItems='stretch'>
          <FormIconButton
            margins='0'
            paddings='0'
            style={{alignItems: 'center'}}
            iconSize={50}
            color='#303050'
            borderColor='transparent'
            leftIcon={faPlusCircle}
            title='STARS'
            // onPress={() => {
            //   history.push(link)
            // }}
          />
        </Container>
      </Container>
      <Label align='right'>
        {new Intl.DateTimeFormat('es-EC').format(
          new Date(
            appointment.scheduledOn.year,
            appointment.scheduledOn.monthValue - 1,
            appointment.scheduledOn.dayOfMonth,
            appointment.scheduledOn.hour
          )
        )}
      </Label>
    </Container>
  </Container>
)

class History extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  componentDidMount() {
    doFetch({
      endpoint: 'getAllAppointments',
      onOK: data => {
        let appointments = [],
          doctors = [],
          consultingRooms = []

        data.forEach(appointment => {
          const {consultingRoom, doctor} = appointment
          let tempConsultingRoom = {},
            tempDoctor = {}
          if (consultingRoom.id) {
            consultingRooms.push(consultingRoom)
            tempConsultingRoom = consultingRoom
          } else {
            tempConsultingRoom = consultingRooms.find(
              r => r.id === consultingRoom
            )
          }
          if (doctor.id) {
            doctors.push(doctor)
            tempDoctor = doctor
          } else {
            tempDoctor = doctors.find(d => d.id === doctor)
          }
          appointments.push({
            ...appointment,
            consultingRoom: tempConsultingRoom,
            doctor: tempDoctor
          })
        })

        this.setState({
          oldAppointments: appointments.filter(
            appointment =>
              new Date(
                appointment.scheduledOn.year,
                appointment.scheduledOn.monthValue - 1,
                appointment.scheduledOn.dayOfMonth,
                appointment.scheduledOn.hour
              ) < new Date()
          ),
          newAppointments: appointments.filter(
            appointment =>
              new Date(
                appointment.scheduledOn.year,
                appointment.scheduledOn.monthValue - 1,
                appointment.scheduledOn.dayOfMonth,
                appointment.scheduledOn.hour
              ) > new Date()
          )
        })
      }
    })
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSelectChange(itemValue, itemPosition) {
    this.setState({
      type: itemValue,
      choosenIndex: itemPosition
    })
  }

  render() {
    const {theme} = this.props,
      {oldAppointments, newAppointments, displayOld} = this.state

    return (
      <Container flex={1}>
        <Container
          margins='0 70px'
          maxWidth='400px'
          width='100%'
          alignSelf='center'
        >
          <ScrollView>
            <Title margins='30px 0 10px'>Consultas</Title>

            <Container
              flexDirection='row'
              flex={1}
              justifyContent='space-around'
              margins='20px 0'
              style={{borderBottomWidth: 1, borderColor: '#acacbe'}}
            >
              <TouchableOpacity
                style={{
                  flex: 1,
                  borderBottomWidth: displayOld ? 2 : null,
                  borderColor: theme.globalGreen
                }}
                onPress={() => this.setState({displayOld: true})}
              >
                <Title fontSize='20px'>Pasadas</Title>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  flex: 1,
                  borderBottomWidth: !displayOld ? 2 : null,
                  borderColor: theme.globalGreen
                }}
                onPress={() => this.setState({displayOld: false})}
              >
                <Title fontSize='20px'>Próximas</Title>
              </TouchableOpacity>
            </Container>

            {oldAppointments && displayOld
              ? oldAppointments.map(appointment => (
                  <Appointment
                    key={appointment.id}
                    appointment={appointment}
                    theme={theme}
                  />
                ))
              : newAppointments && !displayOld
              ? newAppointments.map(appointment => (
                  <Appointment
                    key={appointment.id}
                    appointment={appointment}
                    theme={theme}
                  />
                ))
              : null}
          </ScrollView>
        </Container>
      </Container>
    )
  }
}

export default withTheme(History)
