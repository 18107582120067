import React, {useState} from 'react'
import { Animated, } from 'react-native'
import { Container, Label } from '../../components/common'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { withTheme } from 'styled-components'
import FormIconButton from '../../components/form/FormIconButton'

export const AnimatedPanel = withTheme(({theme, title, description, children}) => {
  const [expanded, setExpanded] = useState(true)
  const [animation] = useState(new Animated.Value())
  const [maxHeight, setMaxHeight] = useState(null)
  const [minHeight, setMinHeight] = useState(null)
  return (
    <Animated.View style={{height: animation, overflow: 'hidden'}}>
      <Container onLayout={e => setMinHeight(e.nativeEvent.layout.height)}>
        <FormIconButton
          rightIcon={expanded ? faChevronUp : faChevronDown}
          title={title}
          onPress={() => {
            let initialValue = expanded ? maxHeight + minHeight : minHeight,
              finalValue = expanded ? minHeight : maxHeight + minHeight;

            setExpanded(!expanded)

            animation.setValue(initialValue);
            Animated.spring(
              animation,
              {toValue: finalValue}
            ).start();
          }}
          margins='10px 0'
          flex={1}
          paddings='10px'
          borderTopWidth='0px'
          borderRightWidth='0px'
          borderBottomWidth='1px'
          borderLeftWidth='0px'
          borderRadius='0px'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          backgroundColor='#d8eeef'
          color={theme.title.color}
          fontSize='20px'
        />
        <Label color={theme.globalGreen}>{description}</Label>
      </Container>

      <Container onLayout={(e) => setMaxHeight(e.nativeEvent.layout.height)}>
        {children}
      </Container>

    </Animated.View>
  )
})