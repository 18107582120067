/**
 * CardView Component for web
 *
 * @format
 * @flow
 */

import styled from 'styled-components'
import {Container} from '../common'

const BoxShadow = styled(Container)`
  box-shadow: ${props => 'black 0px 0px ' + props.cardElevation + 'px'};
  border-radius: ${props => props.cornerRadius + 'px'}
`

export default BoxShadow
