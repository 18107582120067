/**
 * Register View
 *
 * @format
 * @flow
 */

import React, {Fragment} from 'react'
import {ScrollView} from 'react-native'
import {withTheme} from 'styled-components'
import {string as yupString, object as yupObject, ref as yupRef} from 'yup'
import {faLock, faSortDown} from '@fortawesome/free-solid-svg-icons'
import {faEnvelope, faIdCard, faUser} from '@fortawesome/free-regular-svg-icons'

import Header from '../../components/logic/Header'
import FormFinal from '../../components/form/FormFinal'
import InputField from '../../components/form/InputField'
import SelectField from '../../components/form/SelectField'
import {Container, Label, Title} from '../../components/common'
import {withRouter} from '../../utility/routing'
import {userTypeOptions, iDCardValidator} from '../../utility/Util'

const Register = ({theme, history}) => {
  const fieldMargin = '25px 0 0'
  return (
    <Container flex={1}>
      <Header flexDirection='row-reverse' />
      <FormFinal
        endpoint='register'
        contentType='application/json'
        validationSchema={yupObject().shape({
          firstName1: yupString().required('Ingrese su nombre'),
          firstName2: yupString(),
          lastName1: yupString().required('Ingrese su apellido paterno'),
          lastName2: yupString().required('Ingrese su apellido materno'),
          idNumber: yupString()
            // Validate idCard & RUC inserted. https://medium.com/@bryansuarez/c%C3%B3mo-validar-c%C3%A9dula-y-ruc-en-ecuador-b62c5666186f
            // Library to do this can be: https://github.com/diaspar/validacion-cedula-ruc-ecuador
            // some code: https://www.jybaro.com/blog/cedula-de-identidad-ecuatoriana/
            // .test(
            //   'idCardValidator',
            //   params => `${params.value} no es una cédula válida`,
            //   value => iDCardValidator(value)
            // )
            // .length(
            //   10,
            //   params =>
            //     `Cédula debe tener exáctamente ${params.length} caracteres`
            // )
            .required('Cédula o Pasaporte es un campo requerido'),
          username: yupString()
            .email('Correo Electrónico debe ser válido')
            .required('Correo Electrónico es un campo requerido'),
          password: yupString()
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
              'Por seguridad, la contraseña debe tener al menos 8 caracteres: mínimo 1 letra, mínimo 1 número y mínimo 1 caracter especial'
            )
            .required('Contraseña es un campo requerido'),
          confirmPassword: yupString()
            .oneOf(
              [yupRef('password'), null],
              'Las contraseñas deben ser iguales'
            )
            .required('Debe confirmar la contraseña'),
          type: yupString().nullable()
            .matches(
              /(medic|patient|owner|callcenter)/,
              'Debe seleccionar un tipo'
            )
            .required('Debe seleccionar un tipo') //TODO NOT = nul
        })}
        onSuccess={data => {
          history.goBack()
        }}
        submitButtonTitle='Registrarse'
        wrapper={ScrollView}
        wrapperProps={{
          contentContainerStyle: {
            maxWidth: 350,
            width: '100%',
            alignSelf: 'center',
            marginLeft: 70,
            marginRight: 70
          },
          keyboardShouldPersistTaps: 'handled'
          // showsVerticalScrollIndicator: false
        }}
      >
        {form => (
          <Fragment>
            <Title margins='30px 0 50px'>REGISTRO</Title>
            <Container
              flexDirection='row'
              justifyContent='center'
              alignItems='stretch'
            >
              <InputField
                width='50%'
                placeholder='Primer Nombre'
                name='firstName1'
                maxLength={512}
                icon={faUser}
              />
              <InputField
                width='50%'
                placeholder='Segundo Nombre'
                name='firstName2'
                maxLength={512}
              />
            </Container>
            <Container
              flexDirection='row'
              justifyContent='center'
              alignItems='stretch'
              margins={fieldMargin}
            >
              <InputField
                width='50%'
                placeholder='Primer Apellido'
                name='lastName1'
                maxLength={512}
                icon={faUser}
              />
              <InputField
                width='50%'
                placeholder='Segundo Apellido'
                name='lastName2'
                maxLength={512}
              />
            </Container>

            <InputField
              margins={fieldMargin}
              placeholder='Cédula de Identidad'
              name='idNumber'
              maxLength={10}
              icon={faIdCard}
            />
            <InputField
              margins={fieldMargin}
              placeholder='Correo Electrónico'
              name='username'
              autoCapitalize='none'
              keyboardType='email-address'
              maxLength={256}
              icon={faEnvelope}
            />
            <InputField
              margins={fieldMargin}
              secureTextEntry
              name='password'
              placeholder='Contraseña'
              icon={faLock}
            />
            <InputField
              margins={fieldMargin}
              secureTextEntry
              name='confirmPassword'
              placeholder='Confirmar Contraseña'
              icon={faLock}
            />
            <SelectField
              margins={fieldMargin}
              name='type'
              options={userTypeOptions}
              icon={faSortDown}
            />
          </Fragment>
        )}
      </FormFinal>

      <Label margins='0 0 40px' align='center' color={theme.globalFontColor}>
        Una vez que te registras, aceptas los términos y condiciones
      </Label>
    </Container>
  )
}

export default withTheme(withRouter(Register))
