/**
 * Appointment For Doctor View
 *
 * @format
 * @flow
 */

import React, { Component, Fragment } from 'react'
import { ActivityIndicator, Image, TouchableOpacity } from 'react-native'
import { ThemeProvider, withTheme } from 'styled-components'
import { string as yupString, object as yupObject } from 'yup'
import { format } from 'date-fns'
import { debounce } from 'lodash'
import {
  faCrutch,
  faCalendarDay,
  faMapMarkedAlt
} from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Toast from '../../components/Toast'
import CurrentPosition from '../../components/CurrentPosition'
import { Marker } from 'google-maps-react'
import { MapView } from '../../components/MapView'
import FormFinal from '../../components/form/FormFinal'
import FormIconButton from '../../components/form/FormIconButton'
import InputField from '../../components/form/InputField'
import SelectField from '../../components/form/SelectField'
// import DateField from '../../components/form/DateField'
import TimeField from '../../components/form/TimeField'
import FontAwesomeLink from '../../components/FontAwesomeLink'
import CardView from '../../components/CardView'
import { Container, Title, Label } from '../../components/common'
import { withRouter } from '../../utility/routing'
import { doFetch } from '../../utility/Util'
import docIcon from '../../assets/images/doc.png'
import Modal from '../../components/Modal'
import userPlaceholder from '../../assets/images/user-icon-placeholder.png'

const InfoWrapper = ({ title, children }) => (
  <Container flexDirection='row' justifyContent='space-between' margins='5px 0'>
    <Label color='#313c68' fontSize='15px' fontWeight='bold'>
      {title}
    </Label>
    <Container width='70%'>{children}</Container>
  </Container>
)

class PatientView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      degree: null,
      doctorsList: [],
      degreesList: [],
      appointments: null,
      newAppointments: [[], [], []],
      loadingNearDoctors: false,
      modalVisible: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.debouncedNameQuery = debounce(this.queryName.bind(this), 300)
    this.debouncedDegreeQuery = debounce(this.queryDegree.bind(this), 300)
    this.queryPatientAppointments = this.queryPatientAppointments.bind(this)
    this.selectCell = this.selectCell.bind(this)
    this.selectDoctor = this.selectDoctor.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.scheduleDate = this.scheduleDate.bind(this)
  }

  componentDidMount() {
    this.setState({ loadingNearDoctors: true })
    const { location } = this.props
    const patient = location.state && {
      id: location.state.patientId,
      version: location.state.patientVersion
    }
    doFetch({
      endpoint: 'getNearDoctors',
      body: JSON.stringify(patient),
      onOK: data =>
        this.setState({
          nearDoctors: data,
          loadingNearDoctors: false
        }),
      onNotOK: data =>
        this.setState({
          nearDoctors: null,
          userLocationError: true,
          loadingNearDoctors: false
        })
    })
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    })
    if (name === 'degree') {
      this.debouncedDegreeQuery(value)
    } else {
      this.debouncedNameQuery(value)
    }
  }

  queryName(text) {
    doFetch({
      endpoint: 'findDoctor',
      body: text,
      onOK: data => this.setState({ doctorsList: data })
    })
  }

  queryDegree(text) {
    doFetch({
      endpoint: 'findDegree',
      body: text,
      onOK: data => this.setState({ degreesList: data })
    })
  }

  queryPatientAppointments() {
    const { patient, room } = this.state

    doFetch({
      endpoint: 'getPatientAppointments',
      body: JSON.stringify({
        consultingRoom: { id: room.id, version: room.version },
        doctor: { id: patient.id, version: patient.version }
      }),
      onOK: data => {
        // Appointment from this authenticated patient
        if (data && data[0] && data[0].newAppointments) {
          data[0].newAppointments.forEach(newAppointments => {
            newAppointments.forEach(cell => {
              this.selectCell(cell.column, cell.index, false, true)
            })
          })
        }

        // Appointment from other patients
        if (data && data[1] && data[1].newAppointments) {
          data[1].newAppointments.forEach(newAppointments => {
            newAppointments.forEach(cell => {
              this.selectCell(cell.column, cell.index, true, false)
            })
          })
        }
        this.setState({ appointments: data })
      }
    })
  }

  selectCell(column, index, disabled, value) {
    this.setState((prevState, props) => {
      let newRentsArray = prevState.newAppointments,
        arrayColumn = prevState.newAppointments[column]

      arrayColumn[index] = {
        column,
        index,
        value:
          value === undefined
            ? arrayColumn[index] && arrayColumn[index].value
              ? false
              : true
            : value,
        disabled
      }
      newRentsArray[column] = arrayColumn
      return { ...prevState, newAppointments: newRentsArray }
    })
  }

  selectDoctor(doctor) {
    this.setState({
      doctorName: doctor.person.firstName + ' ' + doctor.person.surName,
      doctorsList: [],
      doctor
    })
  }

  selectDegree(degree) {
    this.setState({
      degree: degree.degree,
      degreesList: []
    })
  }

  handleSelectChange(itemValue, itemPosition) {
    this.setState({
      type: itemValue,
      choosenIndex: itemPosition
    })
  }

  scheduleDate() { }

  render() {
    const { theme, location } = this.props,
      {
        nearDoctors,
        doctorSelected,
        scheduleDateError,
        userLocationError,
        loadingNearDoctors,
        modalVisible
      } = this.state,
      fieldMargin = '20px 0 0',
      textAreaTheme = {
        field: {
          ...theme.field,
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderLeftWidth: '1px',
          borderRightWidth: '1px',
          borderRadius: '5px'
        }
      }

    return (
      <CurrentPosition flex={1}>
        {({ longitude, latitude, error }) =>
          longitude && latitude ? (
            <Fragment>
              <MapView
                style={{ flex: 1 }}
                region={{
                  latitude: doctorSelected
                    ? doctorSelected.person.addresses[0].location.latitude
                    : nearDoctors &&
                      nearDoctors[0] &&
                      nearDoctors[0].person.addresses &&
                      nearDoctors[0].person.addresses[0] &&
                      nearDoctors[0].person.addresses[0].location &&
                      nearDoctors[0].person.addresses[0].location.latitude
                      ? nearDoctors[0].person.addresses[0].location.latitude
                      : latitude,
                  longitude: doctorSelected
                    ? doctorSelected.person.addresses[0].location.longitude
                    : nearDoctors &&
                      nearDoctors[0] &&
                      nearDoctors[0].person.addresses &&
                      nearDoctors[0].person.addresses[0] &&
                      nearDoctors[0].person.addresses[0].location &&
                      nearDoctors[0].person.addresses[0].location.longitude
                      ? nearDoctors[0].person.addresses[0].location.longitude
                      : longitude,
                  latitudeDelta: 0.02,
                  longitudeDelta: 0.04
                }}
                showsUserLocation
                toolbarEnabled={false}
                zoomControl
              >
                {nearDoctors &&
                  nearDoctors.map(doctor => {
                    if (
                      doctor.person.addresses &&
                      doctor.person.addresses[0] &&
                      doctor.person.addresses[0].location &&
                      doctor.person.addresses[0].location.latitude
                    )
                      return (
                        <Marker
                          key={doctor.id}
                          position={{
                            lat: doctor.person.addresses[0].location.latitude,
                            lng: doctor.person.addresses[0].location.longitude
                          }}
                          identifier={doctor.id}
                          icon={{ url: docIcon }}
                          onClick={selProps => {
                            this.setState({
                              selProps: selProps,
                              doctorSelected: doctor
                            })
                          }}
                        />
                      )
                    return null
                  })}
              </MapView>

              <Container
                backgroundColor='rgba(255,255,255,0.7)'
                alignItems='center'
                width='100%'
                paddings='15px'
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0
                }}
              >
                {loadingNearDoctors ? (
                  <Container alignItems='center'>
                    <ActivityIndicator />
                    <Title>{'Cargando Doctores'}</Title>
                  </Container>
                ) : userLocationError ? (
                  <Fragment>
                    <Title>{'No se ha configurado la ubicación'}</Title>
                    <FontAwesomeLink
                      link='/map'
                      icon={faMapMarkedAlt}
                      title='Configurar Ubicación'
                      color={theme.globalBlue}
                      state={{ allowSchedule: true }}
                    />
                  </Fragment>
                ) : (
                      <Title>
                        {'Se muestran los médicos más cercanos y disponibles: ' +
                          nearDoctors.length}
                      </Title>
                    )}
              </Container>

              {scheduleDateError && (
                <Container
                  backgroundColor='rgba(255,255,255,0.7)'
                  style={{
                    position: 'absolute',
                    bottom: 125,
                    left: 0,
                    width: '100%',
                    alignItems: 'center'
                  }}
                >
                  <Label
                    margins='10px 0'
                    fontWeight='bold'
                    fontSize='20'
                    color='red'
                    align='center'
                  >
                    {scheduleDateError}
                  </Label>
                </Container>
              )}

              {doctorSelected && (
                <Fragment>
                  <Container
                    backgroundColor='rgba(255,255,255,0.7)'
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      height: '100%',
                      width: '25%',
                      minWidth: 300
                    }}
                  >
                    <Container paddings='15px'>
                      <Container flex={1}>
                        <Container
                          flexDirection='row'
                          justifyContent='center'
                          margins='10px'
                        >
                          <Image
                            style={{ width: 70, height: 70, borderRadius: 70 }}
                            source={{
                              uri: doctorSelected.person.photo
                                ? doctorSelected.person.photo.photo
                                : userPlaceholder
                            }}
                          />
                        </Container>

                        <InfoWrapper title='Doctor:'>
                          <Label
                            color={theme.globalError}
                            fontSize='15px'
                            fontWeight='bold'
                          >
                            {doctorSelected.person.firstName +
                              ' ' +
                              (doctorSelected.person.surName
                                ? doctorSelected.person.surName
                                : '')}
                          </Label>
                        </InfoWrapper>

                        <InfoWrapper title='Registro Senescyt:'>
                          <Label color={theme.globalBlue} fontSize='15px'>
                            {doctorSelected.person.degrees &&
                              doctorSelected.person.degrees[0] &&
                              doctorSelected.person.degrees[0].code}
                          </Label>
                        </InfoWrapper>

                        <InfoWrapper title='Dirección:'>
                          <Label color={theme.globalBlue} fontSize='15px'>
                            {doctorSelected.person.addresses &&
                              doctorSelected.person.addresses[0] &&
                              doctorSelected.person.addresses[0].location &&
                              doctorSelected.person.addresses[0].location
                                .description}
                          </Label>
                          {doctorSelected.person.addresses &&
                            doctorSelected.person.addresses[0] &&
                            doctorSelected.person.addresses[0].address2 && (
                              <Label color={theme.globalBlue} fontSize='15px'>
                                {doctorSelected.person.addresses[0].address2}
                              </Label>
                            )}
                        </InfoWrapper>
                      </Container>

                      <TouchableOpacity
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingTop: 10
                        }}
                        onPress={() => {
                          this.setState({ modalVisible: true })
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCalendarCheck}
                          size={35}
                          color='#bd2a16'
                        />
                        <Label color='#bd2a16' fontSize={15} fontWeight='bold'>
                          {'Agendar Cita'}
                        </Label>
                      </TouchableOpacity>
                    </Container>
                  </Container>

                  <Modal
                    backdropColor='white'
                    isVisible={modalVisible}
                    onBackdropPress={() => this.setState({ modalVisible: false })}
                    onBackButtonPress={() =>
                      this.setState({ modalVisible: false })
                    }
                  >
                    <CardView
                      cardElevation={5}
                      cardMaxElevation={5}
                      cornerRadius={15}
                      style={{
                        borderColor: theme.globalBlue
                      }}
                      backgroundColor={theme.globalBackground}
                      justifyContent='center'
                      alignItems='center'
                      paddings='20px'
                      height={400}
                    >
                      <FormFinal
                        endpoint='scheduleDate2'
                        initialValues={{
                          doctor: {
                            id: doctorSelected.id,
                            version: doctorSelected.version
                          },
                          patient:
                            location.state && location.state.patientId
                              ? {
                                id: location.state.patientId,
                                version: location.state.patientVersion
                              }
                              : null,
                          triaje: location.state &&
                            location.state.triaje && {
                            id: location.state.triaje.id,
                            version:
                              location.state.triaje.version,
                            briefDiagnosis:
                              location.state &&
                              location.state.triaje &&
                              location.state.triaje.briefDiagnosis
                          }
                        }}
                        validationSchema={yupObject().shape({
                          doctor: yupObject().required('Ingrese su nombre'),
                          time: yupString()
                            .matches(/^([7-9]|0[7-9]|1[0-8]):[0-5][0-9]$/, {
                              message:
                                'Solo se puede agendar desde las 7:00 hasta las 18:59',
                              excludeEmptyString: true
                            })
                            .matches(
                              /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                              {
                                message: 'Hora no válida',
                                excludeEmptyString: true
                              }
                            )
                            .required(
                              'Ingrese la Hora para la visita del Médico'
                            ),
                          date: yupString()
                            .nullable()
                            .matches(
                              /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                              'Formato inválido, el correcto es AAAA-MM-DD (Por ejemplo: 1970-06-25)'
                            )
                            .required(
                              'Ingrese la fecha para la visita del Médico'
                            ),
                          triaje: yupObject().shape({
                            briefDiagnosis: yupString().required(
                              'Ingrese una breve descripción de sus dolencias'
                            )
                          })
                            .required('Ingrese su nombre'),

                        })}
                        onSuccess={data => {
                          this.setState({ modalVisible: false }, () => {
                            Toast({
                              message: 'La cita se ha agendado correctamente',
                              duration: 4000,
                              backgroundColor: theme.globalBlue
                            })
                          })
                        }}
                        onError={data => {
                          if (data.message === 'Appointment already set') {
                            Toast({
                              message:
                                'La cita ya ha sido agendada, no se puede agendar otra cita por el momento.',
                              duration: 4000,
                              backgroundColor: theme.globalError
                            })
                          }
                        }}
                        submitButtonTitle='AGENDAR!'
                        wrapper={Container}
                        wrapperProps={{
                          alignItems: 'center',
                          paddings: '20px'
                        }}
                      >
                        {form => (
                          <Fragment>
                            <Title margins='0 0 30px'>
                              ¿A qué hora desea la visita del médico?
                            </Title>

                            <FormIconButton
                              color={theme.globalBlue}
                              margins='10px'
                              paddings='5px'
                              title='Inmediatamente'
                              borderTopWidth='0px'
                              borderRightWidth='0px'
                              borderBottomWidth='0px'
                              borderLeftWidth='0px'
                              onPress={() => {
                                var today = new Date(),
                                  minute,
                                  hour
                                if (today.getMinutes() > 30) {
                                  minute = '00'
                                  hour = today.getHours() + 1
                                } else {
                                  minute = '30'
                                  hour = today.getHours()
                                }
                                hour =
                                  hour < 7
                                    ? '07'
                                    : hour < 10
                                      ? '0' + hour
                                      : '' + hour
                                if (today.getHours() >= 19) {
                                  form.change(
                                    'date',
                                    format(
                                      new Date().setDate(
                                        new Date().getDate() + 1
                                      ),
                                      'yyyy-MM-dd'
                                    )
                                  )
                                } else {
                                  form.change(
                                    'date',
                                    format(new Date(), 'yyyy-MM-dd')
                                  )
                                }

                                var time = hour + ':' + minute
                                form.change('time', time)
                              }}
                            />

                            <Container flexDirection='row'>
                              <SelectField
                                width='50%'
                                placeholder='Seleccione'
                                options={[
                                  {
                                    value: format(
                                      new Date().setDate(
                                        new Date().getDate() - 1
                                      ),
                                      'yyyy-MM-dd'
                                    ),
                                    label: 'Ayer'
                                  },
                                  {
                                    value: format(new Date(), 'yyyy-MM-dd'),
                                    label: 'Hoy'
                                  },
                                  {
                                    value: format(
                                      new Date().setDate(
                                        new Date().getDate() + 1
                                      ),
                                      'yyyy-MM-dd'
                                    ),
                                    label: 'Mañana'
                                  }
                                ]}
                                name='date'
                                icon={faCalendarDay}
                              />
                              {/*<DateField
                                width='50%'
                                placeholder='AAAA-MM-DD'
                                name='date'
                                minDate={format(new Date().setDate((new Date()).getDate() - 1), 'yyyy-MM-dd')}
                                maxDate={format(new Date().setDate((new Date()).getDate() + 1), 'yyyy-MM-dd')}
                                disabled
                              />*/}
                              <TimeField
                                icon={faClock}
                                name='time'
                                width='50%'
                              />
                            </Container>

                            {location.state &&
                              location.state.hideBriefDiagnosis ? null : (
                                <ThemeProvider theme={textAreaTheme}>
                                  <InputField
                                    width='90%'
                                    margins={fieldMargin}
                                    placeholder='Me duele... (Ingrese toda la información que crea necesaria para que el Médico lo atienda de la mejor forma)'
                                    name='triaje.briefDiagnosis'
                                    icon={faCrutch}
                                    multiline
                                    height={80}
                                    textAlignVertical='top'
                                  />
                                </ThemeProvider>
                              )}
                          </Fragment>
                        )}
                      </FormFinal>
                    </CardView>
                  </Modal>
                </Fragment>
              )}
            </Fragment>
          ) : null
        }
      </CurrentPosition>
    )
  }
}

export default withTheme(withRouter(PatientView))
