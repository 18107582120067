/**
 * Header Component
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {Image} from 'react-native'
import styled, {withTheme} from 'styled-components'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {withRouter} from '../../utility/routing'
import FormIconButton from '../form/FormIconButton'
import {LinearGradientStyled} from '../common'
import doctusLogo from '../../assets/images/doctus_logo.png'
// import doctusLogoVaciado from '../../assets/images/doctus_logo_vaciado.png'

const StyledImage = styled(Image)`
  ${props => props.width && `width: ${props.width};`}
  ${props => props.height && `height: ${props.height};`}
`

class Header extends Component {
  onBackPress = () => {
    this.props.history.goBack()
  }

  render() {
    const {
      theme,
      color = theme.header.color,
      flex = theme.header.flex,
      alignItems = theme.header.alignItems,
      justifyContent = theme.header.justifyContent,
      backgroundColor = theme.header.backgroundColor || 'transparent',
      backgroundColor1 = theme.header.backgroundColor1 || 'transparent',
      home,
      flexDirection,
      homePress,
      ...restProps
    } = this.props
    const centered = theme.header.justifyContent !== 'center' ? false : true

    return (
      <LinearGradientStyled
        {...restProps}
        height={theme.header.height || '60px'}
        flexDirection={flexDirection || 'row'}
        flex={flex}
        alignItems={alignItems}
        justifyContent={justifyContent}
        paddings={!centered && '20px'}
        colors={[backgroundColor, backgroundColor1]}
      >
        <StyledImage
          source={centered ? doctusLogo : doctusLogo}
          alt='logo'
          width={centered ? '259px' : theme.header.logo.width}
          height={centered ? '82px' : theme.header.logo.height}
        />
        {!centered ? (
          <FormIconButton
            paddings={home ? '10px 0 10px 10px' : '10px 10px 10px 0'}
            margins='0'
            iconSize={20}
            color={color}
            borderColor='transparent'
            leftIcon={home ? home : faArrowLeft}
            onPress={home ? homePress : this.onBackPress}
          />
        ) : null}
      </LinearGradientStyled>
    )
  }
}

export default withTheme(withRouter(Header))
