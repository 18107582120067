import React, {Fragment} from 'react'
import {
  VirtualizedList,
  ScrollView,
  TouchableOpacity
} from 'react-native'
import {withTheme} from 'styled-components'
import {Container, Label} from '../common'

/*
 * PagedList component
 */
const PagedList = ({
  theme,
  page,
  rows,
  totalPages,
  contentStyle,
  onPageChange,
  ...restProps
}) => {
  let pages = []
  for (let i = 0; i < totalPages; i++) {
    pages.push(i + 1)
  }
  return (
    <Fragment>
      <ScrollView
        horizontal
        style={{width: '100%'}}
        contentContainerStyle={contentStyle}
      >
        <VirtualizedList
          {...restProps}
          data={rows || []}
          initialNumToRender={10}
          stickyHeaderIndices={[0]}
          getItemCount={data => (data ? data.length : 0)}
          getItem={(data, i) => data[i]}
          keyExtractor={(item, index) => 'item' + index}
          // contentContainerStyle={contentStyle}
        />
      </ScrollView>
      {totalPages > 1 && (
        <Container margins='10px' alignItems='center' justifyContent='center' flexDirection='row' width='100%'>

          <ScrollView horizontal
          // style={{flex: 1}}

          // contentContainerStyle={{
          //   // margins: '10px',
          //   // alignItems: 'center',
          //   justifyContent: 'center',
          //   // flexDirection: 'row'
          // }}
          >
            {/* <Label color={theme.globalBlue}>{"Páginas: "}</Label> */}
            {pages.map((pageNumber, i) => (
              <TouchableOpacity
                key={i}
                style={{
                  paddingVertical: 10,
                  paddingHorizontal: 15,
                  borderWidth: 1,
                  borderLeftWidth: i !== 0 ? 0 : 1,
                  borderRightWidth: i !== pages.length - 1 ? 0 : 1,
                  borderColor: theme.globalBlue,
                  borderRadius: 5,
                  backgroundColor: page === i ? theme.globalBlue : theme.globalBackground
                }}
                onPress={() => onPageChange(i)}
              >
                <Label color={page === i ? theme.globalBackground : theme.globalBlue}>
                  {pageNumber}
                </Label>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </Container>
      )}
    </Fragment>
  )
}

export default withTheme(PagedList)
