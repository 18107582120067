/**
 * Home View with a Map
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {withTheme} from 'styled-components'
import {
  faBars,
  faList,
  faTimes,
  faUserMd
} from '@fortawesome/free-solid-svg-icons'
import {Route, withRouter} from '../../utility/routing'
import Toast from '../../components/Toast'
import Header from '../../components/logic/Header'
import FontAwesomeLink from '../../components/FontAwesomeLink'
import MapHome from './MapHome'
import Profile from '../profile/Profile'
import EditUser from '../profile/EditUser'
import ProfileLocation from '../profile/ProfileLocation'
import History from '../history/History'
import ClinicalHistory from '../history/ClinicalHistory'
import Appointment from '../appointment/View'
import AppointmentList from '../appointment/List'
import AppointmentReport from '../report/Appointments'
import AppointmentByDoctorReport from '../report/AppointmentsByDoctor'
import UnappointedPatients from '../report/UnappointedPatients'
import PatientAppointment from '../appointment/PatientView'
import ConsultingRoomList from '../consultingRoom/ConsultingRoomList'
import Inspect from '../consultingRoom/Inspect2'
import List from '../permission/List'
import Schedule from '../schedule/Schedule'
import CardView from '../../components/CardView'
import BottomMenu from '../../components/logic/BottomMenu'
import SecuredComponent from '../../components/common/SecuredComponent'
import {Container, Label, Title} from '../../components/common'
import GenericPage from '../../components/common/GenericPage'
import FormIconButton from '../../components/form/FormIconButton'
import Modal from '../../components/Modal'
import {getToken, getStoredSessionInfo} from '../../components/logic/Authentication'
import {global} from '../../utility/Util'
import storage from '../../utility/storage'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuVisible: false
    }

    this.showMenu = this.showMenu.bind(this)
    this.logout = this.logout.bind(this)
  }

  async componentDidMount() {
    const sessionInfo = await getStoredSessionInfo()
    this.setState({sessionInfo})
  }

  showMenu(visible) {
    this.setState({menuVisible: visible})
  }

  logout() {
    getToken()
      .then(token => {
        fetch(global.serverURL + 'logout', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'X-CSRF-TOKEN': token
          },
          credentials: 'include'
        }).then(response => {
          storage.removeItem('csrfToken')
          storage.removeItem('user')
          this.props.disconnectFunction()
          this.props.history.push('/')
        })
      })
      // TODO do we need this catch?
      .catch(errToken => {})
  }

  render() {
    const {theme} = this.props,
      {sessionInfo} = this.state,
      admin = sessionInfo && sessionInfo.user.type.code === "superuser"
    return (
      <GenericPage
        home={faBars}
        homePress={() => this.showMenu(!this.state.menuVisible)}
      >
        <Modal
          isVisible={this.state.menuVisible}
          backdropColor='white'
          backdropOpacity={0.8}
        >
          <CardView
            margins='40px 30px'
            flex={1}
            backgroundColor={theme.globalBackground}
            cardElevation={5}
            cardMaxElevation={5}
            cornerRadius={15}
            style={{
              borderColor: theme.globalBlue
            }}
          >
            <Header
              home={faTimes}
              color='black'
              backgroundColor={theme.globalBackground}
              backgroundColor1={theme.globalBackground}
              borderRadius="15px"
              homePress={() => this.showMenu(!this.state.menuVisible)}
            />
            <Container alignItems='flex-start'>
              {/*<FormIconButton
                margins='2px 20px'
                paddings='8px 0'
                color='black'
                borderColor='transparent'
                title='Ajustes de pago'
                onPress={() => this.showMenu(true)}
              />*/}

              <FormIconButton
                margins='2px 20px'
                paddings='8px 0'
                color='black'
                borderColor='transparent'
                title='Acerca de Doctus' //'Términos y Condiciones'
                onPress={() => {
                  this.setState({menuVisible: !this.state.menuVisible}, () => {
                    Toast({
                      message: 'Doctus EC\n\nhttp://www.doctus.com.ec',
                      duration: 4000, //Toast.durations.LONG,
                      position: 70, //Toast.positions.TOP,
                      backgroundColor: this.props.theme.globalBlue,
                      opacity: 0.75
                    })
                  })
                }}
              />

              <FormIconButton
                margins='2px 20px'
                paddings='8px 0'
                color='black'
                borderColor='transparent'
                title='Cerrar Sesión'
                onPress={() => {
                  this.logout()
                }}
              />
            </Container>
          </CardView>
        </Modal>
        <Container flex={1}>
          <SecuredComponent
            checkPermissions={{
              objects: 'Map',
              actions: ['read']
            }}
          >
            <Route path='/' exact component={MapHome} />
          </SecuredComponent>

          {/* Home Profile for Patient */}
          <SecuredComponent
            checkPermissions={{
              objects: 'ProfileHome',
              actions: ['read']
            }}
          >
            {!admin && <Route path='/' exact component={Profile} />}
          </SecuredComponent>

          {/* Patient Appointment */}
          <SecuredComponent
            checkPermissions={{
              objects: 'PatientAppointment',
              actions: ['read']
            }}
          >
            <Route path='/patientappointment' render={() => <PatientAppointment />} />
          </SecuredComponent>

          {/* AGENDAR PACIENTE */}
          <SecuredComponent
            checkPermissions={{
              objects: 'DoctorAppointment',
              actions: ['create']
            }}
          >
            <Route path='/appointments' render={rP => <AppointmentList {...rP} />} />
            <Route path='/clinicalHistory' render={rP => <ClinicalHistory {...rP} />} />
            {/*<Route path='/maps' render={({location, ...rP}) => {
              // console.log('rp', location);
              window.open(location.state.link)
              return null
            }} />*/}
          </SecuredComponent>

          <SecuredComponent
            checkPermissions={{
              objects: 'CallCenter',
              actions: ['read']
            }}
          >
            {!admin && <Route path='/' exact component={UnappointedPatients} />}
            <Route path='/mapa' render={() => <MapHome />} />
            <Route path='/edituser' render={() => <EditUser />} />
            <Route path='/patientappointment' render={() => <PatientAppointment />} />
            <Route path='/appointments' render={rP => <AppointmentList {...rP} />} />
            <Route path='/clinicalHistory' render={rP => <ClinicalHistory {...rP} />} />
            <Route path='/report' render={() => (
              <Container flexDirection='row' justifyContent='center'>
                <Container margins='20px'>
                  <FontAwesomeLink
                    link='/report/appointments'
                    icon={faList}
                    title='Agendamientos'
                  />
                </Container>
                <Container margins='20px'>
                  <FontAwesomeLink
                    link='/report/doctorappointments'
                    icon={faUserMd}
                    title='Agendamientos por Doctor'
                  />
                </Container>
              </Container>
            )} />
            <Route path='/report/appointments' render={() => <AppointmentReport />} />
            <Route path='/report/doctorappointments' render={() => <AppointmentByDoctorReport />} />
          </SecuredComponent>

          <SecuredComponent
            checkPermissions={{
              objects: 'IESSCallCenter',
              actions: ['read']
            }}
          >
            {!admin
              && <Route path='/' exact render={() => (
                <Container flex={1} alignItems='center'>
                  <Title margins='100px 0'>¡Bienvenido!</Title>
                  <Label fontSize='20px' color={this.props.theme.globalGreen}>
                    Haga clic en Crear Paciente para empezar...
                  </Label>
                </Container>
              )}
            />}
            <Route path='/edituser' exact render={() => <EditUser />} />
            <Route path='/patients' component={UnappointedPatients} />
            <Route path='/patientappointment' render={() => <PatientAppointment />} />
          </SecuredComponent>
          <Route path='/profile' render={() => <Profile />} />
          <Route path='/appointment' render={() => <Appointment />} />
          <Route path='/history' render={() => <History />} />
          <Route path='/schedule' render={() => <Schedule />} />
          <Route exact path='/consultingrooms' render={() => <ConsultingRoomList />} />
          <Route exact path='/map' render={() => <ProfileLocation />} />
          <Route exact path='/consultingrooms/:id' render={() => <Inspect />} />
          <Route path='/permission' component={List} />
        </Container>

        <BottomMenu admin={admin} />
      </GenericPage>
    )
  }
}

export default withRouter(withTheme(Home))
