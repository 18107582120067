/**
 * Unappointed Patients
 *
 * @format
 * @flow
 */

import React, {Component, Fragment} from 'react'
import {ActivityIndicator} from 'react-native'
import {withTheme} from 'styled-components'
import {format, differenceInYears} from 'date-fns'
import {es} from 'date-fns/locale'
import {faAsterisk, faSortUp} from '@fortawesome/free-solid-svg-icons'
import {faEdit, faHandPointRight} from '@fortawesome/free-regular-svg-icons'
import {omit} from 'lodash'
import {string as yupString, object as yupObject} from 'yup'
import {Container, Label, TableLabel, Title} from '../../components/common'
import {withRouter} from '../../utility/routing'
import FormIconButton from '../../components/form/FormIconButton'
import PagedList from '../../components/PagedList'
import CardView from '../../components/CardView'
import Modal from '../../components/Modal'
import FormFinal from '../../components/form/FormFinal'
import Toast from '../../components/Toast'
import SecuredComponent from '../../components/common/SecuredComponent'
import {doFetch, hasPermissions} from '../../utility/Util'
import {getStoredSessionInfo} from '../../components/logic/Authentication'

class UnappointedPatients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unappointedPatients: [],
      loading: true,
      page: 0,
      size: 10, //PAGE SIZE
      totalPages: 0
    }
    this.getUnappointedPatients = this.getUnappointedPatients.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.page !== prevState.page) {
      this.getUnappointedPatients()
    }
  }

  componentDidMount() {
    this.getUnappointedPatients()
    getStoredSessionInfo()
      .then(data => {
        this.setState({
          iessCallCenterPermission: hasPermissions(data.permissions, {
            objects: 'IESSCallCenter',
            actions: ['read']
          })
        })
      })
      .catch(e => {})
  }

  getUnappointedPatients() {
    doFetch({
      endpoint: 'getUnappointedPatients',
      params: [{page: this.state.page}, {size: this.state.size}],
      onOK: data => {
        let patientAdmissions = [],
          patients = [],
          triajes = []

        data.content.forEach(({patient, triaje, ...patientAdmission}) => {
          let tempPatient = {},
            tempTriaje = {}
          if (patient.id) {
            patients.push(patient)
            tempPatient = patient
          } else {
            tempPatient = patients.find(p => p.id === patient)
          }
          if (triaje.id) {
            triajes.push(triaje)
            tempTriaje = triaje
          } else {
            tempTriaje = triajes.find(p => p.id === triaje)
          }
          patientAdmissions.push({
            ...patientAdmission,
            patient: tempPatient,
            triaje: tempTriaje
          })
        })
        this.setState({
          unappointedPatients: patientAdmissions,
          loading: false,
          totalPages: data.totalPages
        })
      },
      onNotOK: () => this.setState({loading: false})
    })
  }
  render() {
    const {
        unappointedPatients,
        loading,
        orderedBy,
        iessCallCenterPermission,
        totalPages,
        patientAdmission,
        statusesVisible
      } = this.state,
      {theme, history} = this.props

    return (
      <Container alignItems='center' width='100%' flex={1}>
        <SecuredComponent
          checkPermissions={{
            objects: 'IESSCallCenter',
            actions: ['read']
          }}
        >
          <Title margins='10px'>
            Pacientes No Agendados por Error de Contacto
          </Title>
        </SecuredComponent>

        <SecuredComponent
          checkPermissions={{
            objects: 'CallCenter',
            actions: ['read']
          }}
        >
          <Title margins='10px'>Pacientes IESS No Agendados</Title>
        </SecuredComponent>

        {loading && <ActivityIndicator />}

        <PagedList
          rows={unappointedPatients}
          contentStyle={{
            alignItems: 'center',
            flexDirection: 'column',
            minWidth: iessCallCenterPermission ? 1500 : 1770,
            width: '100%'
          }}
          totalPages={totalPages}
          onPageChange={i => {
            if (this.state.page !== i) {
              this.setState({page: i, loading: true})
            }
          }}
          page={this.state.page}
          refreshing={loading}
          contentContainerStyle={{alignItems: 'center'}}
          ListEmptyComponent={
            !loading && (
              <Label fontWeight='bold' color={theme.globalBlue}>
                No hay datos para mostrar
              </Label>
            )
          }
          ListHeaderComponent={
            !loading && (
              <Container
                flexDirection='row'
                justifyContent='flex-start'
                paddings='5px 10px'
                backgroundColor={theme.globalBackground}
              >
                <TableLabel width='70px' />
                <Container flexDirection='row' width='120px' paddings='5px'>
                  <TableLabel
                    width='80px'
                    paddings='0px'
                    fontSize='17px'
                    color={theme.globalGreen}
                  >
                    Fecha de ingreso
                  </TableLabel>
                  <FormIconButton
                    borderTopWidth='0px'
                    borderRightWidth='0px'
                    borderBottomWidth='0px'
                    borderLeftWidth='0px'
                    margins='0px'
                    paddings='5px 0 0'
                    color={
                      orderedBy === 'createdOn'
                        ? theme.globalBlue
                        : theme.globalGreen
                    }
                    leftIcon={faSortUp}
                    onPress={() => {
                      if (orderedBy !== 'createdOn') {
                        this.setState({
                          unappointedPatients: unappointedPatients.sort(
                            (a, b) => {
                              if (a.updatedOn > b.updatedOn) {
                                return 1
                              }
                              if (a.updatedOn < b.updatedOn) {
                                return -1
                              }
                              return 0
                            }
                          ),
                          orderedBy: 'createdOn'
                        })
                      }
                    }}
                  />
                </Container>
                {!iessCallCenterPermission && <TableLabel width="120px" fontSize='17px' color={theme.globalGreen}>
                  Estado del Ingreso
                </TableLabel>}
                <TableLabel fontSize='17px' color={theme.globalGreen}>
                  Cédula
                </TableLabel>
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='200px'
                >
                  Nombres y Apellidos
                </TableLabel>
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='70px'
                >
                  Tipo de Sangre
                </TableLabel>
                <TableLabel fontSize='17px' color={theme.globalGreen}>
                  Teléfonos
                </TableLabel>
                <TableLabel fontSize='17px' color={theme.globalGreen}>
                  Edad
                </TableLabel>
                <Container flexDirection='row' width='250px' paddings='5px'>
                  <TableLabel
                    fontSize='17px'
                    color={theme.globalGreen}
                    paddings='0'
                  >
                    Dirección
                  </TableLabel>
                  <FormIconButton
                    borderTopWidth='0px'
                    borderRightWidth='0px'
                    borderBottomWidth='0px'
                    borderLeftWidth='0px'
                    margins='0px'
                    paddings='5px 0 0'
                    color={
                      orderedBy === 'address1'
                        ? theme.globalBlue
                        : theme.globalGreen
                    }
                    leftIcon={faSortUp}
                    onPress={() => {
                      if (orderedBy !== 'address1') {
                        this.setState({
                          unappointedPatients: unappointedPatients.sort(
                            (a, b) => {
                              if (
                                !b.patient.person.addresses ||
                                b.patient.person.addresses.length === 0
                              ) {
                                return -1
                              }
                              if (
                                !a.patient.person.addresses ||
                                a.patient.person.addresses.length === 0
                              ) {
                                return 1
                              }
                              if (
                                a.patient.person.addresses[0].address1 >
                                b.patient.person.addresses[0].address1
                              ) {
                                return 1
                              }
                              if (
                                a.patient.person.addresses[0].address1 <
                                b.patient.person.addresses[0].address1
                              ) {
                                return -1
                              }
                              return 0
                            }
                          ),
                          orderedBy: 'address1'
                        })
                      }
                    }}
                  />
                </Container>
                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='250px'
                >
                  Síntomas
                </TableLabel>
                <SecuredComponent
                  checkPermissions={{
                    objects: 'CallCenter',
                    actions: ['read']
                  }}
                >
                  <TableLabel
                    fontSize='17px'
                    color={theme.globalGreen}
                    width='150px'
                  >
                    Enfermedades
                  </TableLabel>
                </SecuredComponent>

                <TableLabel
                  fontSize='17px'
                  color={theme.globalGreen}
                  width='115px'
                >
                  Discapacidad
                </TableLabel>
                <Container flexDirection='row' width='100px' paddings='5px'>
                  <TableLabel
                    fontSize='17px'
                    color={theme.globalGreen}
                    paddings='0'
                    align='right'
                    width='80px'
                  >
                    Categoría
                  </TableLabel>
                  <FormIconButton
                    borderTopWidth='0px'
                    borderRightWidth='0px'
                    borderBottomWidth='0px'
                    borderLeftWidth='0px'
                    margins='0px'
                    paddings='5px 0 0'
                    color={
                      orderedBy === 'atentionLevel'
                        ? theme.globalBlue
                        : theme.globalGreen
                    }
                    leftIcon={faSortUp}
                    onPress={() => {
                      if (orderedBy !== 'atentionLevel') {
                        this.setState({
                          unappointedPatients: unappointedPatients.sort(
                            (a, b) => {
                              if (!a.triaje && !b.triaje) {
                                return 0
                              }
                              if (a.triaje && !b.triaje) {
                                return -1
                              }
                              if (!a.triaje && b.triaje) {
                                return 1
                              }
                              if (
                                a.triaje.atentionLevel > b.triaje.atentionLevel
                              ) {
                                return -1
                              }
                              if (
                                a.triaje.atentionLevel < b.triaje.atentionLevel
                              ) {
                                return 1
                              }
                              return 0
                            }
                          ),
                          orderedBy: 'atentionLevel'
                        })
                      }
                    }}
                  />
                </Container>
              </Container>
            )
          }
          renderItem={({item: patientAdmission, index: i}) => {
            const patient = patientAdmission.patient;
            let patientPhone = ''
            patientPhone =
              patient.person.phones &&
              patient.person.phones.map(
                (phone, i) =>
                  patientPhone + (i === 0 ? '' : ', ') + phone.phoneNumber
              )
            const colorUr = patientAdmission.triaje
                ? patientAdmission.triaje.atentionLevel
                : 'blue',
              ur =
                colorUr === 'yellow'
                  ? 'URGENTE'
                  : colorUr === 'green'
                  ? 'Menos Urgente'
                  : 'No Urgente',
              patientAdmissionStatus =
                patientAdmission.status === "active"
                  ? "Por agendar"
                  : patientAdmission.status === "noAttentionRequired"
                  ? "Ya no requiere atención"
                  : patientAdmission.status === "better"
                  ? "Se siente mejor"
                  : patientAdmission.status === "died"
                  ? "Paciente fallecido"
                  : "Agendado",
              patientStatusColor =
                (patient.person.status === 'wrongData'
                  || patientAdmission.status === 'noAttentionRequired'
                  || patientAdmission.status === 'better'
                  || patientAdmission.status === 'died') &&
                !iessCallCenterPermission
                  ? '#9a9b9d'
                  : theme.globalBlue,
              patientAdmissionStatusColor =
                patient.person.status === 'wrongData'
                  || patientAdmission.status === 'noAttentionRequired'
                  || patientAdmission.status === 'better'
                  || patientAdmission.status === 'died'
                  ? '#9a9b9d'
                  : '#1c1fad'

            if (loading) return null
            if (!loading)
              return (
                <Container
                  flexDirection='row'
                  key={patient.id}
                  backgroundColor={i % 2 === 0 ? '#eaf6f7' : ''}
                >
                  <FormIconButton
                    borderTopWidth='0px'
                    borderRightWidth='0px'
                    borderBottomWidth='0px'
                    borderLeftWidth='0px'
                    margins='0px'
                    paddings='0px'
                    iconSize={30}
                    color='#bd2a16'
                    leftIcon={faEdit}
                    onPress={() => {
                      history.push({
                        pathname: '/edituser',
                        state: {
                          doctor: null,
                          patient,
                          triaje: patientAdmission.triaje
                        }
                      })
                    }}
                  />
                  <TableLabel width='40px'>
                    {i + 1 + this.state.size * this.state.page}
                  </TableLabel>
                  <TableLabel width='120px' color={patientStatusColor}>
                    {format(
                      new Date(
                        patientAdmission.triaje.updatedOn.split(' ')[0].split('-')[0],
                        patientAdmission.triaje.updatedOn.split(' ')[0].split('-')[1] -
                          1,
                        patientAdmission.triaje.updatedOn.split(' ')[0].split('-')[2],
                        patientAdmission.triaje.updatedOn.split(' ')[1].split(':')[0],
                        patientAdmission.triaje.updatedOn.split(' ')[1].split(':')[1]
                      ).setHours(
                        new Date(
                          patientAdmission.triaje.updatedOn.split(' ')[0].split('-')[0],
                          patientAdmission.triaje.updatedOn.split(' ')[0].split('-')[1] -
                            1,
                          patientAdmission.triaje.updatedOn.split(' ')[0].split('-')[2],
                          patientAdmission.triaje.updatedOn.split(' ')[1].split(':')[0],
                          patientAdmission.triaje.updatedOn.split(' ')[1].split(':')[1]
                        ).getHours() - 5
                      ),
                      'dd MMMM, HH:mm',
                      {locale: es}
                    )}
                  </TableLabel>
                  {!iessCallCenterPermission && <Container width='120px' paddings='5px'>
                    <FormIconButton
                      color={patientAdmissionStatusColor}
                      alignItems='center'
                      margins='0'
                      paddings='0'
                      leftIcon={faEdit}
                      title={patientAdmissionStatus}
                      fontWeight='normal'
                      flexDirection='row'
                      borderTopWidth='0px'
                      borderRightWidth='0px'
                      borderBottomWidth='0px'
                      borderLeftWidth='0px'
                      onPress={() =>
                        this.setState({statusesVisible: true, patientAdmission})
                      }
                    />
                  </Container>}
                  <TableLabel color={patientStatusColor}>
                    {patient.person.idNumber}
                  </TableLabel>
                  <TableLabel
                    width='200px'
                    fontWeight='bold'
                    color={patientStatusColor}
                  >
                    {(patient.person.firstName
                      ? patient.person.firstName
                      : '') +
                      ' ' +
                      (patient.person.middleName
                        ? patient.person.middleName
                        : '') +
                      ' ' +
                      (patient.person.surName ? patient.person.surName : '') +
                      ' ' +
                      (patient.person.secondSurName
                        ? patient.person.secondSurName
                        : '')}
                  </TableLabel>
                  <TableLabel width='70px' color='#9f2121'>
                    {patient.person.bloodType}
                  </TableLabel>
                  <TableLabel color={patientStatusColor}>
                    {patientPhone}
                  </TableLabel>
                  <TableLabel color={patientStatusColor}>
                    {patient.person.birthDate
                      ? differenceInYears(
                          new Date(),
                          new Date(patient.person.birthDate)
                        ) + ' años'
                      : ''}
                  </TableLabel>
                  <TableLabel width='250px' color={patientStatusColor}>
                    {patient.person.addresses &&
                      patient.person.addresses[0] &&
                      patient.person.addresses[0].address1 +
                        (patient.person.addresses[0].address2
                          ? ' - ' + patient.person.addresses[0].address2
                          : '')}
                  </TableLabel>
                  <TableLabel width='250px' color={patientStatusColor}>
                    {patientAdmission.triaje && patientAdmission.triaje.briefDiagnosis}
                  </TableLabel>
                  <SecuredComponent
                    checkPermissions={{
                      objects: 'CallCenter',
                      actions: ['read']
                    }}
                  >
                    <TableLabel width='150px' color={patientStatusColor}>
                      {patient.person.diseases}
                    </TableLabel>
                  </SecuredComponent>
                  <TableLabel
                    align='center'
                    width='115px'
                    color={patientStatusColor}
                  >
                    {patient.person.disability &&
                      (patient.person.disabilityPercentage || 0) + '%'}
                  </TableLabel>
                  <TableLabel
                    align='right'
                    color={colorUr === 'yellow' ? '#d48f0e' : colorUr}
                  >
                    {ur}
                  </TableLabel>
                </Container>
              )
          }}
        />

        {patientAdmission && (
          <Modal
            isVisible={statusesVisible}
            backdropColor='white'
            onBackdropPress={() => this.setState({statusesVisible: false})}
            onBackButtonPress={() => this.setState({statusesVisible: false})}
          >
            <CardView
              cardElevation={5}
              cardMaxElevation={5}
              cornerRadius={15}
              style={{
                borderColor: theme.globalBlue
              }}
              alignSelf='center'
              backgroundColor={theme.globalBackground}
              width='350px'
              height='300px'
            >
              <FormFinal
                endpoint='savePatientAdmission'
                initialValues={{
                  ...omit(patientAdmission, ['patient', 'triaje']),
                  patient: {
                    id: patientAdmission.patient.id,
                    version: patientAdmission.patient.version
                  },
                  triaje: {
                    id: patientAdmission.triaje.id,
                    version: patientAdmission.triaje.version
                  }
                }}
                validationSchema={yupObject().shape({
                  status: yupString()
                })}
                onSuccess={() => {
                  Toast({
                    message: 'Cambio guardado correctamente',
                    duration: 4000,
                    backgroundColor: theme.globalBlue
                  })
                  this.setState({statusesVisible: false}, () => {
                    this.getUnappointedPatients()
                  })
                }}
                onError={() => {
                  Toast({
                    message: 'Hubo un error al guardar el cambio.',
                    duration: 4000,
                    backgroundColor: theme.globalError
                  })
                }}
                mutators={{
                  active: (args, state, utils) => {
                    utils.changeValue(state, 'status', () => 'active')
                  },
                  noAttentionRequired: (args, state, utils) => {
                    utils.changeValue(state, 'status', () => 'noAttentionRequired')
                  },
                  better: (args, state, utils) => {
                    utils.changeValue(state, 'status', () => 'better')
                  },
                  died: (args, state, utils) => {
                    utils.changeValue(state, 'status', () => 'died')
                  }
                }}
                wrapper={Container}
                wrapperProps={{ paddings: '20px' }}
              >
                {(form, handleSubmit) => {
                  const Button = ({title, status, color}) => {
                    if (patientAdmission && patientAdmission.status !== status) {
                      return (
                        <FormIconButton
                          flexDirection="row"
                          alignItems='center'
                          leftIcon={faHandPointRight}
                          iconSize={12}
                          color={color}
                          margins='10px'
                          paddings='5px'
                          title={title}
                          borderTopWidth='0px'
                          borderRightWidth='0px'
                          borderBottomWidth='0px'
                          borderLeftWidth='0px'
                          onPress={() => {
                            form.mutators[status]()
                            handleSubmit()
                          }}
                        />
                      )
                    }
                    return null
                  }

                  return (
                    <Fragment>
                      <Container flex={1} alignItems='flex-start' justifyContent='center'>
                        <Button
                          color={theme.globalBlue}
                          title='Por agendar'
                          status='active'
                        />
                        <Button
                          color={theme.globalError}
                          title='Paciente ya no requiere atención'
                          status='noAttentionRequired'
                        />
                        <Button
                          color={theme.globalError}
                          title='Paciente se siente mejor'
                          status='better'
                        />
                        <Button
                          color={theme.globalError}
                          title='Paciente fallecido'
                          status='died'
                        />
                      </Container>

                      <Label fontSize='12px' color={theme.globalBlue}>
                        * Es probable que no pueda revertir el cambio:
                      </Label>
                      <Label fontSize='12px' color={theme.globalBlue}>
                        Si el ingreso del paciente fue el día de hoy, se mostrará en color gris.
                        De otra forma, se mantendrá oculto para una mejor visualización de la pantalla.
                      </Label>
                    </Fragment>
                  )
                }}
              </FormFinal>
            </CardView>
          </Modal>
        )}
      </Container>
    )
  }
}

export default withTheme(withRouter(UnappointedPatients))
