/**
 * History View
 *
 * @format
 * @flow
 */

import React, { Component } from 'react'
import { TextInput, ImageBackground, Dimensions } from 'react-native'
import { withTheme } from 'styled-components'
import 'intl'
import "intl/locale-data/jsonp/en"
import "intl/locale-data/jsonp/es"
import { Container, Label } from '../../components/common'
import bodyBack from '../../assets/images/body_back.png'
import bodyFront from '../../assets/images/body_front.png'
import { AnimatedPanel } from './AnimatedPanel'
import { Field } from 'react-final-form'

class ClinicalHistoryGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      portrait: false
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.group.number === nextProps.groupNumber || (this.props.fields.value === undefined)
  }

  render() {
    const { theme, group, fields, clinicalHistory, callCenterPermission, setGroupNumber } = this.props,
      { portrait } = this.state,
      inputStyle = {
        flex: 1,
        paddingHorizontal: 5,
        borderColor: theme.globalBlue,
        borderBottomWidth: 1,
        marginTop: 5
      },
      bodyInputStyle = {
        width: 20,
        height: 21,
        fontSize: 12,
        textAlign: 'center',
        color: theme.globalError,
        fontWeight: 'bold'
      };

    let helpArray = [];
    const enabled = clinicalHistory && ((callCenterPermission && clinicalHistory.status === "onRevission") || (!callCenterPermission && (clinicalHistory.status === "onEdition" || clinicalHistory.status === "active")))
    for (let index = 0; index < 20; index++) {
      helpArray.push(index)
    }

    return (
      <AnimatedPanel title={group.number + ' - ' + group.title} description={group.description}>
        {group.number === 8
          ? (
            <Container
              flexDirection={portrait ? 'column' : 'row'}
              alignItems={portrait ? 'flex-start' : 'flex-end'}
              onLayout={() => {
                const window = Dimensions.get('window')
                if (window.width > window.height && portrait) {
                  this.setState({ portrait: false })
                } else if (window.width <= window.height && !portrait) {
                  this.setState({ portrait: true })
                }
              }}
            >
              <Container>
                {group.items.map(title => (
                  <Label
                    key={title.id}
                    color={theme.globalBlue}
                    margins="0 10px 0 0"
                  >
                    {title.number + ' - ' + title.title}
                  </Label>
                ))}
              </Container>
              <Container flexDirection='row'>
                <Container style={{ position: 'absolute', top: 0, left: 0, zIndex: 5 }}>
                  {helpArray.map(ii => (
                    <Container key={ii} flexDirection='row'>
                      {helpArray.map(jj => {
                        const found = fields.value && fields.value.find(h => h.value && h.value.includes(';;') && h.value.split(';;')[0].split(',')[0] === ii + '' && h.value.split(';;')[0].split(',')[1] === jj + '');
                        const index = found && fields.value.indexOf(found)

                        if (found) {
                          return (
                            <Field name={`values[${index}].value`} key={`values[${index}]`}>
                              {({ input }) => (
                                <TextInput
                                  style={bodyInputStyle}
                                  value={input.value.split(';;')[1]}
                                  // disabled={enabled}
                                  editable={enabled}
                                  onChangeText={text => {
                                    input.onChange(input.value.split(';;')[0] + ";;" + text)
                                  }}
                                  onFocus={() => {
                                    setGroupNumber(group.number)
                                  }}
                                />
                              )}
                            </Field>
                          );
                        }
                        return (
                          <TextInput
                            key={ii + ',' + jj}
                            style={bodyInputStyle}
                            // disabled={enabled}
                            editable={enabled}
                            onBlur={e => {
                              if (enabled) {
                                const title = group.items.find(h => h.number === Number(e.nativeEvent.text))
                                if (title) {
                                  fields.push({ item: { id: title.id, version: title.version }, value: ii + ',' + jj + ';;' + e.nativeEvent.text })
                                }
                              }
                            }}
                            onFocus={() => {
                              setGroupNumber(group.number)
                            }}
                          />
                        )
                      })}
                    </Container>
                  ))}
                </Container>
                <ImageBackground
                  source={bodyFront}
                  style={{ height: 420, width: 200 }}
                />
                <ImageBackground
                  source={bodyBack}
                  style={{ height: 420, width: 200 }}
                />
              </Container>
            </Container>
          )
          : group.items.map(title => {
            const found = fields.value && fields.value.find(h => h.item.id === title.id)
            const index = found && fields.value.indexOf(found)
            return (
              <Container key={title.id} flexDirection='row' alignItems='flex-end'>
                <Label color={theme.globalBlue} margins="0 10px 0 0">{title.number + ' - ' + title.title}</Label>
                {found ? (
                  <Field name={`values[${index}].value`} key={`values[${index}]`}>
                    {({ input }) => (
                      <TextInput
                        style={inputStyle}
                        value={input.value}
                        placeholder={title.description}
                        // disabled={enabled}
                        editable={enabled}
                        onChangeText={text => {
                          input.onChange(text)
                        }}
                        onFocus={() => {
                          setGroupNumber(group.number)
                        }}
                      />
                    )}
                  </Field>
                ) : (
                  <TextInput
                    style={inputStyle}
                    placeholder={title.description}
                    // disabled={enabled}
                    editable={enabled}
                    onBlur={e => {
                      if (enabled) {
                        fields.push({ item: { id: title.id, version: title.version }, value: e.nativeEvent.text })
                      }
                    }}
                    onFocus={() => {
                      setGroupNumber(group.number)
                    }}
                  />
                )}
              </Container>
            )
          })
        }
      </AnimatedPanel>
    )
  }
}

export default withTheme(ClinicalHistoryGroup)
