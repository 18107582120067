import React, {Component} from 'react'
import {geolocated} from 'react-geolocated'
import {Container} from '../common'

/**
 * An alternative more accurate and recommended by Google is this:
 * https://github.com/Agontuk/react-native-geolocation-service
 */
class CurrentPosition extends Component {
  constructor(props) {
    super(props)

    this.state = {
      latitude: null,
      longitude: null,
      error: null
    }
  }

  render() {
    const {
        children,
        coords,
        isGeolocationAvailable,
        isGeolocationEnabled,
        positionError,
        ...restProps
      } = this.props,
      error = !isGeolocationAvailable
        ? 'Su navegador no soporta Geolocalización'
        : !isGeolocationEnabled
        ? 'La Geolocalización no está habilitada'
        : positionError
        ? positionError
        : null,
      {
        longitude,
        latitude
        // , altitude, heading, speed
      } = coords || {}

    return (
      <Container {...restProps}>
        {children({longitude, latitude, error})}
      </Container>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 5000
})(CurrentPosition)
