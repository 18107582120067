/**
 * Home View with a Map
 *
 * @format
 * @flow
 */

import React, {Component, Fragment} from 'react'
import {
  ActivityIndicator,
  Dimensions,
  Image,
  ScrollView,
  TouchableOpacity
} from 'react-native'
import {
  faExternalLinkAlt,
  faPlus,
  faRoute,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import {faCalendarCheck, faEdit} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import Toast from '../../../components/Toast'
import {Marker} from 'google-maps-react'
import {withRouter} from '../../../utility/routing'
import FormIconButton from '../../../components/form/FormIconButton'
import CurrentPosition from '../../../components/CurrentPosition'
import SecuredComponent from '../../../components/common/SecuredComponent'
import {Container, Title, Label} from '../../../components/common'
import {MapView} from '../../../components/MapView'
import FontAwesomeLink from '../../../components/FontAwesomeLink'
import {doFetch, hasPermissions} from '../../../utility/Util'
import buildIcon from '../../../assets/images/hospital.png'
import docIcon from '../../../assets/images/doc.png'
import patientIcon from '../../../assets/images/patient.png'
import userPlaceholder from '../../../assets/images/user-icon-placeholder.png'
import {getStoredSessionInfo} from '../../../components/logic/Authentication'

const InfoWrapper = ({title, children}) => (
  <Container flexDirection='row' justifyContent='space-between' margins='5px 0'>
    <Label color='#313c68' fontSize='15px' fontWeight='bold'>
      {title}
    </Label>
    <Container width='70%'>{children}</Container>
  </Container>
)

class MapHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      consultingRooms: null,
      menuVisible: false,
      loading: false
    }
    this.importDoctors = this.importDoctors.bind(this)
  }

  componentDidMount() {
    doFetch({
      endpoint: 'getAppointedPatients',
      params: [{ page: 0 }, { size: 20 }],
      body: JSON.stringify({ today: true, searchText: '', searchDate: '' }),
      onOK: data => {
        let appointments = [],
          doctors = [],
          patients = []

        data.content.forEach(({patientAdmission, doctor, ...appointment}) => {
          let tempPatient = {},
            tempDoctor = {}
          if (patientAdmission.patient.id) {
            patients.push(patientAdmission.patient)
            tempPatient = patientAdmission.patient
          } else {
            tempPatient = patients.find(p => p.id === patientAdmission.patient)
          }
          if (doctor.id) {
            doctors.push(doctor)
            tempDoctor = doctor
          } else {
            tempDoctor = doctors.find(d => d.id === doctor)
          }
          appointments.push({
            ...appointment,
            patientAdmission: {
              ...patientAdmission,
              patient: tempPatient
            },
            doctor: tempDoctor
          })
        })
        this.setState({appointments})
      }
    })
    doFetch({
      endpoint: 'getAllDoctors',
      onOK: data => this.setState({allDoctors: data})
    })
    doFetch({
      endpoint: 'getAllPatients',
      onOK: data => this.setState({allPatients: data})
    })
    doFetch({
      endpoint: 'getRentedConsultingRooms',
      onOK: data => {
        let rentedConsultingRooms = [],
          doctors = [],
          consultingRooms = []

        data.forEach(({consultingRoom, doctor}) => {
          let tempConsultingRoom = {},
            tempDoctor = {}
          if (consultingRoom.id) {
            consultingRooms.push(consultingRoom)
            tempConsultingRoom = consultingRoom
          } else {
            tempConsultingRoom = consultingRooms.find(
              r => r.id === consultingRoom
            )
          }
          if (doctor.id) {
            doctors.push(doctor)
            tempDoctor = doctor
          } else {
            tempDoctor = doctors.find(d => d.id === doctor)
          }
          rentedConsultingRooms.push({
            consultingRoom: tempConsultingRoom,
            doctor: tempDoctor
          })
        })
        this.setState({rentedConsultingRooms})
      }
    })
    getStoredSessionInfo()
      .then(data => {
        this.setState({
          callCenterPermission: hasPermissions(data.permissions, {
            objects: 'CallCenter',
            actions: ['read']
          })
        })
        this.setState({
          rentPermission: hasPermissions(data.permissions, {
            objects: 'Rent',
            actions: ['create, update']
          })
        })
      })
      .catch(e => {})
  }

  importDoctors() {
    this.setState({loading: true})
    doFetch({
      endpoint: 'importIESSUsers',
      onOK: data => this.setState({loading: false})
    })
  }

  render() {
    const {
        appointments,
        appointmentSelected,
        rentedConsultingRooms,
        origin,
        destination,
        allDoctors,
        allPatients,
        // loading,
        doctorSelected,
        selected,
        callCenterPermission,
        rentPermission
      } = this.state,
      {history} = this.props,
      panelStyle =
        Dimensions.get('screen').width < 500
          ? {
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: 200
            }
          : {
              position: 'absolute',
              top: 0,
              right: 0,
              height: '100%',
              width: '25%',
              minWidth: 300
            }

    return (
      <Container flex={1}>
        <CurrentPosition flex={1}>
          {({longitude, latitude, error}) =>
            longitude && latitude ? (
              <Fragment>
                <MapView
                  style={{flex: 1}}
                  // containerElement={<div style={{ height: `100%` }} />}
                  // mapElement={<div style={{ height: `100%` }} />}
                  // loadingElement={<div style={{ height: `100%` }} />}
                  // // isMarkerShown
                  // googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${global.GOOGLE_MAPS_APIKEY}&v=3.exp&libraries=geometry,drawing,places`}
                  region={{
                    latitude:
                      (this.state.selProps &&
                        this.state.selProps.position.lat) ||
                      latitude, //: -2.160095,///37.78825,
                    longitude:
                      (this.state.selProps &&
                        this.state.selProps.position.lng) ||
                      longitude, //: -79.8856817,///-122.4324,
                    latitudeDelta: 0.04,
                    longitudeDelta: 0.02
                  }}
                  showsUserLocation
                  zoom={13}
                  toolbarEnabled={false}
                  onReady={(mapProps, map) => {
                    this.setState({map})
                  }}
                >
                  {appointments &&
                    rentPermission &&
                    !origin &&
                    appointments.map(appointment => (
                      <Marker
                        key={appointment.id}
                        position={{
                          lat:
                            appointment.patientAdmission.patient.person.addresses[0].location
                              .latitude,
                          lng:
                            appointment.patientAdmission.patient.person.addresses[0].location
                              .longitude
                        }}
                        identifier={appointment.id}
                        icon={{url: patientIcon}}
                        visible={
                          appointment.status === 'active' ||
                          appointment.status === 'running'
                        }
                        onClick={selProps => {
                          this.setState({
                            selProps: selProps.nativeEvent,
                            appointmentSelected: appointment
                          })
                        }}
                      />
                    ))}
                  {origin && rentPermission && (
                    <Marker icon={{url: docIcon}} position={origin} />
                  )}

                  {destination && rentPermission && (
                    <Marker icon={{url: patientIcon}} position={destination} />
                  )}

                  {allDoctors &&
                    callCenterPermission &&
                    allDoctors.map(doctor => {
                      if (
                        doctor.person.addresses &&
                        doctor.person.addresses[0] &&
                        doctor.person.addresses[0].location
                      )
                        return (
                          <Marker
                            key={doctor.id}
                            position={{
                              lat: doctor.person.addresses[0].location.latitude,
                              lng: doctor.person.addresses[0].location.longitude
                            }}
                            identifier={doctor.id}
                            icon={{url: docIcon}}
                            onClick={selProps =>
                              this.setState({
                                selProps: selProps,
                                selected: 'doctor',
                                doctorSelected: doctor
                              })
                            }
                          ></Marker>
                        )
                      return null
                    })}

                  {allPatients &&
                    callCenterPermission &&
                    allPatients.map(patient => {
                      if (
                        patient.person.addresses &&
                        patient.person.addresses[0] &&
                        patient.person.addresses[0].location
                      )
                        return (
                          <Marker
                            key={patient.id}
                            position={{
                              lat:
                                patient.person.addresses[0].location.latitude,
                              lng:
                                patient.person.addresses[0].location.longitude
                            }}
                            identifier={patient.id}
                            icon={{url: patientIcon}}
                            onClick={selProps => {
                              this.setState({
                                selProps: selProps,
                                selected: 'patient',
                                doctorSelected: patient
                              })
                            }}
                          />
                        )
                      return null
                    })}

                  <SecuredComponent
                    checkPermissions={{
                      objects: 'Appointment',
                      actions: ['create,update']
                    }}
                  >
                    {rentedConsultingRooms
                      ? rentedConsultingRooms.map(
                          ({consultingRoom: room, doctor}) => {
                            if (room.id) {
                              return (
                                <Marker
                                  key={room.id + doctor.id}
                                  coordinate={{
                                    latitude: room.location.latitude,
                                    longitude: room.location.longitude
                                  }}
                                  image={buildIcon}
                                  identifier={room.name}
                                  onPress={selProps => {
                                    this.setState({
                                      selProps: selProps.nativeEvent,
                                      roomSelected: room,
                                      doctorSelected: doctor
                                    })
                                  }}
                                />
                              )
                            }
                            return null
                          }
                        )
                      : null}
                  </SecuredComponent>
                </MapView>

                <SecuredComponent
                  checkPermissions={{
                    objects: 'Rent',
                    actions: ['create']
                  }}
                >
                  <Container
                    backgroundColor='rgba(255,255,255,0.7)'
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%'
                    }}
                  >
                    {appointments && appointments.length ? (
                      <Title margins='15px'>
                        {'Pacientes agendados: ' +
                          (appointments &&
                            appointments.filter(
                              a =>
                                a.status === 'active' || a.status === 'running'
                            ).length)}
                      </Title>
                    ) : (
                      <Title margins='15px'>
                        {'No tiene pacientes agendados'}
                      </Title>
                    )}
                  </Container>
                </SecuredComponent>

                {appointmentSelected && (
                  <Container
                    backgroundColor='rgba(255,255,255,0.7)'
                    style={panelStyle}
                  >
                    <SecuredComponent
                      checkPermissions={{
                        objects: 'Appointment',
                        actions: ['create']
                      }}
                    >
                      {this.state.roomSelected && (
                        <Container alignItems='center'>
                          <FontAwesomeLink
                            link='/schedule'
                            state={{
                              room: this.state.roomSelected,
                              doctor: doctorSelected,
                              appointment: true
                            }}
                            icon={faCalendarCheck}
                            title={'Agendar cita en ' + this.state.selProps.id}
                            color='#bd2a16'
                          />
                          {doctorSelected && (
                            <Label color='green' fontSize={14}>
                              {'Doctor: ' +
                                doctorSelected.person.firstName +
                                ' ' +
                                (doctorSelected.person.surName
                                  ? doctorSelected.person.surName
                                  : '')}
                            </Label>
                          )}
                        </Container>
                      )}
                    </SecuredComponent>

                    <SecuredComponent
                      checkPermissions={{
                        objects: 'Rent',
                        actions: ['create']
                      }}
                    >
                      {appointmentSelected && (
                        <ScrollView contentContainerStyle={{padding: 15}}>
                          <Container flex={1}>
                            <Container
                              flexDirection='row'
                              justifyContent='center'
                              margins='10px'
                            >
                              <Image
                                style={{
                                  width: 70,
                                  height: 70,
                                  borderRadius: 70
                                }}
                                source={
                                  appointmentSelected.patientAdmission.patient.person.photo
                                    ? {
                                        uri:
                                          appointmentSelected.patientAdmission.patient.person
                                            .photo.photo
                                      }
                                    : userPlaceholder
                                }
                              />
                            </Container>

                            <InfoWrapper title='Paciente:'>
                              <Label
                                color='#bd2a16'
                                fontSize='15px'
                                fontWeight='bold'
                              >
                                {appointmentSelected.patientAdmission.patient.person.firstName +
                                  ' ' +
                                  (appointmentSelected.patientAdmission.patient.person.surName
                                    ? appointmentSelected.patientAdmission.patient.person.surName
                                    : '')}
                              </Label>
                            </InfoWrapper>

                            <InfoWrapper title='Síntomas:'>
                              <Label color='#313c68' fontSize='15px'>
                                {(appointmentSelected.patientAdmission.triaje && appointmentSelected.patientAdmission.triaje.briefDiagnosis) ||
                                (appointmentSelected && appointmentSelected.briefDiagnosis)}
                              </Label>
                            </InfoWrapper>

                            {appointmentSelected.patientAdmission.patient.person.disability && (
                              <InfoWrapper title='Discapacidad:'>
                                <Label color='#313c68' fontSize='15px'>
                                  {appointmentSelected.patientAdmission.patient.person
                                    .disabilityPercentage + '%'}
                                </Label>
                              </InfoWrapper>
                            )}

                            {appointmentSelected.patientAdmission.patient.person.phones.length >
                              0 && (
                              <InfoWrapper title='Teléfonos:'>
                                {appointmentSelected.patientAdmission.patient.person.phones.map(
                                  phone => (
                                    <Label
                                      key={phone.id}
                                      color='#313c68'
                                      fontSize='15px'
                                    >
                                      {phone.phoneNumber}
                                    </Label>
                                  )
                                )}
                              </InfoWrapper>
                            )}

                            <InfoWrapper title='Dirección:'>
                              <Label color='#313c68' fontSize='15px'>
                                {appointmentSelected.patientAdmission.patient.person.addresses &&
                                  appointmentSelected.patientAdmission.patient.person
                                    .addresses[0] &&
                                  appointmentSelected.patientAdmission.patient.person
                                    .addresses[0].location &&
                                  appointmentSelected.patientAdmission.patient.person
                                    .addresses[0].location.description}
                              </Label>
                              {appointmentSelected.patientAdmission.patient.person.addresses &&
                                appointmentSelected.patientAdmission.patient.person
                                  .addresses[0] &&
                                appointmentSelected.patientAdmission.patient.person.addresses[0]
                                  .address2 && (
                                  <Label color='#313c68' fontSize='15px'>
                                    {
                                      appointmentSelected.patientAdmission.patient.person
                                        .addresses[0].address2
                                    }
                                  </Label>
                                )}
                            </InfoWrapper>
                          </Container>
                          <Container
                            flexDirection='row'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <TouchableOpacity
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 10
                              }}
                              onPress={() => {
                                const directions = new window.google.maps.DirectionsService(),
                                  renderer = new window.google.maps.DirectionsRenderer(
                                    {
                                      suppressMarkers: true,
                                      polylineOptions: {
                                        strokeColor: '#0b9a9f',
                                        strokeOpacity: 0.7,
                                        strokeWeight: 8
                                      }
                                    }
                                  ),
                                  origin = {
                                    lat: latitude,
                                    lng: longitude
                                  },
                                  destination = {
                                    lat:
                                      appointmentSelected.patientAdmission.patient.person
                                        .addresses[0].location.latitude,
                                    lng:
                                      appointmentSelected.patientAdmission.patient.person
                                        .addresses[0].location.longitude
                                  }
                                renderer.setMap(this.state.map)
                                Toast({
                                  message:
                                    'Generando la ruta hacia el Paciente: ' +
                                    appointmentSelected.patientAdmission.patient.person
                                      .firstName +
                                    ' ' +
                                    (appointmentSelected.patientAdmission.patient.person.surName
                                      ? appointmentSelected.patientAdmission.patient.person
                                          .surName
                                      : ''),
                                  duration: 4000,
                                  backgroundColor: '#313c68',
                                  opacity: 0.75
                                })
                                directions.route(
                                  {
                                    origin,
                                    destination,
                                    travelMode: 'DRIVING'
                                  },
                                  (result, status) => {
                                    if (status === 'OK') {
                                      renderer.setDirections(result)
                                      this.setState({renderer: renderer})
                                    }
                                  }
                                )

                                this.setState({origin, destination})
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faRoute}
                                size={35}
                                color='#bd2a16'
                              />
                              <Label
                                color='#bd2a16'
                                fontSize={15}
                                fontWeight='bold'
                              >
                                {origin ? 'Actualizar ruta' : 'Visitar'}
                              </Label>
                            </TouchableOpacity>

                            {destination && (
                              <TouchableOpacity
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: 10
                                }}
                                onPress={() =>
                                  window.open(
                                    `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving&dir_action=navigate`
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkAlt}
                                  size={35}
                                  color='#bd2a16'
                                />
                                <Label
                                  color='#bd2a16'
                                  fontSize={15}
                                  fontWeight='bold'
                                >
                                  Direcciones
                                </Label>
                              </TouchableOpacity>
                            )}
                          </Container>
                          {origin && appointmentSelected.status !== 'attended' && (
                            <FormIconButton
                              title={
                                appointmentSelected.status === 'active'
                                  ? '¡He llegado! Cambiar estado de la cita médica'
                                  : 'Finalizar la cita médica'
                              }
                              onPress={() => {
                                // On server: follow the flow: active -> running -> attended
                                doFetch({
                                  endpoint: 'changeAppointmentStatus',
                                  body: JSON.stringify({
                                    id: appointmentSelected.id,
                                    version: appointmentSelected.version
                                  }),
                                  onOK: data => {
                                    if (
                                      data.status === 'canceled' ||
                                      data.status === 'attended'
                                    ) {
                                      history.push('/appointments')
                                    } else {
                                      this.state.renderer.setMap(null)
                                      this.setState({appointmentSelected: data})
                                    }
                                  }
                                })
                              }}
                            />
                          )}
                        </ScrollView>
                      )}
                    </SecuredComponent>
                  </Container>
                )}

                {doctorSelected ? (
                  <SecuredComponent
                    checkPermissions={{
                      objects: 'CallCenter',
                      actions: ['read']
                    }}
                  >
                    <Container
                      backgroundColor='rgba(255,255,255,0.7)'
                      style={panelStyle}
                    >
                      <ScrollView contentContainerStyle={{padding: 15}}>
                      <Container flex={1}>
                        <FormIconButton
                          color='#0b9a9f'
                          margins='0'
                          alignSelf='flex-end'
                          paddings='0'
                          iconSize={25}
                          leftIcon={faTimesCircle}
                          borderTopWidth='0px'
                          borderRightWidth='0px'
                          borderBottomWidth='0px'
                          borderLeftWidth='0px'
                          onPress={() => this.setState({doctorSelected: null})}
                        />

                        <Container
                          flexDirection='row'
                          justifyContent='center'
                          margins='10px'
                        >
                          <Image
                            style={{width: 70, height: 70, borderRadius: 70}}
                            source={doctorSelected.person.photo
                              ? {uri: doctorSelected.person.photo.photo}
                              : userPlaceholder
                            }
                          />
                        </Container>

                        <InfoWrapper
                          title={
                            selected === 'doctor' ? 'Doctor:' : 'Paciente:'
                          }
                        >
                          <Label
                            color='#bd2a16'
                            fontSize='15px'
                            fontWeight='bold'
                          >
                            {doctorSelected.person.firstName +
                              ' ' +
                              (doctorSelected.person.surName
                                ? doctorSelected.person.surName
                                : '')}
                          </Label>
                        </InfoWrapper>

                        {selected === 'doctor' ? (
                          <InfoWrapper title='Registro Senescyt:'>
                            <Label color='#313c68' fontSize='15px'>
                              {doctorSelected.person.degrees &&
                                doctorSelected.person.degrees[0] &&
                                doctorSelected.person.degrees[0].code}
                            </Label>
                          </InfoWrapper>
                        ) : (
                          <Fragment>
                          {doctorSelected.triaje && <InfoWrapper title='Síntomas:'>
                            <Label color='#313c68' fontSize='15px'>
                              {doctorSelected.triaje.briefDiagnosis}
                            </Label>
                          </InfoWrapper>}
                          <InfoWrapper title='Enfermedades:'>
                            <Label color='#313c68' fontSize='15px'>
                              {doctorSelected.person.diseases}
                            </Label>
                          </InfoWrapper>
                          </Fragment>
                        )}

                        {doctorSelected.person.disability && (
                          <InfoWrapper title='Discapacidad:'>
                            <Label color='#313c68' fontSize='15px'>
                              {doctorSelected.person.disabilityPercentage + '%'}
                            </Label>
                          </InfoWrapper>
                        )}

                        <InfoWrapper title='Dirección:'>
                          <Label color='#313c68' fontSize='15px'>
                            {doctorSelected.person.addresses &&
                              doctorSelected.person.addresses[0] &&
                              doctorSelected.person.addresses[0].location &&
                              doctorSelected.person.addresses[0].location
                                .description}
                          </Label>
                          {doctorSelected.person.addresses &&
                            doctorSelected.person.addresses[0] &&
                            doctorSelected.person.addresses[0].address2 && (
                              <Label color='#313c68' fontSize='15px'>
                                {doctorSelected.person.addresses[0].address2}
                              </Label>
                            )}
                        </InfoWrapper>
                      </Container>
                      <TouchableOpacity
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingLeft: 10
                        }}
                        onPress={() => {
                          history.push({
                            pathname: '/edituser',
                            state: {
                              doctor:
                                selected === 'doctor' ? doctorSelected : null,
                              patient:
                                selected === 'doctor' ? null : doctorSelected
                            }
                          })
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          size={35}
                          color='#bd2a16'
                        />
                        <Label
                          color='#bd2a16'
                          fontSize='15px'
                          fontWeight='bold'
                        >
                          {'Editar'}
                        </Label>
                      </TouchableOpacity>
                      </ScrollView>
                    </Container>
                  </SecuredComponent>
                ) : (
                  <SecuredComponent
                    checkPermissions={{
                      objects: 'CallCenter',
                      actions: ['read']
                    }}
                  >
                    <FormIconButton
                      style={{position: 'absolute', bottom: 25, right: 25}}
                      color='aliceblue'
                      backgroundColor='#313c68'
                      margins='0'
                      paddings='15px'
                      leftIcon={faPlus}
                      borderTopWidth='0px'
                      borderRightWidth='0px'
                      borderBottomWidth='0px'
                      borderLeftWidth='0px'
                      onPress={() =>
                        history.push({
                          pathname: '/edituser',
                          state: {patient: {}, doctor: null}
                        })
                      }
                    />
                  </SecuredComponent>
                )}

                {/* Botón Importar Doctores, manda a correr el importador. */}
                {/*<SecuredComponent
                  checkPermissions={{
                    objects: 'CallCenter',
                    actions: ['read']
                  }}
                >
                  {!loading ? (
                    <TouchableOpacity
                      style={{
                        position: 'absolute',
                        botom: -100,
                        left: 0,
                        width: '100%',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        padding: 30
                      }}
                      onPress={this.importDoctors}
                    >
                      <Label color='#0b9a9f' fontSize={15} fontWeight='bold'>
                        {'Importar IESS Users'}
                      </Label>
                    </TouchableOpacity>
                  ) : (
                    <Container
                      style={{
                        position: 'absolute',
                        botom: -100,
                        left: 0,
                        width: '100%',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        padding: 30
                      }}
                    >
                      <ActivityIndicator />
                    </Container>
                  )}
                </SecuredComponent>*/}
              </Fragment>
            ) : (
              <Container flex={1} justifyContent='center'>
                {error
                  ? <Label alignSelf="center" color='#313c68' fontSize={14}>
                      {error}
                    </Label>
                  : <ActivityIndicator />
                }
              </Container>
            )
          }
        </CurrentPosition>
      </Container>
    )
  }
}

export default withRouter(MapHome)
