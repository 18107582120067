/**
 * History View
 *
 * @format
 * @flow
 */

import React, { Component, Fragment } from 'react'
import { ScrollView, ActivityIndicator } from 'react-native'
import { array as yupArray, object as yupObject, string as yupString } from 'yup'
import { faCheck, faSdCard, faTimes } from '@fortawesome/free-solid-svg-icons'
import 'intl'
import "intl/locale-data/jsonp/en"
import "intl/locale-data/jsonp/es"
import FormIconButton from '../../components/form/FormIconButton'
import { Container } from '../../components/common'
import { doFetch, hasPermissions } from '../../utility/Util'
import FormFinal from '../../components/form/FormFinal'
import { FieldArray } from 'react-final-form-arrays'
import { getStoredSessionInfo } from '../../components/logic/Authentication'
import ClinicalHistoryGroup from './ClinicalHistoryGroup'
import Modal from '../../components/Modal'
import { withTheme } from 'styled-components'

class ClinicalHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicalHistory: {},
      clinicalHistoryItemGroups: [],
      portrait: false,
      groupNumber: 0,
      loading: true
    }
  }

  componentDidMount() {
    const { history } = this.props,
      { appointment } = history.location.state || {},
      { id, version } = appointment || {};

    doFetch({
      endpoint: 'getClinicalHistoryByAppointment',
      body: JSON.stringify({ id, version }),
      onOK: data => this.setState({ clinicalHistory: data, loading: false })
    })

    doFetch({
      endpoint: 'getClinicalHistoryGroups',
      onOK: data => this.setState({ clinicalHistoryItemGroups: data })
    })

    getStoredSessionInfo()
      .then(data => {
        this.setState({
          callCenterPermission: hasPermissions(data.permissions, {
            objects: 'CallCenter',
            actions: ['read']
          })
        })
      })
      .catch(() => { })
  }

  render() {
    const { theme } = this.props
    const { clinicalHistory, clinicalHistoryItemGroups, callCenterPermission, groupNumber, loading } = this.state

    let helpArray = [];
    const enabled = clinicalHistory && ((callCenterPermission && clinicalHistory.status === "onRevission") || (!callCenterPermission && (clinicalHistory.status === "onEdition" || clinicalHistory.status === "active")))
    for (let index = 0; index < 20; index++) {
      helpArray.push(index)
    }

    return (
      <FormFinal
        endpoint='saveClinicalHistory'
        initialValues={clinicalHistory}
        validationSchema={yupObject().shape({
          values: yupArray().of(
            yupObject().shape({
              value: yupString().nullable()
            })
          )
        })}
        mutators={{
          setRevission: (args, state, utils) => {
            utils.changeValue(state, 'status', () => 'onRevission')
          },
          setEdition: (args, state, utils) => {
            utils.changeValue(state, 'status', () => 'onEdition')
          },
          setApproved: (args, state, utils) => {
            utils.changeValue(state, 'status', () => 'approved')
          }
        }}
        onSuccess={data => {
          this.setState({clinicalHistory: data, loading: false})
        }}
        onError={() => {
          this.setState({loading: false})
        }}
        buttons={(form, handleSubmit) => {
          if (enabled) return (
            <Container justifyContent="space-around" flexDirection='row'>
              <FormIconButton
                flexDirection="row"
                alignItems="center"
                leftIcon={faSdCard}
                title='Guardar Borrador'
                onPress={() => {
                  this.setState({loading: true})
                  handleSubmit()
                }}
              />
              {callCenterPermission
                ? <Fragment>
                  <FormIconButton
                    flexDirection="row"
                    alignItems="center"
                    leftIcon={faTimes}
                    iconColor="red"
                    title='Enviar a Edición'
                    onPress={() => {
                      this.setState({loading: true})
                      form.mutators.setEdition()
                      handleSubmit();
                    }}
                  />
                  <FormIconButton
                    alignItems="center"
                    flexDirection="row"
                    leftIcon={faCheck}
                    iconColor="green"
                    title='Aprobar'
                    onPress={() => {
                      this.setState({loading: true})
                      form.mutators.setApproved()
                      handleSubmit();
                    }}
                  />
                </Fragment>
                : <FormIconButton
                  alignItems="center"
                  flexDirection="row"
                  leftIcon={faCheck}
                  iconColor="green"
                  title='Enviar a Revisión'
                  onPress={() => {
                    this.setState({loading: true})
                    form.mutators.setRevission()
                    handleSubmit();
                  }}
                />
              }
            </Container>
          );
          return null;
        }}
      >
        {(form, handleSubmit) => (
          <Container flex={1}>
            <Modal isVisible={loading} backdropColor='white'>
              <ActivityIndicator color={theme.globalBlue} />
            </Modal>
            <FieldArray name="values"
              subscription={{
                value: true,
              }}
            >
              {({ fields, meta }) => (
                <ScrollView contentContainerStyle={{ margin: 20 }}>
                  {clinicalHistoryItemGroups.map(group => (
                    <Container key={group.id}>
                      <ClinicalHistoryGroup
                        {...{
                          group,
                          fields,
                          clinicalHistory,
                          callCenterPermission,
                          groupNumber,
                          setGroupNumber: (n) => this.setState({groupNumber: n})
                        }}
                      />
                    </Container>
                  ))}
                </ScrollView>
              )}
            </FieldArray>
          </Container>
        )}
      </FormFinal>
    )
  }
}

export default withTheme(ClinicalHistory)