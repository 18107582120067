/**
 * Consulting Room List
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {ScrollView} from 'react-native'
import {withTheme} from 'styled-components'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import FormIconButton from '../../components/form/FormIconButton'
import Room from './Room'
import {Container, Label} from '../../components/common'
import CardView from '../../components/CardView'
import {withRouter} from '../../utility/routing'
import {doFetch} from '../../utility/Util'

class ConsultingRoomList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      consultingRooms: [],
      rentedConsultingRooms: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  componentDidMount() {
    doFetch({
      endpoint: 'getAllConsultingRooms',
      onOK: data => this.setState({consultingRooms: data})
    })
    doFetch({
      endpoint: 'getRentedConsultingRooms',
      onOK: data => this.setState({rentedConsultingRooms: data})
    })
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSelectChange(itemValue, itemPosition) {
    this.setState({
      type: itemValue,
      choosenIndex: itemPosition
    })
  }

  render() {
    const {theme, history} = this.props,
      {consultingRooms, rentedConsultingRooms} = this.state

    return (
      <Container flex={1}>
        <ScrollView contentContainerStyle={{paddingBottom: 80}}>
          {consultingRooms.length > 0 ? (
            consultingRooms.map(consultingRoom => {
              const rented = rentedConsultingRooms.find(
                rcR =>
                  rcR.consultingRoom === consultingRoom.id ||
                  rcR.consultingRoom.id === consultingRoom.id
              )

              return (
                <Room
                  key={consultingRoom.id}
                  roomData={consultingRoom}
                  rented={rented}
                />
              )
            })
          ) : (
            <CardView
              cardElevation={5}
              cardMaxElevation={5}
              cornerRadius={10}
              margins='15px 15px 0'
              paddings='20px'
            >
              <Label fontSize='18'>
                Presione (+) para agregar su primer Consultorio.
              </Label>
            </CardView>
          )}
        </ScrollView>

        <FormIconButton
          style={{position: 'absolute', bottom: 25, left: 25}}
          color='aliceblue'
          backgroundColor={theme.globalGreen}
          margins='0'
          paddings='10px'
          leftIcon={faPlus}
          onPress={() => history.push('/consultingrooms/new')}
        />
      </Container>
    )
  }
}

export default withTheme(withRouter(ConsultingRoomList))
