/**
 * Consulting Room Item
 *
 * @format
 * @flow
 */

import React, {Fragment} from 'react'
import {ImageBackground} from 'react-native'
import {withTheme} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {faPhone} from '@fortawesome/free-solid-svg-icons'
import {faEdit} from '@fortawesome/free-regular-svg-icons'
import FormIconButton from '../../components/form/FormIconButton'
import {Container, Label} from '../../components/common'
import CardView from '../../components/CardView'
import SecuredComponent from '../../components/common/SecuredComponent'
import {withRouter} from '../../utility/routing'
import consImage from '../../assets/images/cons.jpg'

const Room = ({theme, history, roomData, rented}) => {
  let val
  if (roomData && roomData.buildDate) {
    val = new Intl.DateTimeFormat('es-EC', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    }).format(
      new Date(
        roomData.buildDate.split('-')[0],
        roomData.buildDate.split('-')[1],
        roomData.buildDate.split('-')[2]
      )
    )
  }

  return (
    <CardView
      cardElevation={5}
      cardMaxElevation={5}
      cornerRadius={10}
      margins='15px 15px 0'
      flex={1}
    >
      <ImageBackground
        source={consImage}
        style={{
          height: 200
        }}
      ></ImageBackground>
      <Container flex={1} justifyContent='space-between' paddings='10px'>
        <Container flex={1} paddings='0 0 10px'>
          {/* BOLITA */}
          <Container
            backgroundColor={rented ? 'red' : theme.globalGreen}
            height={10}
            width={10}
            borderRadius={5}
          />
        </Container>

        <Label fontWeight='bold' fontSize='20'>
          {roomData.name}
        </Label>
        <Container flexDirection='row' justifyContent='space-between'>
          <Container flex={1}>
            <Label fontSize='17'>
              {roomData.location &&
                roomData.location.address &&
                roomData.location.address.address1}
            </Label>
            {roomData.size && (
              <Container flexDirection='row'>
                <Label fontSize='15' fontWeight='bold'>
                  {'Tamaño: '}
                </Label>
                <Label fontSize='15'>{roomData.size + ' m'}</Label>
                <Label fontSize='12' alignSelf='flex-start'>
                  {'2'}
                </Label>
              </Container>
            )}

            {(roomData.phoneNumber || val) && (
              <Container flexDirection='row' alignItems='center' flex={1}>
                {val && (
                  <Label fontSize='15'>{'Construido el ' + val + '   '}</Label>
                )}
                {roomData.phoneNumber && (
                  <Fragment>
                    <FontAwesomeIcon
                      icon={faPhone}
                      color={theme.globalFontColor}
                      size={14}
                    />
                    <Label fontSize='15'>{' ' + roomData.phoneNumber}</Label>
                  </Fragment>
                )}
              </Container>
            )}
          </Container>
          <SecuredComponent
            checkPermissions={{
              objects: 'ConsultingRoom',
              actions: ['create,update']
            }}
          >
            <FormIconButton
              margins='0'
              paddings='0'
              color={rented ? 'grey' : theme.globalGreen}
              leftIcon={faEdit}
              iconSize={25}
              borderColor='transparent'
              onPress={() =>
                history.push({
                  pathname: `/consultingrooms/${roomData.id}`,
                  state: roomData
                })
              }
              disabled={rented ? true : false}
            />
          </SecuredComponent>
        </Container>
      </Container>
    </CardView>
  )
}

export default withTheme(withRouter(Room))
