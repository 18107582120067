/**
 * Permission Item
 *
 * @format
 * @flow
 */

import React, {Component, Fragment} from 'react'
import {withTheme} from 'styled-components'
import {string as yupString, object as yupObject} from 'yup'
import {faSortDown} from '@fortawesome/free-solid-svg-icons'
import {faSave} from '@fortawesome/free-regular-svg-icons'
import Toast from '../../components/Toast'
import Form from '../../components/form/Form'
import FormIconButton from '../../components/form/FormIconButton'
import FormIconInput from '../../components/form/FormIconInput'
import FormCheckbox from '../../components/form/FormCheckbox'
import FormIconSelect from '../../components/form/FormIconSelect'
import {Container, Error, Label} from '../../components/common'

class Item extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newPermission: {
        resourceName: '',
        action: '',
        systemUser: null,
        userType: null
      },
      userPermission: true
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleAction = this.handleAction.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.userPermission = this.userPermission.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.enableNew) {
      return {
        ...state,
        newPermission: props.permissionObject || state.newPermission
      }
    }
    return state
  }

  handleChange(name, value) {
    let {newPermission} = this.state
    newPermission[name] = value
    this.setState({newPermission})
  }

  handleAction(action, value) {
    let {newPermission} = this.state
    newPermission.action = !newPermission.action.includes(action)
      ? newPermission.action + ',' + action
      : newPermission.action.replace(action, '').replace(',,', ',')
    this.setState({newPermission})
  }

  handleSelectChange(itemValue, itemPosition) {
    let {newPermission, userPermission} = this.state
    if (userPermission) {
      const selected = this.props.users.find(item => item.id === itemValue)
      newPermission.systemUser = {id: selected.id, version: selected.version}
    } else {
      const selected = this.props.userTypes.find(item => item.id === itemValue)
      newPermission.userType = {id: selected.id, version: selected.version}
    }
    this.setState({newPermission})
  }

  userPermission(userPermission) {
    this.setState({userPermission})
  }

  render() {
    const {theme, permissionObject, users, userTypes, enableNew} = this.props,
      {newPermission, userPermission} = this.state,
      resourceName = permissionObject
        ? permissionObject.resourceName === '*'
          ? 'All'
          : permissionObject.resourceName
        : '',
      errorsHeight = theme.field.height.slice(0, -2) - 5
    let actions =
      permissionObject && permissionObject.action
        ? permissionObject.action.split(',').filter(word => word.length > 0)
        : []
    if (actions[0] === '*') {
      actions[0] = 'create'
      actions.push('read')
      actions.push('update')
      actions.push('delete')
    }
    if (enableNew) {
      actions = []
      actions.push('create')
      actions.push('read')
      actions.push('update')
      actions.push('delete')
    }

    return (
      <Form
        margins='2px 5px'
        style={{borderBottomWidth: 1, borderColor: theme.globalGreen}}
        width='100%'
        endpoint='savePermission'
        contentType='application/json'
        body={newPermission}
        validationSchema={yupObject().shape({
          resourceName: yupString().required('Ingrese el nombre del recurso'),
          action: yupString().required('Seleccione alguna acción'),
          systemUser:
            userPermission &&
            yupObject()
              .shape({
                id: yupString().required(
                  'Debe seleccionar un usuario o un tipo de usuario'
                )
              })
              .nullable()
              .required('Debe seleccionar un usuario o un tipo de usuario'),
          userType:
            !userPermission &&
            yupObject()
              .shape({
                id: yupString().required(
                  'Debe seleccionar un usuario o un tipo de usuario'
                )
              })
              .nullable()
              .required('Debe seleccionar un usuario o un tipo de usuario')
        })}
        onSuccess={data => {
          Toast({
            message: 'Guardado correctamente',
            duration: 4000,
            backgroundColor: theme.globalBlue,
            opacity: 0.75
          })
        }}
        wrapper={Container}
        wrapperProps={{flexDirection: 'row', alignItems: 'flex-start'}}
        buttons={handleSubmit =>
          enableNew ? (
            <FormIconButton
              borderTopWidth='0'
              borderRightWidth='0'
              borderBottomWidth='0'
              borderLeftWidth='0'
              margins='0'
              paddings='10px'
              leftIcon={faSave}
              alignSelf='center'
              onPress={handleSubmit}
            />
          ) : null
        }
      >
        {errors => (
          <Fragment>
            <Container
              width='55%'
              style={{borderRightWidth: 1, borderColor: theme.globalGreen}}
            >
              <Container width='100%'>
                {enableNew ? (
                  <FormIconInput
                    name='resourceName'
                    placeholder='Nombre del recurso'
                    value={newPermission.resourceName}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                ) : (
                  <Label paddings='0 5px'>{resourceName}</Label>
                )}
              </Container>

              {enableNew ? (
                <Container width='100%'>
                  <Container flexDirection='row' justifyContent='space-around'>
                    {actions.map(action => (
                      <FormCheckbox
                        key={resourceName + action + 'enableNew'}
                        rightLabel={action}
                        value={newPermission.action.includes(action)}
                        onPress={value => this.handleAction(action, value)}
                        // errors={errors}
                      />
                    ))}
                  </Container>
                  <Error errorsHeight={errorsHeight}>
                    {errors && errors['action']}
                  </Error>
                </Container>
              ) : (
                <Container
                  width='100%'
                  flexDirection='row'
                  justifyContent='space-around'
                >
                  {actions.map(action => (
                    <Label key={resourceName + action}>{action}</Label>
                  ))}
                </Container>
              )}
            </Container>

            <Container width='35%' justifyContent='flex-end'>
              {enableNew ? (
                <Fragment>
                  <FormCheckbox
                    rightLabel='¿Permiso para Usuario?'
                    value={userPermission}
                    onPress={this.userPermission}
                  />
                  {users && userTypes && (
                    <FormIconSelect
                      name={userPermission ? 'systemUser' : 'userType'}
                      value={
                        userPermission
                          ? newPermission.systemUser
                            ? newPermission.systemUser.id
                            : null
                          : newPermission.userType
                          ? newPermission.userType.id
                          : null
                      }
                      onValueChange={this.handleSelectChange}
                      options={userPermission ? users : userTypes}
                      optionsLabel={
                        userPermission
                          ? ['person.firstName', 'person.surName']
                          : 'name'
                      }
                      selectRecord
                      icon={faSortDown}
                      errors={errors}
                    />
                  )}
                </Fragment>
              ) : (
                <Label>
                  {permissionObject &&
                  permissionObject.systemUser &&
                  permissionObject.systemUser.person
                    ? permissionObject.systemUser.person.firstName +
                      ' ' +
                      permissionObject.systemUser.person.surName
                    : permissionObject && permissionObject.userType
                    ? permissionObject.userType.name
                    : ''}
                </Label>
              )}
            </Container>
          </Fragment>
        )}
      </Form>
    )
  }
}
export default withTheme(Item)
