/**
 * EditUser View
 *
 * @format
 * @flow
 */

import React, {Component, Fragment} from 'react'
import {ActivityIndicator, Platform, ScrollView} from 'react-native'
import {array as yupArray, string as yupString, object as yupObject} from 'yup'
import {setIn} from 'final-form'
import {Form, FormSpy} from 'react-final-form'
import {ThemeProvider, withTheme} from 'styled-components'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {isEmpty, omit} from 'lodash'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {
  faAllergies,
  faAmbulance,
  faBirthdayCake,
  faBookMedical,
  faClipboardCheck,
  faCrutch,
  faGraduationCap,
  faMapMarkedAlt,
  faPhone,
  faPlus,
  faUniversity,
  faUserMd,
  faUserShield,
  faVenusMars,
  faVial,
  faWheelchair
} from '@fortawesome/free-solid-svg-icons'
import {
  faEnvelope,
  faEye,
  faHospital,
  faIdCard,
  faUser
} from '@fortawesome/free-regular-svg-icons'
import {MapView} from '../../components/MapView'
import Toast from '../../components/Toast'
import FormFinal from '../../components/form/FormFinal'
import CheckboxField from '../../components/form/CheckboxField'
import CheckboxGroupField from '../../components/form/CheckboxGroupField'
import InputField from '../../components/form/InputField'
import PhotoField from '../../components/form/PhotoField'
import SelectField from '../../components/form/SelectField'
import DateField from '../../components/form/DateField'
import FormIconArrayField from '../../components/form/FormIconArrayField'
import FormIconButton from '../../components/form/FormIconButton'
import {Container, Title, Label, TableLabel} from '../../components/common'
import FontAwesomeLink from '../../components/FontAwesomeLink'
import {withRouter} from '../../utility/routing'
import {
  doFetch,
  genderOptions,
  hasPermissions,
  iDCardValidator
} from '../../utility/Util'
import userPlaceholder from '../../assets/images/user-icon-placeholder.png'
import CardView from '../../components/CardView'
import Modal from '../../components/Modal'
import SecuredComponent from '../../components/common/SecuredComponent'
import Triaje from './Triaje'
import {getStoredSessionInfo} from '../../components/logic/Authentication'

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: null,
      percentDisabled: true,
      admissions: [],
      savePatientAdmission: true
    }

    this.getTriaje = this.getTriaje.bind(this)
    this.setAlreadyAppointments = this.setAlreadyAppointments.bind(this)
  }

  componentDidMount() {
    const locationState = this.props.location.state
    this.setState({
      initialValues:
        locationState && locationState.doctor
          ? locationState.doctor
          : locationState
          ? locationState.patient
          : {},
      locationState,
      savePatientAdmission: locationState && locationState.atentionLevel ? true : false
    })
    getStoredSessionInfo()
      .then(data => {
        this.setState({
          iessCallCenterPermission: hasPermissions(data.permissions, {
            objects: 'IESSCallCenter',
            actions: ['read']
          })
        })
      })
      .catch(e => {})
  }

  getTriaje(locationState) {
    let error = {}
    if (isEmpty(omit(locationState, ['atentionLevel', 'briefDiagnosis']))) {
      error = setIn(
        error,
        'diarrea',
        'Al menos una pregunta debe ser respondida con "Sí"'
      )
    }
    if (!locationState.atentionLevel) {
      error = setIn(error, 'atentionLevel', 'Seleccione un Nivel')
    }
    if (!locationState.briefDiagnosis) {
      error = setIn(
        error,
        'briefDiagnosis',
        'Ingrese una breve descripción de las dolencias del Paciente'
      )
    }
    if (isEmpty(error)) {
      this.setState({
        locationState
      })
    }
    return error
  }

  setAlreadyAppointments(patient, admissions) {
    this.setState({initialValues: patient}, () => {
      if (admissions.length > 0) {
        this.setState({admissions})
      }
    })
  }

  render() {
    const {location, history, theme} = this.props,
      {
        initialValues,
        locationState,
        percentDisabled,
        iessCallCenterPermission,
        savePatientAdmission
      } = this.state,
      fieldMargin = '20px 0 0',
      fieldMargin2 = '10px 0 0',
      textAreaTheme = {
        field: {
          ...theme.field,
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderLeftWidth: '1px',
          borderRightWidth: '1px',
          borderRadius: '5px'
        }
      }
    let triajeState =
      locationState && locationState.atentionLevel ? locationState : null

    if (!initialValues) {
      return (
        <Container flex={1} justifyContent='center'>
          <ActivityIndicator />
        </Container>
      )
    }

    return (
      <Fragment>
        {!locationState && (
          <Form
            subscription={{
              submitting: true,
              pristine: true,
              invalid: true
            }}
            onSubmit={this.getTriaje}
          >
            {({form, handleSubmit}) => (
              <ScrollView
                contentContainerStyle={{
                  width: 450,
                  alignSelf: 'center',
                  marginLeft: 70,
                  marginRight: 70
                }}
                keyboardShouldPersistTaps='handled'
              >
                <Title>{'Nuevo Paciente'}</Title>

                <Title margins='25px 0'>
                  {'Clasificación Triaje de Manchester'}
                </Title>

                <Container
                  alignSelf='flex-end'
                  width='53px'
                  flexDirection='row'
                  justifyContent='space-around'
                >
                  <Label>Sí</Label>
                  <Label>No</Label>
                </Container>

                <CheckboxGroupField
                  leftLabel='¿En los últimos 15 días, ha tenido contacto con algún paciente COVID-19 positivo?'
                  flex={1}
                  justifyContent='space-between'
                  height={35}
                  iconSize={20}
                  name='contactoCovid'
                />

                <CheckboxGroupField
                  leftLabel='¿Tiene dolor de músculos o articulaciones?'
                  flex={1}
                  justifyContent='space-between'
                  iconSize={20}
                  name='dolorMusculos'
                  margins={fieldMargin2}
                />

                <CheckboxGroupField
                  leftLabel='¿Tiene dolor de cabeza?'
                  flex={1}
                  justifyContent='space-between'
                  iconSize={20}
                  name='dolorCabeza'
                  margins={fieldMargin2}
                />

                <CheckboxGroupField
                  leftLabel='¿Tiene dolor de garganta?'
                  flex={1}
                  justifyContent='space-between'
                  iconSize={20}
                  name='dolorGarganta'
                  margins={fieldMargin2}
                />

                <CheckboxGroupField
                  leftLabel='¿Tiene dificultad para respirar?'
                  flex={1}
                  justifyContent='space-between'
                  iconSize={20}
                  name='dificilRespirar'
                  margins={fieldMargin2}
                />

                <CheckboxGroupField
                  leftLabel='¿Tiene tos con sangre?'
                  flex={1}
                  justifyContent='space-between'
                  iconSize={20}
                  name='tosSangre'
                  margins={fieldMargin2}
                />

                <CheckboxGroupField
                  leftLabel='Tiene fiebre alta?'
                  flex={1}
                  justifyContent='space-between'
                  iconSize={20}
                  name='fiebreAlta'
                  margins={fieldMargin2}
                />

                <CheckboxGroupField
                  leftLabel='¿Tiene diarrea?'
                  flex={1}
                  justifyContent='space-between'
                  iconSize={20}
                  margins={fieldMargin2}
                  name='diarrea'
                />

                <FormSpy>
                  {({form: {getState}}) => {
                    const {
                      contactoCovid,
                      dificilRespirar,
                      dolorMusculos,
                      dolorCabeza,
                      dolorGarganta,
                      tosSangre,
                      fiebreAlta,
                      diarrea
                    } = getState().values
                    // 10   10   5   5   5   5   5   5
                    let totalTriaje = 0

                    if (contactoCovid) {
                      totalTriaje = 10
                    }
                    if (dificilRespirar) {
                      totalTriaje = totalTriaje + 10
                    }
                    if (dolorMusculos) {
                      totalTriaje = totalTriaje + 5
                    }
                    if (dolorCabeza) {
                      totalTriaje = totalTriaje + 5
                    }
                    if (dolorGarganta) {
                      totalTriaje = totalTriaje + 5
                    }
                    if (tosSangre) {
                      totalTriaje = totalTriaje + 5
                    }
                    if (fiebreAlta) {
                      totalTriaje = totalTriaje + 5
                    }
                    if (diarrea) {
                      totalTriaje = totalTriaje + 5
                    }

                    form.change(
                      'atentionLevel',
                      totalTriaje < 11
                        ? 'blue'
                        : totalTriaje > 10 && totalTriaje < 30
                        ? 'green'
                        : 'yellow'
                    )
                    return (
                      <Container
                        flexDirection='row'
                        width='100%'
                        justifyContent='space-between'
                        alignItems='stretch'
                        margins={fieldMargin}
                        paddings='5px'
                        style={{borderRadius: 5}}
                        backgroundColor={
                          totalTriaje < 11
                            ? '#b2cbf9'
                            : totalTriaje > 10 && totalTriaje < 30
                            ? '#a5f5a5'
                            : '#fdfd84'
                        }
                      >
                        <Container>
                          <Label color={theme.globalGreen}>
                            {'Nivel de Atención'}
                          </Label>

                          <Container margins='5px 0' flexDirection='row'>
                            <FontAwesomeIcon
                              icon={faAmbulance}
                              color={theme.field.icon.color}
                            />
                            <Label margins='0 5px'>
                              {totalTriaje < 11
                                ? 'AZUL'
                                : totalTriaje > 10 && totalTriaje < 30
                                ? 'VERDE'
                                : 'AMARILLO'}
                            </Label>
                          </Container>
                        </Container>
                        <Container>
                          <Label color={theme.globalGreen}>{'Categoría'}</Label>
                          <Label margins='5px 0px'>
                            {totalTriaje < 11
                              ? 'No Urgente'
                              : totalTriaje > 10 && totalTriaje < 30
                              ? 'Menos Urgente'
                              : 'URGENTE'}
                          </Label>
                        </Container>
                        <Container>
                          <Label color={theme.globalGreen}>
                            {'Tiempo de Atención'}
                          </Label>
                          <Label margins='5px 0px'>
                            {totalTriaje < 11
                              ? 'Dispensario'
                              : totalTriaje > 10 && totalTriaje < 30
                              ? '120 minutos'
                              : '60 minutos'}
                          </Label>
                        </Container>
                      </Container>
                    )
                  }}
                </FormSpy>

                <ThemeProvider theme={textAreaTheme}>
                  <InputField
                    margins={fieldMargin2}
                    paddings='5px'
                    placeholder='Me duele... (Ingrese toda la información que crea necesaria para que el Médico lo atienda de la mejor forma)'
                    name='briefDiagnosis'
                    label='Síntomas'
                    icon={faCrutch}
                    multiline
                    height={80}
                    textAlignVertical='top'
                  />
                </ThemeProvider>

                {/* THIS IS A HACK */}
                <Container width='1px' height='1px'>
                  <MapView
                    style={{flex: 1}}
                    region={{
                      latitude: -2.160095, ///37.78825,
                      longitude: -79.8856817, ///-122.4324,
                      latitudeDelta: 0.04,
                      longitudeDelta: 0.02
                    }}
                    // showsUserLocation
                    toolbarEnabled={false}
                  />
                </Container>
                {/* END OF HACK */}

                <FormIconButton
                  margins='20px'
                  title='Confirmar...'
                  onPress={handleSubmit}
                />
              </ScrollView>
            )}
          </Form>
        )}
        {locationState && (
          <FormFinal
            endpoint='saveProfile'
            contentType='application/json'
            initialValues={initialValues}
            validationErrorMessage={`Revise los datos ingresados.\nEstos son los datos mínimos para identificar al Paciente.`}
            validationSchema={yupObject().shape({
              person: yupObject().shape({
                firstName: yupString().required('Ingrese el primer nombre'),
                middleeName: yupString(),
                surName: yupString().required('Ingrese el apellido paterno'),
                // status: yupString().required('Ingrese el apellido paterno')
                //   .test('not-inactive', 'No tiene permisos para inactivar pacientes', (value) => value !== 'inactive'),
                secondSurName: yupString().required('Ingrese el apellido materno'),
                birthDate: yupString()
                  .required('Ingrese la fecha de nacimiento')
                  .nullable()
                  .matches(
                    /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                    'Formato inválido, ejemplo correcto: 1970-06-25'
                  ),
                idNumber: yupString()
                  // Validate idCard & RUC inserted. https://medium.com/@bryansuarez/c%C3%B3mo-validar-c%C3%A9dula-y-ruc-en-ecuador-b62c5666186f
                  // Library to do this can be: https://github.com/diaspar/validacion-cedula-ruc-ecuador
                  // some code: https://www.jybaro.com/blog/cedula-de-identidad-ecuatoriana/
                  // .test(
                  //   'idCardValidator',
                  //   params => `${params.value} no es una cédula válida`,
                  //   value => iDCardValidator(value)
                  // )
                  // .length(
                  //   10,
                  //   params =>
                  //     `Cédula debe tener exáctamente ${params.length} caracteres`
                  // )
                  .required('Ingrese la Cédula de Identidad o Pasaporte'),
                emails: yupArray()
                  .of(
                    yupObject().shape({
                      address: yupString()
                        .email('Correo Electrónico debe ser válido')
                        .test(
                          'emailReq',
                          'Correo Electrónico es un campo requerido',
                          email => {
                            if ((locationState && !location.state) || email) {
                              return true
                            }
                            return false
                          }
                        )
                    })
                  )
                  .test(
                    'emailRequired',
                    'Ingrese al menos un Correo Electrónico',
                    email => {
                      if ((locationState && !location.state) || email) {
                        return true
                      }
                      return false
                    }
                  ),
                phones: yupArray()
                  .of(
                    yupObject().shape({
                      phoneNumber: yupString().test(
                        'phoneReq',
                        'Teléfono es un campo requerido. Formato: 0912345678 ó 042123456',
                        ph => {
                          if (iessCallCenterPermission && !ph) {
                            return false
                          } else if (
                            iessCallCenterPermission &&
                            ph.length > 10
                          ) {
                            return false
                          } else if (
                            iessCallCenterPermission &&
                            ph.length < 9
                          ) {
                            return false
                          } else if (!ph || ph.length === 0) {
                            return false
                          }
                          return true
                        }
                      )
                    })
                  )
                  .test(
                    'phoneRequired',
                    'Ingrese al menos un Número Telefónico',
                    ph => {
                      if (iessCallCenterPermission && !ph) {
                        return false
                      }
                      return true
                    }
                  )
              })
            })}
            onSuccess={data => {
              if (savePatientAdmission) {
                doFetch({
                  endpoint: 'savePatientAdmission',
                  body: JSON.stringify({patient: {id: data.id, version: data.version}, triaje: triajeState}),
                  onOK: patiendAdmissionSaved => {
                    Toast({
                      message: 'Guardado correctamente',
                      duration: 4000,
                      backgroundColor: theme.globalBlue,
                      opacity: 0.75
                    })
                    if (iessCallCenterPermission) {
                      history.push({
                        pathname: '/map',
                        state: {
                          userId: data.id,
                          userVersion: data.version,
                          userPersonId:
                            data.person &&
                            data.person.addresses &&
                            data.person.addresses[0] &&
                            data.person.addresses[0].id,
                          userPersonVersion:
                            data.person &&
                            data.person.addresses &&
                            data.person.addresses[0] &&
                            data.person.addresses[0].version,
                          allowSchedule: iessCallCenterPermission ? false : true,
                          triaje: patiendAdmissionSaved.triaje,
                          hideBriefDiagnosis: true
                        }
                      })
                    }
                  }
                })
              } else {
                Toast({
                  message: 'Guardado correctamente',
                  duration: 4000,
                  backgroundColor: theme.globalBlue,
                  opacity: 0.75
                })
                if (iessCallCenterPermission) {
                  history.push({
                    pathname: '/map',
                    state: {
                      userId: data.id,
                      userVersion: data.version,
                      userPersonId:
                        data.person &&
                        data.person.addresses &&
                        data.person.addresses[0] &&
                        data.person.addresses[0].id,
                      userPersonVersion:
                        data.person &&
                        data.person.addresses &&
                        data.person.addresses[0] &&
                        data.person.addresses[0].version,
                      allowSchedule: iessCallCenterPermission ? false : true,
                      triaje: locationState,
                      hideBriefDiagnosis: true
                    }
                  })
                } else {
                  this.setState({initialValues: data})
                }
              }
            }}
            submitButtonTitle='Guardar'
            wrapper={ScrollView}
            wrapperProps={{
              contentContainerStyle: {
                maxWidth: 500,
                width: '100%',
                alignSelf: 'center',
                marginLeft: 70,
                marginRight: 70
              },
              keyboardShouldPersistTaps: 'handled'
              // showsVerticalScrollIndicator: false
            }}
          >
            {(form, handleSubmit) => (
              <Fragment>
                <SecuredComponent
                  checkPermissions={{
                    objects: 'IESSCallCenter',
                    actions: ['read']
                  }}
                >
                  <Triaje
                    initialValues={initialValues}
                    form={form}
                    handleSubmit={handleSubmit}
                    setAlreadyAppointments={this.setAlreadyAppointments}
                    savePatientAdmission={savePatientAdmission}
                    setSavePatientAdmission={val => this.setState({savePatientAdmission: val})}
                  />
                </SecuredComponent>

                <SecuredComponent
                  checkPermissions={{
                    objects: 'CallCenter',
                    actions: ['read']
                  }}
                >
                  <Fragment>
                    {location.state && location.state.doctor ? (
                      <Title margins='25px 0'>
                        {initialValues.id ? 'Editar Médico' : 'Crear Médico'}
                      </Title>
                    ) : (
                      <Title margins='25px 0'>
                        {initialValues.id
                          ? 'Editar Paciente'
                          : 'Crear Paciente'}
                      </Title>
                    )}

                    <Container
                      flexDirection='row'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <PhotoField
                        name='person.photo.photo'
                        placeholder={userPlaceholder}
                        height={125}
                        width={125}
                      />
                    </Container>

                    <Container
                      flexDirection='row'
                      alignItems='stretch'
                      margins={fieldMargin}
                    >
                      <InputField
                        width='50%'
                        placeholder='Cédula de Identidad'
                        name='person.idNumber'
                        maxLength={10}
                        icon={faIdCard}
                        // TODO check that this is valuable.
                        // onBlur={({nativeEvent: {text}}) => {
                        //   if (text.length === 10) {
                        //     doFetch({
                        //       endpoint: 'getPatientsByIdNumber2',
                        //       body: text,
                        //       onOK: data => {
                        //         let patient =
                        //           data && data.length > 0
                        //             ? data[0].patient
                        //               : null
            
                        //         if (patient) {
                        //           Toast({
                        //             message: 'La cédula ya registrada en el sistema. Se han cargado los datos existentes.',
                        //             duration: 4000,
                        //             backgroundColor: theme.globalBlue,
                        //             opacity: 0.75
                        //           })
                        //           this.setAlreadyAppointments(patient, data)
                        //         } else {
                        //           this.setAlreadyAppointments({}, [])
                        //         }
                        //       }
                        //     })
                        //   }
                        // }}
                      />
                      <SelectField
                        width='50%'
                        placeholder={
                          location.state && location.state.doctor
                            ? 'Estado del Médico'
                            : 'Estado del Paciente'
                        }
                        options={
                          location.state && location.state.doctor
                            ? [
                                {value: 'active', label: 'Activo'},
                                {value: 'inactive', label: 'Inactivo'}
                              ]
                            : [
                                {value: 'active', label: 'Activo'},
                                // {value: 'noAttentionRequired', label: 'Ya no requiere atención'},
                                // {value: 'better', label: 'Mejorado'},
                                {value: 'died', label: 'Fallecido'},
                                {value: 'wrongData', label: 'Datos Erróneos'},
                                // {value: 'inactive', label: 'Inactivo'}
                              ]
                        }
                        name='person.status'
                        icon={faEye}
                      />
                    </Container>

                    <Container
                      flexDirection='row'
                      justifyContent='center'
                      alignItems='stretch'
                      margins={fieldMargin}
                    >
                      <InputField
                        width='50%'
                        placeholder='Primer Nombre'
                        name='person.firstName'
                        maxLength={512}
                        returnKeyType='next'
                        icon={faUser}
                      />
                      <InputField
                        width='50%'
                        placeholder='Segundo Nombre'
                        maxLength={512}
                        name='person.middleName'
                      />
                    </Container>
                    <Container
                      flexDirection='row'
                      justifyContent='center'
                      alignItems='stretch'
                      margins={fieldMargin}
                    >
                      <InputField
                        width='50%'
                        placeholder='Primer Apellido'
                        name='person.surName'
                        maxLength={512}
                        icon={faUser}
                      />
                      <InputField
                        width='50%'
                        placeholder='Segundo Apellido'
                        name='person.secondSurName'
                        maxLength={512}
                      />
                    </Container>

                    <Container
                      flexDirection='row'
                      justifyContent='space-between'
                      alignItems='stretch'
                      margins={fieldMargin}
                    >
                      <SelectField
                        width='30%'
                        options={genderOptions}
                        placeholder='Sexo'
                        name='person.gender'
                        maxLength={256}
                        icon={faVenusMars}
                      />
                      <DateField
                        width='30%'
                        placeholder='AAAA-MM-DD'
                        name='person.birthDate'
                        icon={faBirthdayCake}
                      />
                      <SelectField
                        width='30%'
                        placeholder='Tipo/Sangre'
                        options={[
                          {value: 'A+', label: 'A+'},
                          {value: 'A-', label: 'A-'},
                          {value: 'B+', label: 'B+'},
                          {value: 'B-', label: 'B-'},
                          {value: 'AB+', label: 'AB+'},
                          {value: 'AB-', label: 'AB-'},
                          {value: 'O+', label: 'O+'},
                          {value: 'O-', label: 'O-'}
                        ]}
                        name='person.bloodType'
                        icon={faVial}
                      />
                    </Container>

                    <FormIconArrayField
                      name='person.emails'
                      label='Correos Electrónicos'
                      margins={fieldMargin}
                      wrapperStyle={{minHeight: 60}}
                    >
                      {({fields, form}) => (
                        <Container flex={1} flexDirection='row'>
                          <FormIconButton
                            leftIcon={faPlus}
                            margins='0'
                            paddings='5px'
                            alignSelf='flex-start'
                            onPress={() => fields.push({})}
                          />
                          <Container margins='0 0 0 10px' flex={1}>
                            {fields.map((emailItem, index) => (
                              <InputField
                                key={'emailItem' + index}
                                margins={index === 0 ? '0' : fieldMargin}
                                placeholder='Correo Electrónico'
                                name={`${emailItem}.address`}
                                maxLength={256}
                                autoCapitalize='none'
                                keyboardType='email-address'
                                icon={faEnvelope}
                              />
                            ))}
                          </Container>
                        </Container>
                      )}
                    </FormIconArrayField>

                    <FormIconArrayField
                      name='person.phones'
                      label='Teléfonos'
                      margins={fieldMargin}
                      wrapperStyle={{minHeight: 60}}
                    >
                      {({fields, form}) => (
                        <Container flex={1} flexDirection='row'>
                          <FormIconButton
                            leftIcon={faPlus}
                            margins='0'
                            paddings='5px'
                            alignSelf='flex-start'
                            onPress={() => fields.push({})}
                          />
                          <Container margins='0 0 0 10px' flex={1}>
                            {fields.map((phoneItem, index) => (
                              <InputField
                                key={'phoneItem' + index}
                                margins={index === 0 ? '0' : fieldMargin}
                                placeholder='09 1234 5678'
                                name={`${phoneItem}.phoneNumber`}
                                maxLength={32}
                                keyboardType='numeric'
                                icon={faPhone}
                              />
                            ))}
                          </Container>
                        </Container>
                      )}
                    </FormIconArrayField>

                    {location.state && location.state.doctor ? (
                      <Fragment>
                        <FormIconArrayField
                          name='person.degrees'
                          label='Títulos'
                          wrapperStyle={{minHeight: 70}}
                          margins={fieldMargin}
                        >
                          {({fields, form}) => (
                            <Container flex={1} flexDirection='row'>
                              <FormIconButton
                                leftIcon={faPlus}
                                margins='0'
                                paddings='5px'
                                alignSelf='flex-start'
                                onPress={() => fields.push({})}
                              />
                              <Container margins='0 0 0 10px' flex={1}>
                                {fields.map((degreeItem, index) => (
                                  <Fragment key={'degreeItem' + index}>
                                    <InputField
                                      margins={index === 0 ? '0' : fieldMargin}
                                      placeholder='Universidad o Institución Educativa'
                                      name={`${degreeItem}.college`}
                                      maxLength={256}
                                      icon={faUniversity}
                                    />
                                    <InputField
                                      margins={fieldMargin}
                                      placeholder='Médico / Licenciado / Seminario'
                                      name={`${degreeItem}.levelType`}
                                      maxLength={256}
                                      icon={faGraduationCap}
                                    />
                                    <InputField
                                      margins={fieldMargin}
                                      placeholder='Medicina General / Ciencias de la Salud'
                                      name={`${degreeItem}.degree`}
                                      maxLength={256}
                                      icon={faGraduationCap}
                                    />
                                    <InputField
                                      margins={fieldMargin}
                                      placeholder='Registro Senescyt'
                                      name={`${degreeItem}.code`}
                                      maxLength={256}
                                      icon={faClipboardCheck}
                                    />
                                  </Fragment>
                                ))}
                              </Container>
                            </Container>
                          )}
                        </FormIconArrayField>

                        <FormIconArrayField
                          name='person.workExperiences'
                          label='Experiencias Laborales'
                          wrapperStyle={{minHeight: 70}}
                          margins={fieldMargin}
                        >
                          {({fields, form}) => (
                            <Container flex={1} flexDirection='row'>
                              <FormIconButton
                                leftIcon={faPlus}
                                margins='0'
                                paddings='5px'
                                alignSelf='flex-start'
                                onPress={() => fields.push({})}
                              />
                              <Container margins='0 0 0 10px' flex={1}>
                                {fields.map((workItem, index) => (
                                  <Fragment key={'workItem' + index}>
                                    <InputField
                                      margins={index === 0 ? '0' : fieldMargin}
                                      placeholder='Compañía o Institución Médica'
                                      name={`${workItem}.companyName`}
                                      maxLength={256}
                                      icon={faHospital}
                                    />
                                    <InputField
                                      margins={fieldMargin}
                                      placeholder='Jefe de pediatría'
                                      name={`${workItem}.position`}
                                      maxLength={256}
                                      icon={faUserMd}
                                    />

                                    <ThemeProvider theme={textAreaTheme}>
                                      <InputField
                                        margins={fieldMargin}
                                        placeholder='Como pediatra revisé ...'
                                        name={`${workItem}.responsabilities`}
                                        maxLength={256}
                                        icon={faBookMedical}
                                        multiline
                                        height={70}
                                        textAlignVertical='top'
                                      />
                                    </ThemeProvider>
                                  </Fragment>
                                ))}
                              </Container>
                            </Container>
                          )}
                        </FormIconArrayField>

                        <FormSpy>
                          {({form: {getState}}) => {
                            if (getState().values.id) {
                              return (
                                <FontAwesomeLink
                                  link='/map'
                                  icon={faMapMarkedAlt}
                                  title='Ubicación Geográfica'
                                  color={theme.globalGreen}
                                  state={{
                                    userId: getState().values.id,
                                    userVersion: getState().values.version,
                                    userPersonId:
                                      getState().values.person &&
                                      getState().values.person.addresses &&
                                      getState().values.person.addresses[0] &&
                                      getState().values.person.addresses[0].id,
                                    userPersonVersion:
                                      getState().values.person &&
                                      getState().values.person.addresses &&
                                      getState().values.person.addresses[0] &&
                                      getState().values.person.addresses[0]
                                        .version,
                                    allowSchedule: false
                                  }}
                                />
                              )
                            }
                            return (
                              <Label
                                align='center'
                                color={theme.globalBlue}
                                fontSize={15}
                                fontWeight='bold'
                              >
                                Guarde los datos iniciales para obtener la
                                ubicación geográfica
                              </Label>
                            )
                          }}
                        </FormSpy>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <InputField
                          margins={fieldMargin}
                          label='Alergias'
                          placeholder='Alergias'
                          name='person.allergies'
                          maxLength={512}
                          icon={faAllergies}
                        />
                        <Container
                          flexDirection='row'
                          justifyContent='flex-start'
                          alignItems='flex-end'
                          margins={fieldMargin}
                        >
                          <CheckboxField
                            label='Discapacidad'
                            leftLabel=' '
                            width='40%'
                            name='person.disability'
                            icon={faWheelchair}
                            onChange={val =>
                              this.setState({percentDisabled: !val})
                            }
                          />

                          <InputField
                            width='50%'
                            label='Porcentaje de Discapacidad'
                            placeholder='###'
                            keyboardType='numeric'
                            maxLength={3}
                            name='person.disabilityPercentage'
                            disabled={percentDisabled}
                          />
                          <Label>%</Label>
                        </Container>

                        <CheckboxField
                          margins={fieldMargin}
                          label='Seguro Médico Privado'
                          leftLabel=' '
                          width='100%'
                          name='privateUser'
                          icon={faUserShield}
                        />

                        <ThemeProvider theme={textAreaTheme}>
                          <Fragment>
                            <InputField
                              margins={fieldMargin}
                              label='Enfermedades'
                              placeholder='Asma...'
                              name='person.diseases'
                              maxLength={512}
                              multiline
                              height={70}
                              textAlignVertical='top'
                            />
                            <InputField
                              margins={fieldMargin}
                              label='Operaciones o Intervenciones Quirúrgicas'
                              name='person.surgicalOperations'
                              maxLength={512}
                              multiline
                              height={70}
                              textAlignVertical='top'
                            />
                            <InputField
                              margins='20px 0 20px'
                              label='Tratamientos'
                              name='person.treatment'
                              maxLength={512}
                              multiline
                              height={70}
                              textAlignVertical='top'
                            />
                          </Fragment>
                        </ThemeProvider>

                        <FormSpy>
                          {({form: {getState}}) => {
                            if (getState().values.id) {
                              return (
                                <FontAwesomeLink
                                  link='/map'
                                  icon={faMapMarkedAlt}
                                  title='Ubicación Geográfica'
                                  color={theme.globalGreen}
                                  state={{
                                    userId: getState().values.id,
                                    userVersion: getState().values.version,
                                    userPersonId:
                                      getState().values.person &&
                                      getState().values.person.addresses &&
                                      getState().values.person.addresses[0] &&
                                      getState().values.person.addresses[0].id,
                                    userPersonVersion:
                                      getState().values.person &&
                                      getState().values.person.addresses &&
                                      getState().values.person.addresses[0] &&
                                      getState().values.person.addresses[0]
                                        .version,
                                    allowSchedule: true,
                                    triaje: locationState && locationState.atentionLevel ? locationState : locationState && locationState.triaje,
                                    doctor: locationState && locationState.doctor,
                                    patient: locationState && locationState.patient,
                                  }}
                                />
                              )
                            }
                            return (
                              <Label
                                align='center'
                                color='#313c68'
                                fontSize={15}
                                fontWeight='bold'
                              >
                                Guarde los datos iniciales para obtener la
                                ubicación geográfica
                              </Label>
                            )
                          }}
                        </FormSpy>
                      </Fragment>
                    )}
                  </Fragment>
                </SecuredComponent>

                <Modal
                  isVisible={this.state.admissions.length > 0}
                  backdropColor='white'
                  onBackdropPress={() =>
                    this.setState({admissions: [], savePatientAdmission: false})
                  }
                  onBackButtonPress={() =>
                    this.setState({admissions: [], savePatientAdmission: false})
                  }
                >
                  <CardView
                    cardElevation={5}
                    cardMaxElevation={5}
                    cornerRadius={15}
                    style={{borderColor: theme.globalBlue}}
                    alignSelf='center'
                    backgroundColor={theme.globalBackground}
                    width={Platform.OS === 'web' ? '700px' : '300px'}
                    height='500px'
                    paddings='30px'
                  >
                    <FormSpy>
                      {({form: {getState}}) => {
                        if (getState().values.person) {
                          const person = getState().values.person,
                            status = person.status === 'wrongData'
                              ? 'Datos Erróneos'
                              : person.status === 'noAttentionRequired'
                              ? ''
                              : person.status === 'better'
                              ? ''
                              : person.status === 'died'
                              ? 'Fallecido'
                              : ''

                          return (
                            <Container paddings="0 5px">
                              <Label
                                fontSize='17px'
                                fontWeight='bold'
                                color={theme.globalGreen}
                              >
                                {`Paciente: ${person.firstName} ${person.middleName || ''} ${person.surName} ${person.secondSurName}`}
                              </Label>
                              {person.status !== 'active' && <Label color={theme.globalBlue}>{status}</Label>}
                            </Container>
                          )
                        }
                        return null
                      }}
                    </FormSpy>

                    <Container
                      flexDirection='row'
                      justifyContent='flex-start'
                      paddings='10px 0px'
                    >
                      <TableLabel color={theme.globalGreen} width='100%'>Síntomas</TableLabel>
                      <TableLabel color={theme.globalGreen} width="230px">Fecha de Ingreso</TableLabel>
                      <TableLabel color={theme.globalGreen} width="230px">Fecha de Agendamiento</TableLabel>
                    </Container>
                    <ScrollView>
                      {this.state.admissions.map(app => (
                          <Container
                            flexDirection='row'
                            justifyContent='flex-start'
                            paddings='5px 0px'
                            key={app.id}
                          >
                            <TableLabel width="100%">{(app.triaje && app.triaje.briefDiagnosis) || app.appointment.briefDiagnosis}</TableLabel>
                            <TableLabel width="230px">
                              {format(new Date(
                                app.createdOn.split(' ')[0].split('-')[0],
                                app.createdOn.split(' ')[0].split('-')[1] - 1,
                                app.createdOn.split(' ')[0].split('-')[2],
                                app.createdOn.split(' ')[1].split(':')[0],
                                app.createdOn.split(' ')[1].split(':')[1]
                              ), 'd MMM yyyy, H:mm', { locale: es })}
                            </TableLabel>
                            <TableLabel width="230px">
                              {app.appointment ? format(new Date(
                                app.appointment.scheduledOn.split(' ')[0].split('-')[0],
                                app.appointment.scheduledOn.split(' ')[0].split('-')[1] - 1,
                                app.appointment.scheduledOn.split(' ')[0].split('-')[2],
                                app.appointment.scheduledOn.split(' ')[1].split(':')[0],
                                app.appointment.scheduledOn.split(' ')[1].split(':')[1]
                              ), 'd MMM yyyy, H:mm', { locale: es }) : "--"}
                            </TableLabel>
                          </Container>
                        ))
                      }
                    </ScrollView>

                    {iessCallCenterPermission && (
                      <Container flexDirection="row" justifyContent='space-between'>
                        <FormIconButton
                          margins='0'
                          title='Cancelar'
                          onPress={() => {
                            this.setState({admissions: [], savePatientAdmission: false})
                          }}
                        />

                        <FormIconButton
                          margins='0'
                          title='Ingresar para nuevo agendamiento'
                          onPress={() => {
                            if (form.getState().values.person.status !== 'died') {
                              form.change('person.status', 'active');
                            }
                            this.setState({admissions: [], savePatientAdmission: true})
                          }}
                          disabled={form.getState().values && form.getState().values.person && form.getState().values.person.status === 'died'}
                        />
                      </Container>
                    )}
                  </CardView>
                </Modal>
              </Fragment>
            )}
          </FormFinal>
        )}
      </Fragment>
    )
  }
}

export default withRouter(withTheme(EditUser))
