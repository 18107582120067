/**
 * Appointment Report
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {ActivityIndicator, ScrollView} from 'react-native'
import {withTheme} from 'styled-components'
import {Container, Label, TableLabel, Title} from '../../components/common'
import {doFetch} from '../../utility/Util'

class AppointmentReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appointments: [],
      loading: true
    }
  }

  componentDidMount() {
    doFetch({
      endpoint: 'reportAppointments',
      onOK: appointments => {
        this.setState({appointments, loading: false})
      },
      onNotOK: () => this.setState({loading: false})
    })
  }

  render() {
    const {appointments, loading} = this.state,
      {theme} = this.props
    let temp = ['fecha']

    return (
      <Container alignItems='center' width='100%' flex={1}>
        <Title margins='10px'>Pacientes Ingresados IESS</Title>

        {loading && <ActivityIndicator />}
        {!loading && appointments.length === 0 && (
          <Container paddings='15px' alignItems='center'>
            <Label fontWeight='bold' color={theme.globalBlue}>
              No hay datos para mostrar
            </Label>
          </Container>
        )}

        {!loading && appointments.length > 0 && (
          <ScrollView
            horizontal
            style={{width: '100%'}}
            contentContainerStyle={{
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: 1000,
              width: '100%'
            }}
          >
            <Container flexDirection='row' justifyContent='flex-start'>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Fecha
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Mayores 65 años
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Menores 5 años
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Discapacitados
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Agendados
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Atendidos
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Cancelados
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Atendiendo
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Total
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Efectividad
              </TableLabel>
            </Container>
            <ScrollView
              contentContainerStyle={{
                width: '100%',
                alignItems: 'center'
              }}
              keyboardShouldPersistTaps='handled'
            >
              {appointments.map(appointment => {
                Object.entries(appointment).map((val, ii) => {
                  if (val[0] !== 'scheduled') {
                    if (temp.length === ii) {
                      temp.push(val[1])
                    } else {
                      temp[ii] = temp[ii] + val[1]
                    }
                  }
                  return null
                })
                return (
                <Container key={appointment.scheduled} flexDirection='row'>
                  <TableLabel align='center'>
                    {appointment.scheduled}
                  </TableLabel>
                  <TableLabel
                    align='center'
                    backgroundColor='rgba(255,255,0,0.1)'
                  >
                    {appointment.major65Count}
                  </TableLabel>
                  <TableLabel
                    align='center'
                    backgroundColor='rgba(0,0,255,0.1)'
                  >
                    {appointment.minor5Count}
                  </TableLabel>
                  <TableLabel
                    align='center'
                    backgroundColor='rgba(255,0,0,0.1)'
                  >
                    {appointment.disabilityCount}
                  </TableLabel>
                  <TableLabel
                    align='center'
                    backgroundColor='rgba(0,100,0,0.1)'
                  >
                    {appointment.activeCount}
                  </TableLabel>
                  <TableLabel
                    align='center'
                    backgroundColor='rgba(0,100,0,0.1)'
                  >
                    {appointment.attendedCount}
                  </TableLabel>
                  <TableLabel
                    align='center'
                    backgroundColor='rgba(0,100,0,0.1)'
                  >
                    {appointment.canceledCount}
                  </TableLabel>
                  <TableLabel
                    align='center'
                    backgroundColor='rgba(0,100,0,0.1)'
                  >
                    {appointment.runningCount}
                  </TableLabel>
                  <TableLabel
                    align='center'
                    backgroundColor='rgba(0,100,0,0.1)'
                  >
                    {appointment.total}
                  </TableLabel>
                  <TableLabel align='center'>
                    {appointment.effectiveness + '%'}
                  </TableLabel>
                </Container>
              )})}

              <Container flexDirection='row' backgroundColor='#e0f0f0'>
                <TableLabel align='center'>Total</TableLabel>
                {temp.map((val, ii) => {
                  if (ii > 0 && ii !== 9) {
                    return (
                      <TableLabel align='center' key={ii} width={ii === 20 ? "80px" : "100px"}>
                        {val}
                      </TableLabel>
                    )
                  }
                  if (ii === 9) {
                    return (
                      <TableLabel align='center' key={ii} width={ii === 20 ? "80px" : "100px"}>
                        {"-"}
                      </TableLabel>
                    )
                  }
                  return null
                })}
              </Container>
            </ScrollView>
          </ScrollView>
        )}
      </Container>
    )
  }
}

export default withTheme(AppointmentReport)
