/**
 * Profile Location Component
 *
 * @format
 * @flow
 */

import React, {Component, Fragment} from 'react'
import {ScrollView} from 'react-native'
import {Field, FormSpy} from 'react-final-form'
import {
  number as yupNumber,
  string as yupString,
  object as yupObject
} from 'yup'
import {
  faCity,
  faFlag as faSolidFlag,
  faMailBulk,
  faRoad,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import {faFlag} from '@fortawesome/free-regular-svg-icons'
import Toast from '../../components/Toast'
import FormFinal from '../../components/form/FormFinal'
import InputField from '../../components/form/InputField'
import IconAddressLocation from '../../components/form/IconAddressLocation'
import {Container, Title} from '../../components/common'
import {withRouter} from '../../utility/routing'
import {doFetch} from '../../utility/Util'
import docIcon from '../../assets/images/doc.png'
import patientMovIcon from '../../assets/images/patient_mov.png'

class ProfileLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buildingDetails: false,
      locationDetails: false
    }
    this.setInitial = this.setInitial.bind(this)
  }

  componentDidMount() {
    const {location} = this.props
    doFetch({
      endpoint: 'profileLocation',
      body: JSON.stringify({
        id: location.state.userId,
        version: location.state.userVersion
      }),
      onOK: data => this.setInitial(data)
    })
  }

  setInitial(initialValues) {
    this.setState({initialValues})
  }

  render() {
    const {history, location} = this.props,
      {initialValues} = this.state,
      {address} = initialValues || {},
      {person} = address || {},
      {allowSchedule} = location.state || {},
      fieldMargin = '15px 0 0',
      myLocation = {
        location: {
          ...initialValues,
          address: {
            ...address,
            person: {
              ...person,
              id: location.state.userPersonId,
              version: location.state.userPersonVersion
            }
          }
        },
        user: {
          id: location.state.userId,
          version: location.state.userVersion
        }
      }

    return (
      <FormFinal
        endpoint='saveUserLocation'
        contentType='application/json'
        initialValues={
          initialValues
            ? myLocation
            : {
                user: {
                  id: location.state.userId,
                  version: location.state.userVersion
                }
              }
        }
        evalVersion={myLocation.location.version}
        validationSchema={yupObject().shape({
          location: yupObject()
            .shape({
              latitude: yupNumber().required(
                'Se requiere ingresar una dirección con datos de ubicación: Latitud y Longitud'
              ),
              longitude: yupNumber().required(
                'Se requiere ingresar una dirección con datos de ubicación: Latitud y Longitud'
              ),
              placeId: yupString().required(
                'Se requiere ingresar una dirección con datos de ubicación: Latitud y Longitud'
              ),
              description: yupString().required(
                'Se requiere datos de ubicación: Latitud y Longitud' +
                  '\nMueva el marcador o ingrese una dirección válida aquí arriba.'
              ),
              address: yupObject().shape({
                address1: yupString().required('Ingrese una dirección válida')
              })
            })
            .required('Ingrese una dirección válida')
        })}
        onSuccess={data => {
          if (allowSchedule) {
            history.push({
              pathname: 'patientappointment',
              state: {
                patientId: location.state.userId,
                patientVersion: location.state.userVersion,
                triaje: location.state.triaje,
                hideBriefDiagnosis: location.state.hideBriefDiagnosis
              }
            })
          } else {
            Toast({
              message: 'Paciente guardado correctamente en Doctus EC',
              duration: 4000,
              backgroundColor: '#313c68',
              opacity: 0.75
            })
            history.replace('/')
          }
        }}
        submitButtonTitle='Guardar'
        wrapper={ScrollView}
        wrapperProps={{
          contentContainerStyle: {
            width: '100%',
            alignSelf: 'center',
            marginLeft: 0,
            marginRight: 0
          },
          keyboardShouldPersistTaps: 'handled'
          // showsVerticalScrollIndicator: false
        }}
      >
        {() => (
          <Container margins='0 20px'>
            <Title margins='15px 0'>
              {'Ubicación Geográfica del ' +
                (location.state.triaje &&
                (location.state.triaje.briefDiagnosis ||
                  location.state.triaje.patient)
                  ? 'Paciente'
                  : 'Médico')}
            </Title>

            <Container flex={1} flexDirection='row'>
              <Container flex={1}>
                <IconAddressLocation
                  margins={fieldMargin}
                  label='Dirección'
                  icon={faSearch}
                  inputMargin='0 0 0 10px'
                  name='location'
                  marker={
                    location.state.triaje &&
                    (location.state.triaje.briefDiagnosis ||
                      location.state.triaje.patient)
                      ? patientMovIcon
                      : docIcon
                  }
                />
              </Container>

              <Container flex={1} margins='100px 0 0 20px'>
                <FormSpy>
                  {({form: {getFieldState}}) => {
                    if (
                      getFieldState('location') &&
                      getFieldState('location').value &&
                      getFieldState('location').value.address
                    ) {
                      return (
                        <Fragment>
                          <InputField
                            margins={fieldMargin}
                            name='location.address.address1'
                            maxLength={128}
                            placeholder='Calle ##'
                            label='Principal :'
                            icon={faRoad}
                          />
                          <InputField
                            margins={fieldMargin}
                            name='location.address.address2'
                            maxLength={128}
                            placeholder='Oficina ###'
                            label='Referencia:'
                            icon={faRoad}
                          />
                          <Container
                            flexDirection='row'
                            justifyContent='center'
                            alignItems='stretch'
                            margins={fieldMargin}
                          >
                            <InputField
                              width='70%'
                              name='location.address.city'
                              maxLength={36}
                              placeholder='Guayaquil'
                              label='Ciudad:'
                              icon={faCity}
                            />
                            <InputField
                              width='30%'
                              name='location.address.zipCode'
                              maxLength={10}
                              placeholder='#####'
                              label='Código Postal:'
                              icon={faMailBulk}
                            />
                          </Container>
                          <Container
                            flexDirection='row'
                            justifyContent='center'
                            alignItems='stretch'
                            margins={fieldMargin}
                          >
                            <InputField
                              width='70%'
                              name='location.address.state'
                              maxLength={24}
                              placeholder='Guayas'
                              label='Provincia:'
                              icon={faSolidFlag}
                            />
                            <InputField
                              width='30%'
                              name='location.address.country'
                              maxLength={2}
                              placeholder='EC'
                              label='País:'
                              icon={faFlag}
                            />
                          </Container>
                          <Field name='location.address.person.id'>
                            {() => null}
                          </Field>
                          <Field name='location.address.person.version'>
                            {() => null}
                          </Field>
                        </Fragment>
                      )
                    }
                    return null
                  }}
                </FormSpy>
              </Container>
            </Container>
          </Container>
        )}
      </FormFinal>
    )
  }
}

export default withRouter(ProfileLocation)
