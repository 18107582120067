/**
 * Profile View
 *
 * @format
 * @flow
 */

import React, {Component, Fragment} from 'react'
import {ActivityIndicator, ScrollView} from 'react-native'
import {array as yupArray, string as yupString, object as yupObject} from 'yup'
import {FormSpy} from 'react-final-form'
import {ThemeProvider, withTheme} from 'styled-components'
import {
  faAllergies,
  faBirthdayCake,
  faBookMedical,
  faClipboardCheck,
  faGraduationCap,
  faMapMarkedAlt,
  faPhone,
  faPlus,
  faUniversity,
  faUserMd,
  faUserShield,
  faVenusMars,
  faVial,
  faWheelchair
} from '@fortawesome/free-solid-svg-icons'
import {
  faEnvelope,
  faHospital,
  faIdCard,
  faUser
} from '@fortawesome/free-regular-svg-icons'
import Toast from '../../components/Toast'
import FormFinal from '../../components/form/FormFinal'
import CheckboxField from '../../components/form/CheckboxField'
import InputField from '../../components/form/InputField'
import PhotoField from '../../components/form/PhotoField'
import SelectField from '../../components/form/SelectField'
import DateField from '../../components/form/DateField'
import FormIconArrayField from '../../components/form/FormIconArrayField'
import FormIconButton from '../../components/form/FormIconButton'
import SecuredComponent from '../../components/common/SecuredComponent'
import {Container, Title, Label} from '../../components/common'
import FontAwesomeLink from '../../components/FontAwesomeLink'
import {withRouter} from '../../utility/routing'
import {doFetch, genderOptions, iDCardValidator} from '../../utility/Util'
import userPlaceholder from '../../assets/images/user-icon-placeholder.png'

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: null,
      percentDisabled: true
    }

    this.setProfile = this.setProfile.bind(this)
    this.setInitial = this.setInitial.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  componentDidMount() {
    doFetch({
      endpoint: 'userProfile',
      onOK: data => {
        this.setProfile(data)
      }
    })
    // This is done because when changing state by setState on fields the state of initialValues does not change
    doFetch({
      endpoint: 'userProfile',
      onOK: data => {
        this.setInitial(data)
      }
    })
  }

  setInitial(initialValues) {
    this.setState({initialValues})
  }

  setProfile(profile) {
    this.setState({profile})
  }

  handleSelectChange(itemValue, itemPosition) {
    this.setState({
      type: itemValue,
      choosenIndex: itemPosition
    })
  }

  render() {
    const {theme} = this.props,
      {profile, initialValues, percentDisabled} = this.state,
      fieldMargin = '20px 0 0',
      textAreaTheme = {
        field: {
          ...theme.field,
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderLeftWidth: '1px',
          borderRightWidth: '1px',
          borderRadius: '5px'
        }
      }

    if (!profile || !profile.person) {
      return (
        <Container flex={1} justifyContent='center'>
          <ActivityIndicator />
        </Container>
      )
    }

    return (
      <FormFinal
        endpoint='saveProfile'
        contentType='application/json'
        initialValues={initialValues}
        validationSchema={yupObject().shape({
          person: yupObject().shape({
            firstName: yupString().required('Ingrese su nombre'),
            middleeName: yupString(),
            surName: yupString().required('Ingrese su apellido paterno'),
            secondSurName: yupString().required('Ingrese su apellido materno'),
            birthDate: yupString()
              .nullable()
              .matches(
                /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                'Formato inválido, el correcto es AAAA-MM-DD (Por ejemplo: 1970-06-25)'
              ),
            idNumber: yupString()
              // Validate idCard & RUC inserted. https://medium.com/@bryansuarez/c%C3%B3mo-validar-c%C3%A9dula-y-ruc-en-ecuador-b62c5666186f
              // Library to do this can be: https://github.com/diaspar/validacion-cedula-ruc-ecuador
              // some code: https://www.jybaro.com/blog/cedula-de-identidad-ecuatoriana/
              // .test(
              //   'idCardValidator',
              //   params => `${params.value} no es una cédula válida`,
              //   value => iDCardValidator(value)
              // )
              // .length(
              //   10,
              //   params =>
              //     `Cédula debe tener exáctamente ${params.length} caracteres`
              // )
              .required('Ingrese su Cédula de Identidad o Pasaporte'),
            emails: yupArray()
              .of(
                yupObject().shape({
                  address: yupString()
                    .email('Correo Electrónico debe ser válido')
                    .required('Correo Electrónico es un campo requerido')
                })
              )
              .required('Ingrese al menos un Correo Electrónico')
              .min(1, 'Ingrese al menos un Correo Electrónico')
          })
          // username: yupString()
          //   .email('Correo Electrónico debe ser válido')
          //   .required('Correo Electrónico es un campo requerido'),
        })}
        onSuccess={data => {
          Toast({
            message: 'Guardado correctamente',
            duration: 4000,
            backgroundColor: theme.globalBlue,
            opacity: 0.75
          })
          this.setState({initialValues: data})
        }}
        submitButtonTitle='Guardar'
        wrapper={ScrollView}
        wrapperProps={{
          contentContainerStyle: {
            maxWidth: 500,
            width: '100%',
            alignSelf: 'center',
            marginLeft: 70,
            marginRight: 70
          },
          keyboardShouldPersistTaps: 'handled'
          // showsVerticalScrollIndicator: false
        }}
      >
        {() => (
          <Fragment>
            <Title margins='25px 0'>Perfil</Title>

            <Container
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
            >
              <PhotoField
                name='person.photo.photo'
                placeholder={userPlaceholder}
                height={125}
                width={125}
              />
            </Container>

            <InputField
              margins={fieldMargin}
              // label='Cédula de Identidad'
              placeholder='Cédula de Identidad'
              name='person.idNumber'
              maxLength={10}
              icon={faIdCard}
              // onChange={text => {
              //   if (text.length === 10) {
              //     doFetch({
              //       endpoint: 'getPatientsByIdNumber',
              //       body: text,
              //       onOK: data => {
              //         let patient = (data && data.appointments[0].patient) || (data && data.patient)
              //
              //         if (data && data.patient) {
              //           Toast({
              //             message: 'Cédula ya registrada en el sistema',
              //             duration: 4000,
              //             backgroundColor: theme.globalBlue,
              //             opacity: 0.75
              //           })
              //           this.setState({initialValues: data && data.patient})
              //         }
              //       }
              //     })
              //   }
              // }}
            />

            <Container
              flexDirection='row'
              justifyContent='center'
              alignItems='stretch'
              margins={fieldMargin}
            >
              <InputField
                width='50%'
                placeholder='Primer Nombre'
                name='person.firstName'
                maxLength={512}
                returnKeyType='next'
                icon={faUser}
              />
              <InputField
                width='50%'
                placeholder='Segundo Nombre'
                name='person.middleName'
                maxLength={512}
              />
            </Container>
            <Container
              flexDirection='row'
              justifyContent='center'
              alignItems='stretch'
              margins={fieldMargin}
            >
              <InputField
                width='50%'
                placeholder='Primer Apellido'
                name='person.surName'
                maxLength={512}
                icon={faUser}
              />
              <InputField
                width='50%'
                placeholder='Segundo Apellido'
                name='person.secondSurName'
                maxLength={512}
              />
            </Container>

            <Container
              flexDirection='row'
              justifyContent='space-between'
              alignItems='stretch'
              margins={fieldMargin}
            >
              <SelectField
                width='30%'
                options={genderOptions}
                placeholder='Sexo'
                name='person.gender'
                icon={faVenusMars}
              />
              <DateField
                width='30%'
                placeholder='AAAA-MM-DD'
                name='person.birthDate'
                icon={faBirthdayCake}
              />
              <SelectField
                width='30%'
                placeholder='Tipo/Sangre'
                options={[
                  {value: 'A+', label: 'A+'},
                  {value: 'A-', label: 'A-'},
                  {value: 'B+', label: 'B+'},
                  {value: 'B-', label: 'B-'},
                  {value: 'AB+', label: 'AB+'},
                  {value: 'AB-', label: 'AB-'},
                  {value: 'O+', label: 'O+'},
                  {value: 'O-', label: 'O-'}
                ]}
                name='person.bloodType'
                icon={faVial}
              />
            </Container>

            <FormIconArrayField
              name='person.emails'
              label='Correos Electrónicos'
              margins={fieldMargin}
              wrapperStyle={{minHeight: 60}}
            >
              {({fields, form}) => (
                <Container flex={1} flexDirection='row'>
                  <FormIconButton
                    leftIcon={faPlus}
                    margins='0'
                    paddings='5px'
                    alignSelf='flex-start'
                    onPress={() => fields.push({})}
                  />
                  <Container margins='0 0 0 10px' flex={1}>
                    {fields.map((emailItem, index) => (
                      <InputField
                        key={'emailItem' + index}
                        margins={index === 0 ? '0' : fieldMargin}
                        placeholder='Correo Electrónico'
                        name={`${emailItem}.address`}
                        maxLength={256}
                        autoCapitalize='none'
                        keyboardType='email-address'
                        icon={faEnvelope}
                      />
                    ))}
                  </Container>
                </Container>
              )}
            </FormIconArrayField>

            <FormIconArrayField
              name='person.phones'
              label='Teléfonos'
              margins={fieldMargin}
              wrapperStyle={{minHeight: 60}}
            >
              {({fields, form}) => (
                <Container flex={1} flexDirection='row'>
                  <FormIconButton
                    leftIcon={faPlus}
                    margins='0'
                    paddings='5px'
                    alignSelf='flex-start'
                    onPress={() => fields.push({})}
                  />
                  <Container margins='0 0 0 10px' flex={1}>
                    {fields.map((phoneItem, index) => (
                      <InputField
                        key={'phoneItem' + index}
                        margins={index === 0 ? '0' : fieldMargin}
                        placeholder='09 1234 5678'
                        name={`${phoneItem}.phoneNumber`}
                        maxLength={32}
                        keyboardType='numeric'
                        icon={faPhone}
                      />
                    ))}
                  </Container>
                </Container>
              )}
            </FormIconArrayField>

            <SecuredComponent
              checkPermissions={{
                objects: 'MedicalProfile',
                actions: ['create,update']
              }}
            >
              <FormIconArrayField
                name='person.degrees'
                label='Títulos'
                wrapperStyle={{minHeight: 70}}
                margins={fieldMargin}
              >
                {({fields, form}) => (
                  <Container flex={1} flexDirection='row'>
                    <FormIconButton
                      leftIcon={faPlus}
                      margins='0'
                      paddings='5px'
                      alignSelf='flex-start'
                      onPress={() => {
                        fields.push({})
                      }}
                    />
                    <Container margins='0 0 0 10px' flex={1}>
                      {fields.map((degreeItem, index) => (
                        <Fragment key={'degreeItem' + index}>
                          <InputField
                            margins={index === 0 ? '0' : fieldMargin}
                            placeholder='Universidad o Institución Educativa'
                            name={`${degreeItem}.college`}
                            maxLength={256}
                            icon={faUniversity}
                          />
                          <InputField
                            margins={fieldMargin}
                            placeholder='Médico / Licenciado / Seminario'
                            name={`${degreeItem}.levelType`}
                            maxLength={256}
                            icon={faGraduationCap}
                          />
                          <InputField
                            margins={fieldMargin}
                            placeholder='Medicina General / Ciencias de la Salud'
                            name={`${degreeItem}.degree`}
                            maxLength={256}
                            icon={faGraduationCap}
                          />
                          <InputField
                            margins={fieldMargin}
                            placeholder='Registro Senescyt'
                            name={`${degreeItem}.code`}
                            maxLength={256}
                            icon={faClipboardCheck}
                          />
                        </Fragment>
                      ))}
                    </Container>
                  </Container>
                )}
              </FormIconArrayField>

              <FormIconArrayField
                name='person.workExperiences'
                label='Experiencias Laborales'
                wrapperStyle={{minHeight: 70}}
                margins={fieldMargin}
              >
                {({fields, form}) => (
                  <Container flex={1} flexDirection='row'>
                    <FormIconButton
                      leftIcon={faPlus}
                      margins='0'
                      paddings='5px'
                      alignSelf='flex-start'
                      onPress={() => fields.push({})}
                    />
                    <Container margins='0 0 0 10px' flex={1}>
                      {fields.map((workItem, index) => (
                        <Fragment key={'workItem' + index}>
                          <InputField
                            margins={index === 0 ? '0' : fieldMargin}
                            placeholder='Compañía o Institución Médica'
                            name={`${workItem}.companyName`}
                            maxLength={256}
                            icon={faHospital}
                          />
                          <InputField
                            margins={fieldMargin}
                            placeholder='Jefe de pediatría'
                            name={`${workItem}.position`}
                            maxLength={256}
                            icon={faUserMd}
                          />

                          <ThemeProvider theme={textAreaTheme}>
                            <InputField
                              margins={fieldMargin}
                              placeholder='Como pediatra revisé ...'
                              name={`${workItem}.responsabilities`}
                              maxLength={256}
                              icon={faBookMedical}
                              multiline
                              height={70}
                              textAlignVertical='top'
                            />
                          </ThemeProvider>
                        </Fragment>
                      ))}
                    </Container>
                  </Container>
                )}
              </FormIconArrayField>

              <FormSpy>
                {({form: {getState}}) => {
                  if (getState().values.id) {
                    return (
                      <FontAwesomeLink
                        link='/map'
                        icon={faMapMarkedAlt}
                        title='Ubicación Geográfica'
                        color={theme.globalGreen}
                        state={{
                          userId: getState().values.id,
                          userVersion: getState().values.version,
                          userPersonId:
                            getState().values.person &&
                            getState().values.person.addresses &&
                            getState().values.person.addresses[0] &&
                            getState().values.person.addresses[0].id,
                          userPersonVersion:
                            getState().values.person &&
                            getState().values.person.addresses &&
                            getState().values.person.addresses[0] &&
                            getState().values.person.addresses[0].version,
                          allowSchedule: false
                        }}
                      />
                    )
                  }
                  return (
                    <Label
                      align='center'
                      color='#313c68'
                      fontSize={15}
                      fontWeight='bold'
                    >
                      Guarde los datos iniciales para obtener la ubicación
                      geográfica
                    </Label>
                  )
                }}
              </FormSpy>
            </SecuredComponent>

            <SecuredComponent
              checkPermissions={{
                objects: 'PatientProfile',
                actions: ['create,update']
              }}
            >
              <InputField
                margins={fieldMargin}
                label='Alergias'
                placeholder='Alergias'
                name='person.allergies'
                maxLength={512}
                icon={faAllergies}
              />

              <Container
                flexDirection='row'
                justifyContent='flex-start'
                alignItems='flex-end'
                margins={fieldMargin}
              >
                <CheckboxField
                  label='Discapacidad'
                  leftLabel=' '
                  width='40%'
                  name='person.disability'
                  icon={faWheelchair}
                  onChange={val => this.setState({percentDisabled: !val})}
                />

                <InputField
                  width='50%'
                  label='Porcentaje de Discapacidad'
                  placeholder='###'
                  keyboardType='numeric'
                  maxLength={3}
                  name='person.disabilityPercentage'
                  disabled={percentDisabled}
                />
                <Label>%</Label>
              </Container>

              <CheckboxField
                margins={fieldMargin}
                label='Seguro Médico Privado'
                leftLabel=' '
                width='100%'
                name='privateUser'
                icon={faUserShield}
              />

              <ThemeProvider theme={textAreaTheme}>
                <Fragment>
                  <InputField
                    margins={fieldMargin}
                    label='Enfermedades'
                    placeholder='Asma?...'
                    name='person.diseases'
                    maxLength={512}
                    multiline
                    height={70}
                    textAlignVertical='top'
                  />
                  <InputField
                    margins={fieldMargin}
                    label='Operaciones o Intervenciones Quirúrgicas'
                    name='person.surgicalOperations'
                    maxLength={512}
                    multiline
                    height={70}
                    textAlignVertical='top'
                  />
                  <InputField
                    margins='20px 0 20px'
                    label='Tratamientos'
                    name='person.treatment'
                    maxLength={512}
                    multiline
                    height={70}
                    textAlignVertical='top'
                  />
                </Fragment>
              </ThemeProvider>

              <FormSpy>
                {({form: {getState}}) => {
                  if (getState().values.id) {
                    return (
                      <FontAwesomeLink
                        link='/map'
                        icon={faMapMarkedAlt}
                        title='Ubicación Geográfica'
                        color={theme.globalGreen}
                        state={{
                          userId: getState().values.id,
                          userVersion: getState().values.version,
                          userPersonId:
                            getState().values.person &&
                            getState().values.person.addresses &&
                            getState().values.person.addresses[0] &&
                            getState().values.person.addresses[0].id,
                          userPersonVersion:
                            getState().values.person &&
                            getState().values.person.addresses &&
                            getState().values.person.addresses[0] &&
                            getState().values.person.addresses[0].version,
                          allowSchedule: true
                        }}
                      />
                    )
                  }
                  return (
                    <Label
                      align='center'
                      color={theme.globalBlue}
                      fontSize={15}
                      fontWeight='bold'
                    >
                      Guarde los datos iniciales para obtener la ubicación
                      geográfica
                    </Label>
                  )
                }}
              </FormSpy>
            </SecuredComponent>
          </Fragment>
        )}
      </FormFinal>
    )
  }
}

export default withRouter(withTheme(Profile))
