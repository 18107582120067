/**
 * Appointments By Doctor Report
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {ActivityIndicator, ScrollView} from 'react-native'
import {withTheme} from 'styled-components'
import {Container, Label, TableLabel, Title} from '../../components/common'
import {doFetch} from '../../utility/Util'

class AppointmentReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appointments: [],
      loading: true
    }
  }

  componentDidMount() {
    doFetch({
      endpoint: 'reportAppointmentsByDoctor',
      onOK: appointments => {
        this.setState({appointments, loading: false})
      },
      onNotOK: () => this.setState({loading: false})
    })
  }

  /**
	 *
	 *                   medico            |                  id
-------------------------------------------+--------------------------------------
 Dr(a). Maria De Lourdes Bravo Valencia    | 3aef21e2-67c9-48aa-8763-3ad3c5beb695
 Dr(a). ROBERTO ANDRE CALDERON LEON        | d1b6e946-3774-4149-b7d4-d790723b6f3b
 Dr(a). WILLIAM . CHAVEZ FLORES            | d4060eac-0ab6-464b-b2a7-b0a6bd73facd
 Dr(a). LUIS ENRIQUE CRUZ BAJAÑA           | 988151ba-c27e-4a1c-9130-50433d211df3
 Dr(a). Alexandra Elizabeth Delgado Rivera | 650c1a0c-2c4b-40c9-8536-b3d0fbada182
 Dr(a). LUIS ANOTNIO JIMENEZ  P            | e38ae871-b740-4ced-b1c7-027612993c3b
 Dr(a). LUIS ASDRUBAL LEAL VANEGAS         | fcdfcc9e-2c3b-496b-a781-81b5ba0d67a8
 Dr(a). Diana Gabriela Masabanda Ordoñez   | eedd83ec-8c44-463b-b26f-1a2a35c450ba
 Dr(a). Maria Belen Miranda Pareja         | 09f9f875-439e-4d6f-b961-de1f283b909e
 Dr(a). Cesar Raúl Molina Yanchapaxi       | 9a9f29f9-c455-4a7c-a9af-fcdfac11c693
 Dr(a). Daniel Jose Poaquiza Pacheco       | 33ff3245-c680-4508-adb4-5df4ab9985e2
 Dr(a). Andrés Francisco Prieto López      | 2cfe538a-beea-4ec9-a05b-14f0d9e057f8
 Dr(a). Andrea Soledad Rivera León         | 4839ce06-5eb8-421c-9738-f871df1a05f7
 Dr(a). Fernando Javier Rivera Morales     | f61598f0-de02-407c-9d64-51d24a8e71e0
 Dr(a). ALFONSO PATRICIO ROBAYO AYALA      | 8bbda976-31ef-49af-bc20-c229b392a755
 Dr(a). JOSE ALEJANDRO ROMERO JIMENEZ      | 3df6d97d-adaa-456a-80a8-38a62dc2f842
 Dr(a). Jean Carlos Gutierrez Vera         | befcd22a-faa8-4013-9ffb-70c3ae642c12
 Dr(a). VICTOR STEVEN NARVAEZ GUERRERO     | 2d9cdf92-705d-409b-86bd-4379dfe01ca7
 Dr(a). JORGE LUIS PALOMINO MENDOZA        | 2e97c9e8-8f67-4ce2-9f7c-c40a481adc0c
	 */
  render() {
    const {appointments, loading} = this.state,
      {theme} = this.props
    let temp = ['fecha']

    return (
      <Container alignItems='center' width='100%' flex={1}>
        <Title margins='10px'>Pacientes Atendidos por Médico</Title>

        {loading && <ActivityIndicator />}
        {!loading && appointments.length === 0 && (
          <Container paddings='15px' alignItems='center'>
            <Label fontWeight='bold' color={theme.globalBlue}>
              No hay datos para mostrar
            </Label>
          </Container>
        )}

        {!loading && appointments.length > 0 && (
          <ScrollView
            horizontal
            style={{width: '100%'}}
            contentContainerStyle={{
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: 2100,
              width: '100%'
            }}
          >
            <Container flexDirection='row' justifyContent='flex-start'>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Fecha
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Maria De Lourdes Bravo Valencia
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                ROBERTO ANDRE CALDERON LEON
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                WILLIAM . CHAVEZ FLORES
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                LUIS ENRIQUE CRUZ BAJAÑA
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Alexandra Elizabeth Delgado Rivera
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                LUIS ANOTNIO JIMENEZ P
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                LUIS ASDRUBAL LEAL VANEGAS
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Diana Gabriela Masabanda Ordoñez
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Maria Belen Miranda Pareja
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Cesar Raúl Molina Yanchapaxi
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Daniel Jose Poaquiza Pacheco
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Andrés Francisco Prieto López
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Andrea Soledad Rivera León
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Fernando Javier Rivera Morales
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                ALFONSO PATRICIO ROBAYO AYALA
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                JOSE ALEJANDRO ROMERO JIMENEZ
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                Jean Carlos Gutierrez Vera
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                VICTOR STEVEN NARVAEZ GUERRERO
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
              >
                JORGE LUIS PALOMINO MENDOZA
              </TableLabel>
              <TableLabel
                align='center'
                fontSize='17px'
                color={theme.globalGreen}
                width="80px"
              >
                Total
              </TableLabel>
            </Container>
            <ScrollView
              contentContainerStyle={{
                width: '100%',
                alignItems: 'center'
              }}
              keyboardShouldPersistTaps='handled'
            >
              {appointments.map((appointment, i) => {
                Object.entries(appointment).map((val, ii) => {
                  if (val[0] !== 'scheduled') {
                    if (temp.length === ii) {
                      temp.push(val[1])
                    } else {
                      temp[ii] = temp[ii] + val[1]
                    }
                  }
                  return null
                })
                return (
                  <Container
                    flexDirection='row'
                    key={appointment.scheduled}
                    alignItems='center'
                    backgroundColor={i % 2 === 0 ? '#eaf6f7' : ''}
                  >
                    <TableLabel align='center'>{appointment.scheduled}</TableLabel>
                    <TableLabel align='center'>{appointment.bravo}</TableLabel>
                    <TableLabel align='center'>{appointment.calderon}</TableLabel>
                    <TableLabel align='center'>{appointment.chavez}</TableLabel>
                    <TableLabel align='center'>{appointment.cruz}</TableLabel>
                    <TableLabel align='center'>{appointment.delgado}</TableLabel>
                    <TableLabel align='center'>{appointment.jimenez}</TableLabel>
                    <TableLabel align='center'>{appointment.leal}</TableLabel>
                    <TableLabel align='center'>{appointment.masabanda}</TableLabel>
                    <TableLabel align='center'>{appointment.miranda}</TableLabel>
                    <TableLabel align='center'>{appointment.molina}</TableLabel>
                    <TableLabel align='center'>{appointment.poaquiza}</TableLabel>
                    <TableLabel align='center'>{appointment.prieto}</TableLabel>
                    <TableLabel align='center'>{appointment.riveraleón}</TableLabel>
                    <TableLabel align='center'>{appointment.riveramorales}</TableLabel>
                    <TableLabel align='center'>{appointment.robayo}</TableLabel>
                    <TableLabel align='center'>{appointment.romero}</TableLabel>
                    <TableLabel align='center'>{appointment.gutierrez}</TableLabel>
                    <TableLabel align='center'>{appointment.narvaez}</TableLabel>
                    <TableLabel align='center'>{appointment.palomino}</TableLabel>
                    <TableLabel
                      align='center'
                      backgroundColor='rgba(0,100,0,0.1)'
                      width="80px"
                    >
                      {appointment.total}
                    </TableLabel>
                  </Container>
                )
              })}

              <Container flexDirection='row' backgroundColor='#e0f0f0'>
                <TableLabel align='center'>Total</TableLabel>
                {temp.map((val, ii) => {
                  if (ii > 0) {
                    return (
                      <TableLabel align='center' key={ii} width={ii === 20 ? "80px" : "100px"}>
                        {val}
                      </TableLabel>
                    )
                  }
                  return null
                })}
              </Container>
            </ScrollView>
          </ScrollView>
        )}
      </Container>
    )
  }
}

export default withTheme(AppointmentReport)
