/**
 * Login View
 *
 * @format
 * @flow
 */

import React, {Component, Fragment} from 'react'
import {ScrollView} from 'react-native'
import {ThemeProvider} from 'styled-components'
import {string as yupString, object as yupObject} from 'yup'
import {faLock} from '@fortawesome/free-solid-svg-icons'
import {faEnvelope} from '@fortawesome/free-regular-svg-icons'
import {Field} from 'react-final-form'
import {withRouter} from '../../utility/routing'
import Header from '../../components/logic/Header'
import FormFinal from '../../components/form/FormFinal'
import InputField from '../../components/form/InputField'
import FormIconButton from '../../components/form/FormIconButton'
import {Container} from '../../components/common'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      userId: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.createAccount = this.createAccount.bind(this)
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  createAccount() {
    this.props.history.push('/register')
  }

  render() {
    const {connectFunction = () => {}} = this.props
    return (
      <Container flex={1}>
        <ThemeProvider
          theme={{
            header: {
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}
        >
          <Header />
        </ThemeProvider>
        <FormFinal
          endpoint='login'
          contentType='application/x-www-form-urlencoded'
          initialValues={{"remember-me": true}}
          validationSchema={yupObject().shape({
            userId: yupString()
              .required('Correo Electrónico es un campo requerido')
              .email('Correo Electrónico debe ser válido'),
            password: yupString().required('Contraseña es un campo requerido')
          })}
          onSuccess={data => {
            connectFunction(data)
          }}
          submitButtonTitle='Inicio de Sesión'
          submitButtonMargins='40px 0'
          wrapper={ScrollView}
          wrapperProps={{
            style: {
              maxWidth: 300,
              width: '100%',
              alignSelf: 'center',
              marginLeft: 0,
              marginRight: 0,
              flex: 1
            },
            keyboardShouldPersistTaps: 'handled'
          }}
        >
          {(form, handleSubmit) => (
            <Fragment>
              <InputField
                placeholder='Correo Electrónico'
                name='userId'
                icon={faEnvelope}
                returnKeyType='next'
                onSubmitEditing={handleSubmit}
                autoCapitalize='none'
                keyboardType='email-address'
                // autoFocus
              />
              <InputField
                placeholder='Contraseña'
                name='password'
                icon={faLock}
                returnKeyType='go'
                onSubmitEditing={handleSubmit}
                margins='25px 0 0'
                secureTextEntry
              />

              <Field name='rememberMe'>{() => null}</Field>

              {/*<FormIconButton
                margins='0'
                paddings='0'
                borderColor='transparent'
                title='Olvidé la contraseña'
                alignSelf='flex-end'
                // onPress={this.forgotPass}
              />*/}
            </Fragment>
          )}
        </FormFinal>

        <FormIconButton
          flex={0.1}
          margins='0 0 20px'
          paddings='10px 0'
          borderColor='transparent'
          title='Crear cuenta'
          alignSelf='center'
          onPress={this.createAccount}
        />
      </Container>
    )
  }
}

export default withRouter(Login)
