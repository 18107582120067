/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, {Component} from 'react'
import {Platform, SafeAreaView} from 'react-native'
import {ThemeProvider} from 'styled-components'
import Notifications from 'react-notify-toast'
import AppProvider from './components/logic/AppProvider'
import {setGlobalServer} from './utility/Util'
import {Container} from './components/common'

const global = {
  appUrl: 'https://server.doctus.com.ec/',
  // appUrl: 'http://192.168.100.27:5000/',
  theme: {
    globalBackground: '#f9fcff',
    globalGreen: '#0b9a9f',
    globalBlue: '#313c68',
    globalError: '#bd2a16',
    globalFontColor: '#203020',
    globalPlaceholderColor: '#929292',

    header: {
      height: '65px',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#0b9a9f',
      backgroundColor1: 'white',
      color: '#313c68',
      logo: {
        width: '102px',
        height: '32px'
      }
    },

    bottomMenu: {
      height: '70px',
      // flex: 0.15,
      alignItems: 'center',
      justifyContent: 'space-around',
      backgroundColor: 'white',
      backgroundColor1: '#0b9a9f',
      color: 'white',
      iconSize: 30,
      iconColor: '#0b9a9f',
      selectedIconColor: '#313c68'
    },

    field: {
      height: '27px',
      fontSize: '15px',
      labelColor: '#0b9a9f',
      alignItems: 'center',
      icon: {
        size: 15,
        color: '#929292'
      },
      borderBottomWidth: '1px'
    },

    arrayField: {
      borderColor: '#0b9a9f',
      borderTopWidth: '0px',
      borderBottomWidth: '0px',
      borderLeftWidth: '0px',
      borderRightWidth: '0px',
      borderRadius: '5px'
    },

    title: {
      fontSize: '23px',
      color: '#0b9a9f',
      align: 'center',
      fontWeight: 'bold'
    },

    defaultButton: {
      borderTopWidth: '2px',
      borderBottomWidth: '2px',
      borderLeftWidth: '2px',
      borderRightWidth: '2px',
      borderRadius: '25px',
      borderColor: '#0b9a9f',
      backgroundColor: 'transparent',
      margins: '20px 0 30px',
      paddings: '10px 40px'
    }
  }
}

setGlobalServer(global.appUrl)

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={global.theme}>
        <SafeAreaView
          style={Platform.OS === 'web'
            ? {height: '100vh'}
            : {flex: 1, backgroundColor: '#0b9a9f'}
          }
        >
          <Container
            flex={1}
            // margins="20px"
            backgroundColor={global.theme.globalBackground}
          >
            {/* <a href="basicvideochat://">open APP</a> */}
            {Platform.OS === 'web' && <Notifications />}
            <AppProvider appUrl={global.appUrl} />
          </Container>
        </SafeAreaView>
      </ThemeProvider>
    )
  }
}

export default App
